
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { obtener_fabricantesEnAPI, obtener_modelosEnAPI, obtener_motorizacionesEnAPI } from "../action/actionCreatorsRequests";
import { MSGERROR, MSGOK, traducirPagina } from "../librerias/funciones"
import {
    SearchOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { Drawer } from 'antd';

const CP_FABRICANTES = (props) => {
    const filtrarFabricante = (palabraABuscar, marcas) => {
        let palabra = palabraABuscar;
        let resultado = [];
        if (palabraABuscar === undefined || palabraABuscar === "") {
            resultado = marcas;

        } else {
            let resultadoFiltro = marcas.filter((item) => {
                return item.DESCRIPCION.toUpperCase().includes(palabra.toUpperCase()) ||
                    item.DESCRIPCION.toUpperCase() === palabra.toUpperCase()
            })

            if (resultadoFiltro.length > 0) {
                resultado = resultadoFiltro;
            } else {
                resultado = [];
            }
        }

        return resultado;
    }

    const modelarDatos = (marcas, textoBuscador) => {
        marcas = filtrarFabricante(textoBuscador, marcas);
        let listaMarca = [];
        let objetoNuevoMarcas = [];
        let contAux = 0;

        for (var i = 0; i < marcas.length; i++) {
            contAux += 1;
            if (contAux <= 3) {
                listaMarca.push({ "ID": marcas[i].ID, "Marca": marcas[i].DESCRIPCION })
                if ((marcas.length - 1) === i) {
                    objetoNuevoMarcas.push({ "lista": listaMarca });
                }
            } else {
                objetoNuevoMarcas.push({ "lista": listaMarca });
                contAux = 0;
                listaMarca = []
                i = (i - 1)
            }
        }
        return objetoNuevoMarcas.length === 0 ? [] : objetoNuevoMarcas;
    }




    return (

        props.MARCAS === undefined || props.MARCAS === null ?
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("Msg_sin_resultados")}</h3> </div>
            :
            props.MARCAS.length > 0 ?
                modelarDatos(props.MARCAS, props.TXT_FILTRO).map((item, i) =>
                    <div key={i} id={i} style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>
                        {item.lista.map((row, i) =>
                            <div style={{ float: 'left', padding: '5px', width: '33%', cursor: 'pointer' }} className="efectoContainerVehiculo" onClick={() => {
                                props.HISTORIAL_VEHICULO.POSICION_ACTUAL = 1;
                                props.HISTORIAL_VEHICULO.DESCRIPCION_MARCA = row.Marca
                                props.HISTORIAL_VEHICULO.ID_MARCA = row.ID;

                                props.BUSCAR_MODELOS_API(row.ID);

                                props.SET_HISTORIAL({ ...props.HISTORIAL_VEHICULO })
                                props.SET_TXT_FILTRO("")
                            }} >
                                {row.Marca}
                            </div>
                        )}
                    </div>
                )
                :
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
    );

}

const CP_MODELOS = (props) => {

    const filtarModelos = (palabraABuscar, registros) => {
        let palabra = palabraABuscar;
        let resultado = [];
        if (palabraABuscar === undefined || palabraABuscar === "") {
            resultado = registros;

        } else {
            let resultadoFiltro = registros.filter((item) => {
                return item.DESCRIPCION.toUpperCase().includes(palabra.toUpperCase()) ||
                    item.DESCRIPCION.toUpperCase() === palabra.toUpperCase() ||
                    item.DESDE.toUpperCase().includes(palabra.toUpperCase()) ||
                    item.DESDE.toUpperCase() === palabra.toUpperCase() ||
                    item.HASTA.toUpperCase().includes(palabra.toUpperCase()) ||
                    item.HASTA.toUpperCase() === palabra.toUpperCase()

            })

            if (resultadoFiltro.length > 0) {
                resultado = resultadoFiltro;
            } else {
                resultadoFiltro = [];
            }
        }

        return resultado;
    }

    return (
        props.MODELOS === null || props.MODELOS === undefined ?
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("Msg_sin_resultados")}</h3> </div>
            :
            props.MODELOS.length > 0 ?
                <table style={{ border: '1px solid #d9d9d9', width: '100%', color: '#000' }} >
                    <thead>
                        <tr style={{ backgroundColor: '#dcdcdc' }}>
                            <th style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => { }}>{traducirPagina("Modelo")}</th>
                            <th style={{ width: '120px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} onClick={(e) => { }}>{traducirPagina("Desde")}</th>
                            <th style={{ width: '120px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>{traducirPagina("Hasta")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {filtarModelos(props.TXT_FILTRO, props.MODELOS).map((registro, i) =>
                            <tr key={i} style={{ backgroundColor: '#fff' }} onClick={() => {
                                props.HISTORIAL_VEHICULO.POSICION_ACTUAL = 2;
                                props.HISTORIAL_VEHICULO.DESCRIPCION_MODELO = registro.DESCRIPCION
                                props.HISTORIAL_VEHICULO.ID_MODELO = registro.ID;

                                props.BUSCAR_MOTORIZACION_API(registro.ID);
                                props.SET_HISTORIAL({ ...props.HISTORIAL_VEHICULO })
                                props.SET_TXT_FILTRO("")


                            }}>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{registro.DESCRIPCION}</td>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>{registro.DESDE}</td>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>{registro.HASTA}</td>
                            </tr>
                        )
                        }
                    </tbody>
                </table>
                :
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
    );
}

const CP_MOTORIZACION = (props) => {

    const filtarMotores = (palabraABuscar, motores) => {
        let palabra = palabraABuscar;
        let resultado = [];
        if (palabraABuscar === undefined || palabraABuscar === "") {
            resultado = motores;

        } else {
            let resultadoFiltro = motores.filter((item) => {
                return item.DESCRIPCION.toUpperCase().includes(palabra.toUpperCase()) ||
                    item.DESCRIPCION.toUpperCase() === palabra.toUpperCase() ||
                    item.DESDE.toUpperCase().includes(palabra.toUpperCase()) ||
                    item.DESDE.toUpperCase() === palabra.toUpperCase() ||
                    item.MOTORES.toUpperCase().includes(palabra.toUpperCase()) ||
                    item.MOTORES.toUpperCase() === palabra.toUpperCase() ||
                    item.KW.toString().toUpperCase().includes(palabra.toUpperCase()) ||
                    item.KW.toString().toUpperCase() === palabra.toUpperCase() ||
                    item.CV.toString().toUpperCase().includes(palabra.toUpperCase()) ||
                    item.CV.toString().toUpperCase() === palabra.toUpperCase() ||
                    item.CC.toString().toUpperCase().includes(palabra.toUpperCase()) ||
                    item.CC.toString().toUpperCase() === palabra.toUpperCase()
            })

            if (resultadoFiltro.length > 0) {
                resultado = resultadoFiltro;
            } else {
                resultadoFiltro = [];
            }
        }

        return resultado;
    }



    return (
        props.MOTORES === null || props.MOTORES === undefined ?
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{"Sin datos"}</h3> </div>
            :
            props.MOTORES.length > 0 ?
                <table style={{ border: '1px solid #d9d9d9', width: '100%', color: '#000' }} >
                    <thead>
                        <tr style={{ backgroundColor: '#dcdcdc' }} >
                            <th style={{ width: '135px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} >{traducirPagina("Motor")}</th>
                            <th style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Codigo")}</th>
                            <th style={{ width: '100px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Anio")}</th>
                            <th style={{ width: '100px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{"kW"}</th>
                            <th style={{ width: '100px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{"CV"}</th>
                            <th style={{ width: '100px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{"CC"}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {filtarMotores(props.TXT_FILTRO, props.MOTORES).map((registro, i) =>
                            <tr key={i} style={{ backgroundColor: '#fff' }} onClick={() => {
                                props.HISTORIAL_VEHICULO.DESCRIPCION_MOTOR = registro.DESCRIPCION
                                props.HISTORIAL_VEHICULO.ID_MOTOR = registro.ID;
                                props.HISTORIAL_VEHICULO.MOTORES = registro.MOTORES;

                                props.SET_HISTORIAL({ ...props.HISTORIAL_VEHICULO })
                                props.SET_TXT_FILTRO("")

                                props.ACTUALIZAR_VEHICULO(props.HISTORIAL_VEHICULO)
                                props.CERRAR_FICHA(false)
                            }}>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{registro.DESCRIPCION}</td>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{registro.MOTORES}</td>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{registro.DESDE}</td>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{registro.KW}</td>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{registro.CV}</td>
                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{registro.CC}</td>

                            </tr>
                        )
                        }
                    </tbody>
                </table>

                :
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div className="spinner-border fast colorSubAzul" role="status" disabled /> </div>
    );
}


const FichaVehiculoTecdoc = ({ props, configApp }) => {
    const [txtFiltro, setTxtFiltro] = React.useState("");
    const [historialVehiculo, setHistorialVehiculo] = React.useState(
        {
            ID_MARCA: 0,
            ID_MODELO: 0,
            ID_MOTOR: 0,
            DESCRIPCION_MARCA: "",
            DESCRIPCION_MODELO: "",
            DESCRIPCION_MOTOR: "",
            MOTORES: "",
            POSICION_ACTUAL: 0,

        }
    );


    const [hayCliente, setHayCliente] = React.useState(false);
    const [marcas, setMarcas] = React.useState([]);
    const [modelos, setModelos] = React.useState([]);
    const [motores, setMotores] = React.useState([]);



    const buscarFabricanteAPI = (fabricante) => {
        obtener_fabricantesEnAPI(fabricante).then((fabricantes) => {
            if (fabricantes !== undefined && fabricantes !== null && fabricantes.REGISTROS !== null && fabricantes.REGISTROS.length > 0) {
                setMarcas(fabricantes.REGISTROS)
            } else {
                setMarcas(null);
            }
        })
    }


    const buscarModelosAPI = async (fabricante) => {
        const modelos = await obtener_modelosEnAPI(fabricante);
        if (modelos !== undefined && modelos !== null && modelos.REGISTROS !== null && modelos.REGISTROS.length > 0) {
            setModelos(modelos.REGISTROS);
        } else {
            setModelos(null);
        }
    }


    const buscarMotorizacionAPI = async (modelo) => {
        const motores = await obtener_motorizacionesEnAPI(modelo);
        if (motores !== undefined && motores !== null && motores.REGISTROS !== null && motores.REGISTROS.length > 0) {
            setMotores(motores.REGISTROS);

        } else {
            setMotores(null);
        }
    }


    if (!hayCliente && props.ABRIR_FICHA) {
        setHayCliente(true);
        buscarFabricanteAPI(1);
    } else {
        if (!props.ABRIR_FICHA && hayCliente) {
            setHayCliente(false);
        }
    }

    return (
        <>
            <Drawer
                width={configApp.width <= 900 ? '100%' : 800}
                onClose={() => { props.CERRAR_FICHA(false) }}
                closable={false}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero header_ficha" style={{ height: '56px' }}>
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false) }} >
                            <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {historialVehiculo.POSICION_ACTUAL === 0 ? props.title : historialVehiculo.POSICION_ACTUAL === 1 ? traducirPagina("Seleccione_modelo").toUpperCase() : historialVehiculo.POSICION_ACTUAL === 2 ? traducirPagina("Seleccione_tipo_motor").toUpperCase() : ""}
                    </div>
                </div>

                <div className='containerVehiculo'>

                    <div className='v_consulta_Seleccionado' >
                        <div className='v_consulta_Seleccionado_texto'>
                            <input type='text' placeholder={traducirPagina("Buscar_en_tabla")} value={txtFiltro} onChange={(e) => {
                                setTxtFiltro(e.target.value.trim() !== "" ? e.target.value : "")
                            }} ></input>
                        </div>

                        <div className='v_consulta_Seleccionado_remove'>
                            {txtFiltro !== "" ?
                                <CloseOutlined onClick={() => {
                                    setTxtFiltro("")

                                }}></CloseOutlined>
                                :
                                null
                            }

                        </div>

                        <div className='v_consulta_Seleccionado_barra' ></div>

                        <div className='v_consulta_Seleccionado_remove' onClick={() => {
                            // setAbrirFichaTecdoc(true)
                        }}>
                            <SearchOutlined></SearchOutlined>
                            {/**   <CloseOutlined></CloseOutlined>*/}
                        </div>
                    </div>

                    {/*
                    <div className='historialVehiculo'>
                        {historialVehiculo.DESCRIPCION_MARCA === "" ?
                            null :
                            <span>
                                {historialVehiculo.DESCRIPCION_MARCA}
                            </span>
                        }

                        {historialVehiculo.DESCRIPCION_MARCA === "" ?
                            null :
                            <span>
                                {historialVehiculo.DESCRIPCION_MODELO}
                            </span>
                        }
                        {historialVehiculo.DESCRIPCION_MARCA === "" ?
                            null :
                            <span>
                                {historialVehiculo.DESCRIPCION_MOTOR}
                            </span>
                        }
                    </div>
                    */}
                </div>

                <div className='bodyVehiculo'>
                    <div className='container_list_marcas'>

                        {historialVehiculo.POSICION_ACTUAL === 0 ?

                            <CP_FABRICANTES
                                MARCAS={marcas}
                                HISTORIAL_VEHICULO={historialVehiculo}
                                TXT_FILTRO={txtFiltro}
                                BUSCAR_MODELOS_API={buscarModelosAPI}
                                SET_HISTORIAL={setHistorialVehiculo}
                                SET_TXT_FILTRO={setTxtFiltro}
                            >

                            </CP_FABRICANTES>

                            :

                            historialVehiculo.POSICION_ACTUAL === 1 ?
                                <CP_MODELOS
                                    MODELOS={modelos}
                                    HISTORIAL_VEHICULO={historialVehiculo}
                                    TXT_FILTRO={txtFiltro}
                                    BUSCAR_MOTORIZACION_API={buscarMotorizacionAPI}
                                    SET_HISTORIAL={setHistorialVehiculo}
                                    SET_TXT_FILTRO={setTxtFiltro}

                                >

                                </CP_MODELOS>
                                :
                                historialVehiculo.POSICION_ACTUAL === 2 ?
                                    <CP_MOTORIZACION
                                        MOTORES={motores}
                                        HISTORIAL_VEHICULO={historialVehiculo}
                                        TXT_FILTRO={txtFiltro}
                                        SET_HISTORIAL={setHistorialVehiculo}
                                        SET_TXT_FILTRO={setTxtFiltro}
                                        ACTUALIZAR_VEHICULO={props.ACTUALIZAR_VEHICULO}
                                        CERRAR_FICHA={props.CERRAR_FICHA}

                                    >

                                    </CP_MOTORIZACION>

                                    :
                                    null
                        }
                    </div>

                </div>

            </Drawer>

        </>
    )
}

FichaVehiculoTecdoc.propTypes = {
    title: PropTypes.string,
    ID_TAREA: PropTypes.number,

    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    REGISTRO_SELECCIONADO: PropTypes.object,
    ES_NUEVA_TAREA: PropTypes.bool,
    ACTUALIZAR_TABLA: PropTypes.func,
    FILTRO_FECHA: PropTypes.object,
    FILTROS: PropTypes.object,

    ACTUALIZAR_VEHICULO: PropTypes.func

};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        QUERY_USER: state.QUERY_USER

    };
}

export default connect(mapStateToProps)(FichaVehiculoTecdoc);