import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Router, Switch, Route } from "react-router-dom";

import 'alertifyjs//build/alertify.min.js';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'antd/dist/antd.css';
import './index.css'

import store from './store'
import { Provider } from 'react-redux';
import { updateStateMasterPage } from './action/actionCreators';
import ContainerTareas from "./components/ContainerTareas";
import Login from "./components/Login";
import history from './history';


window.onpopstate = function (event) {
  // Code to handle back button or prevent from navigation
  if (event.path[0].location.pathname === "/") {
    store.dispatch(updateStateMasterPage(false))

  } else {
    store.dispatch(updateStateMasterPage(true))
  }
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter >
    <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          
          <Route exact path="/home" component={ContainerTareas} />
        </Switch>
      </Router>
    </BrowserRouter>
  </Provider>,
 
  document.getElementById("root")
);


