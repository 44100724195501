
import React from "react";
import store from '../store'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  changeDimensionsApp
} from '../action/actionCreators';
import {
  LogoutEnAPI
} from '../action/actionCreatorsRequests';

import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';
import FichaPerfil from "../components/FichaPerfil";
import FichaBoletines from "../components/FichaBoletines";
import FichaTutorial from "../components/FichaTutorial";
import {
  traducirPagina
} from '../librerias/funciones'
import {
  SettingOutlined,
  ExportOutlined,
  FilterOutlined,
  BlockOutlined,
  VideoCameraOutlined,
  ReadOutlined
} from '@ant-design/icons';

import history from '../history';

import {
  UserOutlined,
  FileDoneOutlined
} from '@ant-design/icons';
import FichaHaynes from "../components/FichaHaynes";

const MasterPage = ({ configApp, props, usuario }) => {


  const [ABRIR_FICHA_BOLETINES, setABRIR_FICHA_BOLETINES] = React.useState(false)
  const [ABRIR_FICHA_HAYNES, setABRIR_FICHA_HAYNES] = React.useState(false)

  const [ABRIR_FICHA_PERFIL, setABRIR_FICHA_PERFIL] = React.useState(false)
  const [ABRIR_FICHA_TUTORIAL, setABRIR_FICHA_TUTORIAL] = React.useState(false)

  const { USUARIO } = usuario;

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })


  React.useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
      store.dispatch(changeDimensionsApp(window.innerWidth, window.innerHeight));

    }

    window.addEventListener('resize', handleResize)
  }, [])


  const logOut = async () => {
    const data = await LogoutEnAPI();
  }



  if (configApp.height === 0) {
    store.dispatch(changeDimensionsApp(window.innerWidth, window.innerHeight));
  }



  if (history.location.pathname !== "/") {
    //store.dispatch(updateStateMasterPage(true))

    //verifico el estado del usuario en redux
    if (Object.keys(usuario).length === 0) {
      history.push("/");
      store.dispatch({ type: "STATE_REGISTRO_ITEM", activeItemRegistro: {} })
      //store.dispatch(updateStateMasterPage(false))
    }
  }



  return (

    <div className="mdb-skin">
      <div>
        <MDBNavbar double expand="xs" className="barraPrincipal">
          <MDBNavbarNav left>
            <MDBNavItem>
              <div className="logo_empresa">
                <img className="logoApp" style={{ height: '40px', cursor: 'pointer' }} ></img>
                <span>TALLER</span>
              </div>

            </MDBNavItem>
          </MDBNavbarNav>

          <MDBNavbarNav right>
            {configApp.width <= 1300 ?
              <MDBNavItem title={"Filtro"}>
                <button className="colorEmpresa" style={{ border: '0', padding: '5px', margin: '0px', backgroundColor: 'transparent', marginRight: '10px', display: 'flex', alignItems: 'center' }} onClick={() => props.CERRAR_FICHA(true)}> <FilterOutlined className="colorEmpresa" style={{ fontSize: '24px', color: '#fff' }} /> </button>
              </MDBNavItem>
              :
              null}

            {USUARIO !== undefined ?
              USUARIO.CON_HAYNES ?
                <MDBNavItem title={traducirPagina("Next_data").toUpperCase()}>
                  <button className="colorEmpresa" style={{ border: '0', padding: '5px', margin: '0px', backgroundColor: 'transparent', marginRight: '10px', display: 'flex', alignItems: 'center' }} onClick={() => setABRIR_FICHA_HAYNES(true)}> <ReadOutlined className="colorEmpresa" style={{ fontSize: '24px', color: '#fff', marginRight: '5px' }} /> {configApp.width <= 900 ? '' : traducirPagina("Next_data").toUpperCase()} </button>
                </MDBNavItem>
                :
                null
              :
              null
            }


{/*
            <MDBNavItem title={traducirPagina("Boletines")}>
              <button className="colorEmpresa" style={{ border: '0', padding: '5px', margin: '0px', backgroundColor: 'transparent', marginRight: '10px', display: 'flex', alignItems: 'center' }} onClick={() => setABRIR_FICHA_BOLETINES(true)}> <FileDoneOutlined className="colorEmpresa" style={{ fontSize: '24px', color: '#fff', marginRight: '5px' }} /> {configApp.width <= 900 ? '' : traducirPagina("Boletines").toUpperCase()} </button>
            </MDBNavItem>

 */}

 
            <MDBDropdown>
              <MDBDropdownToggle nav>
                <button style={{ border: '0', padding: '5px', margin: '0px', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}> <UserOutlined className="colorEmpresa" style={{ fontSize: '24px' }} /> </button>
              </MDBDropdownToggle>

              <MDBDropdownMenu right>
                <MDBDropdownItem onClick={() => { setABRIR_FICHA_PERFIL(true) }}> <SettingOutlined style={{ fontSize: '18px', verticalAlign: 'initial', width: '24px' }} />{traducirPagina("Mi_configuracion")} </MDBDropdownItem>
                <MDBDropdownItem onClick={() => { window.open("https://download.anydesk.com/AnyDesk.exe") }}><BlockOutlined style={{ fontSize: '18px', verticalAlign: 'initial', width: '24px' }} /> {traducirPagina("Soporte_remoto")}</MDBDropdownItem>
                <MDBDropdownItem onClick={() => { window.open("https://download.teamviewer.com/download/TeamViewer_Setup_x64.exe") }}><BlockOutlined style={{ fontSize: '18px', verticalAlign: 'initial', width: '24px' }} /> {traducirPagina("Soporte_remoto_Team")}</MDBDropdownItem>

                {window.location.hostname === "supporte85-flexfuel.servicenext.eu" ?
                  <MDBDropdownItem onClick={() => { setABRIR_FICHA_TUTORIAL(true) }}><VideoCameraOutlined style={{ fontSize: '18px', verticalAlign: 'initial', width: '24px' }} /> {traducirPagina("Video_tutorial")}</MDBDropdownItem>
                  :
                  null
                }




                <MDBDropdownItem onClick={() => {
                  logOut();
                  store.dispatch(
                    {
                      type: "SET_SESION_USER",
                      usuario: {}
                    }
                  )
                  history.push("/");
                }} ><ExportOutlined style={{ fontSize: '18px', verticalAlign: 'initial', width: '24px' }} /> {traducirPagina("Cerrar_sesion")}</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

          </MDBNavbarNav>
        </MDBNavbar>
      </div>

      {ABRIR_FICHA_BOLETINES ?
        <FichaBoletines
          title={traducirPagina("Boletines").toUpperCase()}
          ABRIR_FICHA={ABRIR_FICHA_BOLETINES}
          CERRAR_FICHA={setABRIR_FICHA_BOLETINES}

        >
        </FichaBoletines>

        : null}

      {ABRIR_FICHA_HAYNES ?
        <FichaHaynes
          title={traducirPagina("Next_data").toUpperCase()}
          ABRIR_FICHA={ABRIR_FICHA_HAYNES}
          CERRAR_FICHA={setABRIR_FICHA_HAYNES}

        >
        </FichaHaynes>

        : null}

      {ABRIR_FICHA_PERFIL ?
        <FichaPerfil
          title={traducirPagina("Mi_configuracion").toUpperCase()}
          ABRIR_FICHA={ABRIR_FICHA_PERFIL}
          CERRAR_FICHA={setABRIR_FICHA_PERFIL}

        >
        </FichaPerfil>

        : null}

      {
        ABRIR_FICHA_TUTORIAL ?
          <FichaTutorial
            title={traducirPagina("Video_tutorial").toUpperCase()}
            ABRIR_FICHA={ABRIR_FICHA_TUTORIAL}
            CERRAR_FICHA={setABRIR_FICHA_TUTORIAL}
          >
          </FichaTutorial>
          :
          null
      }

      <link rel="stylesheet" type="text/css" href={`plantillas/template${(USUARIO === undefined ? 'DEFAULT' : USUARIO.DISENYO_ESPECIAL === undefined && USUARIO.DISENYO_ESPECIAL === null ? 'DEFAULT' : USUARIO.DISENYO_ESPECIAL === "" ? 'DEFAULT' : USUARIO.DISENYO_ESPECIAL.toUpperCase())}.css`} />
      {/*  <link rel="stylesheet" type="text/css" href={`plantillas/templateASER.css`} /> */}

    </div>
  );
}


MasterPage.propTypes = {
  ABRIR_FICHA: PropTypes.bool,
  CERRAR_FICHA: PropTypes.func,

};

const mapStateToProps = (state, props) => {
  return {
    props: props,
    search: state.searchValue,
    configApp: state.configApp,
    usuario: state.usuario,
    clienteSeleccionado: state.clienteSeleccionado,
    activeItemReferencia: state.activeItemReferencia,
  };
}

export default connect(mapStateToProps)(MasterPage);





