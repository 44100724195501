import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';


const reducer = (state, action) => {
    switch (action.type) {
        case "SET_SESION_USER":
            return {
                ...state,
                usuario: action.usuario,
            }

        case "QUERY_USER":
            return {
                ...state,
                QUERY_USER: action.QUERY_USER,
            }
        case "CHANGE_DIMENSIONS_APP":
            return {
                ...state,
                configApp: { ...state.configApp, width: action.width, height: action.height }
            }

        case "UPDATE_IDIOMA_SERTEC":
            return {
                ...state,
                idiomaActual: action.idiomaActual
            }
    }

    return state;
};



const logger = store => next => action => {
    if (window.location.hostname === "licencias.servicenext.eu") {
        let result = next(action)
        return result;
    } else {
        console.log('dispatching', action)
        let result = next(action)
        console.log('next state', store.getState());
        return result;
    }
}


export default createStore(reducer, {
    usuario: {},
    QUERY_USER: {},
    configApp: {
        width: 0,
        height: 0,
        visualizacionDescuento: true,
        visualizacionFiltro: true,
        visualizacionGrafico: true
    },
    idiomaActual: {}
}, applyMiddleware(logger, thunk));
