import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MDBSelect } from "mdbreact";

import { connect } from 'react-redux';
import { actualizar_usuario_EnAPI, obtener_usuario_EnAPI, obtener_registros_idiomas_EnAPI } from "../action/actionCreatorsRequests";
import { MSGERROR, MSGOK, traducirPagina, obtenerUsuario } from "../librerias/funciones"
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { Select, Drawer, Input, Checkbox, Row, Col, Tabs } from 'antd';

import store from '../store'
const { Option } = Select;
const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;

const FichaPerfil = ({ props, configApp }) => {
    const [textoIdioma, setTextoIdioma] = React.useState(1);
    const [hayCliente, setHayCliente] = React.useState(false);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [idiomasLocal, setIdiomasLocal] = React.useState([]);
    const [passwordUsuario, setPasswordUsuario] = React.useState("");

    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            ID: 0,
            NOMBRE: "",
            CORREO_RECUPERACION_PWD: "",
            USUARIO: "",
            PWD_OLD: "",
            PWD_NEW: "",
            PWD: "",
            OBSERVACIONES: "",
            BAJA: false,
            ID_IDIOMA: "1",
        }
    )


    if (!hayCliente && props.ABRIR_FICHA) {
        setHayCliente(true);

        

        obtener_usuario_EnAPI().then(usuario => {
            if (usuario !== undefined && usuario !== null && usuario.REGISTROS !== null && usuario.REGISTROS.length > 0) {
                const registro = usuario.REGISTROS[0];
                setPayLoadLocal({
                    ID: registro.ID,
                    NOMBRE: registro.NOMBRE_TALLER,
                    CORREO_RECUPERACION_PWD: registro.CORREO_RECUPERACION_PWD,
                    USUARIO: registro.USUARIO,
                    PWD_OLD: "",
                    PWD_NEW: "",
                    PWD: "",
                    OBSERVACIONES: registro.OBSERVACIONES,
                    BAJA: registro.BAJA,
                    ID_IDIOMA: registro.ID_IDIOMA
                })

                setPasswordUsuario(registro.PWD)
                setTextoIdioma(registro.ID_IDIOMA)

            } else {
                setPayLoadLocal(null)
            }
        })

        obtener_registros_idiomas_EnAPI(1, "A").then(idiomas => {
            if (idiomas !== undefined) {
                if (idiomas !== undefined && idiomas !== null && idiomas.REGISTROS !== null && idiomas.REGISTROS.length > 0) {
                    setIdiomasLocal(idiomas.REGISTROS)
                } else {
                    setIdiomasLocal(null)
                }
            }
        })

    } else {
        if (!props.ABRIR_FICHA && hayCliente) {
            setHayCliente(false);
            setTextoIdioma(1)
            setTabPorDefecto("1")
            setIdiomasLocal([])
            setPasswordUsuario("")
            setPayLoadLocal({
                ID: 0,
                NOMBRE: "",
                CORREO_RECUPERACION_PWD: "",
                USUARIO: "",
                PWD_OLD: "",
                PWD_NEW: "",
                PWD: "",
                OBSERVACIONES: "",
                BAJA: false,
                ID_IDIOMA: "1",
            })
        }
    }


    const verificarNuevaContraseña = () => {
        let modificarContraseña = passwordUsuario
        let permitirGuardarContraseña = false

        //Si es nuevo usuario
        if (props.ID_USUARIO === -1) {
            permitirGuardarContraseña = true
            setPasswordUsuario(payLoadLocal.PWD_NEW);
            modificarContraseña = payLoadLocal.PWD_NEW;
        } else {
            if (payLoadLocal.PWD_OLD.trim() === "") {
                permitirGuardarContraseña = true
                modificarContraseña = passwordUsuario
            } else {
                if (payLoadLocal.PWD_OLD === modificarContraseña) {
                    if (payLoadLocal.PWD_NEW.trim() !== "") {
                        if (payLoadLocal.PWD_NEW === payLoadLocal.PWD) {

                            permitirGuardarContraseña = true
                            setPasswordUsuario(payLoadLocal.PWD_NEW);
                            modificarContraseña = payLoadLocal.PWD_NEW;
                        } else {
                            MSGERROR(traducirPagina("Msg_confirmar_pwd"))
                        }
                    } else {
                        MSGERROR(traducirPagina("Msg_pwd_vacia"))
                    }

                } else {
                    MSGERROR(traducirPagina("Msg_pwd_no_coincide"))
                }
            }
        }

        return permitirGuardarContraseña
    }


    const modificarUsuario = () => {

        let modificarContraseña = passwordUsuario

        if (payLoadLocal.PWD_OLD.trim() === "") {

            actualizar_usuario_EnAPI(payLoadLocal, passwordUsuario).then(usuario => {
                if (usuario.OK) {
                    MSGOK(traducirPagina("Msg_usuario_modificado"))
                    props.CERRAR_FICHA(false)
                }
            })

        } else {

            let permitirGuardarContraseña = verificarNuevaContraseña()
            if (permitirGuardarContraseña) {
                modificarContraseña = payLoadLocal.PWD_NEW;
                actualizar_usuario_EnAPI(payLoadLocal, modificarContraseña).then(usuario => {
                    if (usuario.OK) {
                        MSGOK(traducirPagina("Msg_usuario_modificado"))
                        props.CERRAR_FICHA(false)


                    }
                })

            }
        }
    }




    const guardarEstadoFormulario = (e) => {
        const NOMBRE = e.target.name === 'NOMBRE' ? e.target.value : payLoadLocal.NOMBRE;
        const CORREO_RECUPERACION_PWD = e.target.name === 'CORREO_RECUPERACION_PWD' ? e.target.value : payLoadLocal.CORREO_RECUPERACION_PWD;
        const USUARIO = e.target.name === 'USUARIO' ? e.target.value : payLoadLocal.USUARIO;
        const PWD_OLD = e.target.name === 'PWD_OLD' ? e.target.value : payLoadLocal.PWD_OLD;
        const PWD_NEW = e.target.name === 'PWD_NEW' ? e.target.value : payLoadLocal.PWD_NEW;
        const PWD = e.target.name === 'PWD' ? e.target.value : payLoadLocal.PWD;
        const OBSERVACIONES = e.target.name === 'OBSERVACIONES' ? e.target.value : payLoadLocal.OBSERVACIONES;
        const ID = e.target.name === 'ID' ? e.target.value : payLoadLocal.ID;
        const BAJA = e.target.name === 'BAJA' ? e.target.value : payLoadLocal.BAJA;
        const ID_IDIOMA = e.target.name === 'ID_IDIOMA' ? e.target.value : payLoadLocal.ID_IDIOMA;

        setPayLoadLocal(
            {
                ID: ID,
                NOMBRE: NOMBRE,
                CORREO_RECUPERACION_PWD: CORREO_RECUPERACION_PWD,
                USUARIO: USUARIO,
                PWD_OLD: PWD_OLD,
                PWD_NEW: PWD_NEW,
                PWD: PWD,
                OBSERVACIONES: OBSERVACIONES,
                BAJA: BAJA,
                ID_IDIOMA: ID_IDIOMA
            }
        )
    }

    const modificarIdioma = (e) => {
        payLoadLocal.ID_IDIOMA = e;
        setPayLoadLocal(payLoadLocal)
        setTextoIdioma(e)
    }



    return (

        <Drawer
            width={configApp.width <= 900 ? '100%' : 600}
            onClose={() => { props.CERRAR_FICHA(false) }}
            closable={false}

            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff' }}>


            <div className="flex-container-Grow-Tercero header_ficha" style={{ height: '56px' }}>
                <div style={{ textAlign: 'inherit' }}>
                    <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false); }} >
                        <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </button>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>

            {payLoadLocal === null ?
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h4>{traducirPagina("Msg_sin_info_usuario")}</h4>
                </div>
                :
                <>
                    <Tabs defaultActiveKey={"1"} activeKey={tabPorDefecto} onChange={(tab) => setTabPorDefecto(tab)}
                        style={{ padding: '0px 20px 0px 20px' }}
                    >
                        <TabPane tab={`${traducirPagina("Configuracion")}`}  key="1" style={{ height: (configApp.height - 183).toString() + "px", overflowY: 'auto', overflowX: 'hidden' }}>
                            <div style={{ padding: '20px 5px 0px 0px' }} >

                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>

                                    <Col md={24}>
                                        <span>{`${traducirPagina("Nombre_de_usuario")}: `} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input disabled={true} name="NOMBRE" value={payLoadLocal.NOMBRE} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{`${traducirPagina("Correo_recuperacion")}: `} </span>
                                    </Col>

                                    <Col md={24} >
                                        <Input name="CORREO_RECUPERACION_PWD" value={payLoadLocal.CORREO_RECUPERACION_PWD} onChange={(e) => guardarEstadoFormulario(e)} disabled={window.location.hostname === "supporte85-flexfuel.servicenext.eu" ? true:false} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{`${traducirPagina("Idioma_por_defecto")}: `} </span>
                                    </Col>
                                    {/* ID_IDIOMA */}
                                    <Col md={24}>
                                        {idiomasLocal !== null ?
                                            <select value={textoIdioma} className="custom-select"
                                                onChange={(e) => modificarIdioma(e.target.value)}>
                                                {idiomasLocal.length > 0 ?
                                                    idiomasLocal.map((idioma) =>
                                                        <option key={idioma.ID} value={idioma.ID}>{idioma.NOMBRE}</option>
                                                    )
                                                    :
                                                    null
                                                }
                                            </select>
                                            :
                                            <div style={{ textAlign: 'center', }}>
                                                <h4>{traducirPagina("Sin_idioma")}</h4>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>



                        <TabPane tab={`${traducirPagina("Credenciales")}`} key="2" style={{ height: (configApp.height - 183).toString() + "px", overflowY: 'auto', overflowX: 'hidden' }}>
                            <div style={{ padding: '20px 5px 0px 0px' }} >
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={24}>
                                        <span>{`${traducirPagina("Usuario")}: `} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input name="USUARIO" value={payLoadLocal.USUARIO} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span style={{ color: '#999' }}>{traducirPagina("Msg_modificar_pwd")} </span>
                                    </Col>

                                    <Col md={24}>
                                        <span>{`${traducirPagina("Pwd_actual")}: `} </span>
                                    </Col>
                                    <Col md={24}>
                                        <Input type="password" name="PWD_OLD" value={payLoadLocal.PWD_OLD} autoComplete="new-password" onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{`${traducirPagina("Nueva_pwd")}: `} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input type="password" name="PWD_NEW" value={payLoadLocal.PWD_NEW} autoComplete="new-password" onChange={(e) => { guardarEstadoFormulario(e) }} />
                                    </Col>

                                    <Col md={24}>
                                        <span>{`${traducirPagina("Confirma_pwd")}: `} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input type="password" name="PWD" value={payLoadLocal.PWD} autoComplete="new-password" onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>
                                </Row>

                            </div>
                        </TabPane>

                        <TabPane tab={`${traducirPagina("Observaciones")}`} key="3" style={{ height: (configApp.height - 183).toString() + "px", overflowY: 'auto', overflowX: 'hidden' }}>
                            <div style={{ padding: '20px 5px 0px 0px' }} >
                                <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                    <Col md={24}>
                                        <span>{`${traducirPagina("Observaciones")}: `} </span>
                                    </Col>
                                    <Col md={24} >
                                        <Input.TextArea name="OBSERVACIONES" rows={20} value={payLoadLocal.OBSERVACIONES} onChange={(e) => guardarEstadoFormulario(e)} />
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>

                    </Tabs>
                    <div style={{ flexGrow: '6', textAlign: 'right', padding: '20px 25px 20px 20px' }} >
                        <button className="activebtnMenu2" style={{ height: '40px', padding: '0px 20px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => modificarUsuario()}> {<SaveOutlined style={{ fontSize: '20px' }} />}   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_salir").toUpperCase()} </span> </button>
                    </div>
                </>
            }
        </Drawer >

    )
}

FichaPerfil.propTypes = {
    title: PropTypes.string,
    ID_USUARIO: PropTypes.number,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func

};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(FichaPerfil);