import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { recortarCadena, descargarFicheroPasandoBase64 } from '../librerias/funciones'

import {
    CloseOutlined, DownloadOutlined

} from '@ant-design/icons';

import { Drawer } from 'antd';

const VisorGenerico = ({ props, configApp }) => {
    const [descarga, setDescarga] = React.useState(false);


    return (
        <>
            {props.PROPIEDADES_DOC.TIPO_DOCUMENTO === "IMG" ?
                props.ABRIR_VISOR ?
                    <div className="Visor_Telegram">

                        <div className="Visor_Telegram_Menu">

                            <div className='Visor_Telegram_item_1'>
                                <span onClick={() => {
                                    props.CERRAR_VISOR(false);
                                }}> <CloseOutlined /> </span>

                                <span>{props.PROPIEDADES_DOC.TITULO.toUpperCase()}</span>
                            </div>


                            {props.MOSTRAR_BOTON_DESCARGA ?
                                <div className={`Visor_Telegram_item_2 ${descarga ? 'colorBloqueoBotoneraFacturacion' : 'btnColor'}`}
                                    onClick={() => {
                                        if (!descarga) {
                                            // ObtenerArchivo(ID_ARCHIVO, NOMBRE_ARCHIVO, TIPO_ARCHIVO, EXTENSION_ARCHIVO)
                                        }
                                    }} style={{ width: '14%', color: descarga ? '#999' : '#fff' }} >
                                    <span>{descarga ? "Descargando ..." : "Descargar"} <DownloadOutlined style={{ fontSize: '25px', verticalAlign: 'text-bottom', marginLeft: '5px' }}> </DownloadOutlined> </span>
                                </div>
                                :
                                null
                            }


                        </div>

                        <div className="Container_doc_Visor_Telegram">
                            {props.PROPIEDADES_DOC.URL === "" ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '75vh', color: '#fff' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                :
                                <img src={props.PROPIEDADES_DOC.URL} alt="" ></img>
                            }
                        </div>
                    </div>
                    :
                    null
                :

                <Drawer
                    width={configApp.width <= 900 ? '100%' : 800}
                    closable={false}
                    onClose={() => { props.CERRAR_VISOR(false) }}
                    visible={props.ABRIR_VISOR}
                    placement={"right"}
                    bodyStyle={{ padding: '0px', margin: '0px', lineHeight: '0px' }}
                    style={{ color: '#fff' }}>

                    <div className="flex-container-Grow-Tercero header_ficha" style={{ lineHeight: '23px' }} >
                        <div style={{ textAlign: 'inherit' }}>
                            <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_VISOR(false) }} >
                                <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                    </svg>
                                </i>
                            </button>
                        </div>

                        <div style={{ flexGrow: '3', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                            {configApp.width <= 900 ? recortarCadena(props.PROPIEDADES_DOC.TITULO.toUpperCase(), 20, "...") : props.PROPIEDADES_DOC.TITULO.toUpperCase()}
                        </div>
                        {props.MOSTRAR_BOTON_DESCARGA ?

                            <div disabled={descarga} className={`Visor_Telegram_item_2 ${descarga ? 'colorBloqueoBotoneraFacturacion' : 'btnColor'}`}
                                onClick={() => {
                                    if (!descarga) {
                                        // ObtenerArchivo(ID_ARCHIVO, NOMBRE_ARCHIVO, TIPO_ARCHIVO, EXTENSION_ARCHIVO)
                                    }

                                }} style={{ width: '30%', margin: '0px 10px', fontSize: '16px', color: descarga ? '#999' : '#fff' }} >
                                <span>{descarga ? "Descargando ..." : "Descargar"}  <DownloadOutlined style={{ fontSize: '25px', verticalAlign: 'text-bottom', marginLeft: '5px' }}> </DownloadOutlined> </span>
                            </div>
                            :
                            null
                        }

                    </div>

                    {props.PROPIEDADES_DOC.URL === "" ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '75vh', color: '#000' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                        :
                        props.PROPIEDADES_DOC.TIPO_DOCUMENTO === "DOC" ?
                            <iframe title='Visor de pdf' src={props.PROPIEDADES_DOC.URL} style={{ width: configApp.width <= 900 ? '100%' : '800px', height: configApp.height - 53 + "px", border: 0 }} ></iframe>
                            :
                            <div className='continaerVideo'>
                                <video width={configApp.width <= 900 ? '100%' : 800} controls>
                                    <source src={props.PROPIEDADES_DOC.URL} />
                                </video>
                            </div>


                    }

                </Drawer>
            }


        </>
    )
}


VisorGenerico.propTypes = {
    title: PropTypes.string,
    ABRIR_VISOR: PropTypes.bool,
    CERRAR_VISOR: PropTypes.func,
    PROPIEDADES_DOC: PropTypes.object,
    ZOOM_DOCUMENTO: PropTypes.bool,
    MOSTRAR_BOTON_DESCARGA: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
    };
}

export default connect(mapStateToProps)(VisorGenerico);