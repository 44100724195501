import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { obtener_haynes_urlEnAPI } from "../action/actionCreatorsRequests";

import {
    traducirPagina, buscarVehiculoLocal
} from "../librerias/funciones"

import FichaSeleccionVehiculo from './FichaSeleccionVehiculo'
import VisorGenerico from './VisorGenerico';

import {
    CalendarOutlined,
    EyeOutlined
} from '@ant-design/icons';
import { Tabs, Select, Row, Col, Input, Collapse, Modal, Button, Drawer } from 'antd';


const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;


const FichaHaynes = ({ props, configApp, HISTORIAL_VEHICULO }) => {
    const [enlaceHaynes, setEnlaceHaynes] = React.useState("");

    /*
    if (!hayEnlce && openDrawerHaynesPro && Object.keys(usuario).length > 0) {
        setHayEnlce(true)

        let idCocheTecdoc = fichaBusquedaVehiculo.historicapp.length === 0 ? 0:fichaBusquedaVehiculo.historicapp[2].id;

        loginHaynesProTouch(idCocheTecdoc).then(haynes => {
            if (haynes.OK) {
                setEnlaceHaynes(haynes.REDIRECT_URL);
            } else {
                setEnlaceHaynes(null)
            }
        })

    } else {
        if (!openDrawerHaynesPro && hayEnlce) {
            setEnlaceHaynes("")
            setHayEnlce(false)
        }
    }
*/
    
    React.useEffect(() => {
       obtener_haynes_urlEnAPI().then(haynes => {

            if (haynes.OK) {
                setEnlaceHaynes(haynes.REDIRECT_URL);
            } else {
                setEnlaceHaynes(null)
            }
        })

    }, [])




    return (
        <div>
            <Drawer
                width={configApp.width <= 900 ? '100%' : "95%"}
                onClose={() => {
                    props.CERRAR_FICHA(false)
                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero header_ficha" style={{ height: '56px' }}>
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false) }} >
                            <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                {enlaceHaynes !== null ?
                    enlaceHaynes === "" ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                        :
                        <iframe
                            id={"haynespro"}
                            width="100%"
                            height={(configApp.height - 60).toString() + "px"}
                            title={props.title}
                            frameBorder="0"
                            allowtransparency="true"
                            src={enlaceHaynes}
                         

                        />
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("Msg_haynespro")}</h3> </div>
                }

            </Drawer>

        </div>
    )
}

FichaHaynes.propTypes = {
    TIPO: PropTypes.string,
    CERRAR: PropTypes.object,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(FichaHaynes);