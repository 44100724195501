import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { obtener_grado_satisfaccionEnAPI, actualizar_feedbackEnAPI, documento_consutlaEnAPI } from "../action/actionCreatorsRequests";
import { MSGERROR, MSGOK, traducirPagina, obtenerLicenciaLocal } from "../librerias/funciones"
import FichaInfoConsulta from './FichaInfoConsulta';
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeFilled,
    SendOutlined,
    EyeOutlined
} from '@ant-design/icons';
import { Select, Drawer, Input, Checkbox, Row, Col, Tabs } from 'antd';

import store from '../store'
const { Option } = Select;
const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;

const FichaFeedback = ({ props, configApp }) => {
    const [abrirFichaInfoConsulta, setAbrirFichaInfoConsulta] = React.useState(false);
    const [htmlTarea, setHtmlTarea] = React.useState("");

    const [hayDatos, setHayDatos] = React.useState(false);
    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            ID_TAREA: 0,
            GRADO_SASTISSFACION: 1,
            TEXTO_GRADO: "",
        }
    )
    const [lista_grado, setLista_grado] = React.useState([])
    const [cerrarFicha, setCerrarFicha] = React.useState(false);




    const obtener_grado_satisfaccion = () => {
        obtener_grado_satisfaccionEnAPI().then((GRADO) => {
            if (GRADO !== undefined && GRADO !== null && GRADO.REGISTROS !== null && GRADO.REGISTROS.length > 0) {
                GRADO.REGISTROS.map((registro) => {
                    registro.SELECCIONADO = false;
                    registro.URL = `./imagenes/caras/${registro.ID}.png`
                })
                const GRADOS = GRADO.REGISTROS.filter((item) => item.ID !== 1)

                setLista_grado(GRADOS);
            } else {
                setLista_grado(null);
            }
        })
    }



    const documento_consutla = (ID) => {
        documento_consutlaEnAPI(ID).then((REGISTRO) => {
            if (REGISTRO !== undefined && REGISTRO !== null) {
                setHtmlTarea(REGISTRO.HTML_TAREA);
            } else {
                setHtmlTarea(null);
            }
        })
    }



    const enviarComentarios = () => {
        const { ID_TAREA, GRADO_SASTISSFACION, TEXTO_GRADO } = payLoadLocal

        if (GRADO_SASTISSFACION > 1 && TEXTO_GRADO) {

            actualizar_feedbackEnAPI(ID_TAREA, GRADO_SASTISSFACION, TEXTO_GRADO).then((REGISTRO) => {
                setCerrarFicha(true)
                if (REGISTRO !== undefined && REGISTRO !== null && REGISTRO.OK) {
                    props.ACTUALIZAR_TABLA(3, "D", false, props.FILTROS.TAREAS_CERRADAS, obtenerLicenciaLocal().ID_TALLER, null, props.FILTRO_FECHA.FECHA_PARTIDA, props.FILTRO_FECHA.ACCION_DESDE, props.FILTRO_FECHA.ACCION_HASTA)
                    props.CERRAR_FICHA(false)
                }
            })


        } else {
            MSGERROR(traducirPagina("Msg_texto_grado"))
        }


    }


    if (!hayDatos && props.ABRIR_FICHA) {
        setHayDatos(true);
        payLoadLocal.ID_TAREA = props.DATOS_GRADO.ID;
        setPayLoadLocal({ ...payLoadLocal })

        obtener_grado_satisfaccion();

        documento_consutla(props.DATOS_GRADO.ID);
    } else {
        if (!props.ABRIR_FICHA && hayDatos) {
            setHayDatos(false);
        }
    }


    return (
        <>
            <Drawer
                width={configApp.width <= 900 ? '100%' : 800}
                onClose={() => {
                    //props.MODIFICA_ENVIO_FEED_BACK(false)
                    if (cerrarFicha) {
                        props.CERRAR_FICHA(false)

                    }
                }}
                closable={false}

                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>


                <div className="flex-container-Grow-Tercero " style={{ height: '56px' }}>
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            // props.MODIFICA_ENVIO_FEED_BACK(false)
                            if (cerrarFicha) {

                                props.CERRAR_FICHA(false);
                            }

                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>

                </div>


                <div className='body_feedback'>
                    {lista_grado === null ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: 'calc(100vh - 130px)' }}>   <h4 style={{ margin: '0px' }}>{traducirPagina("Msg_sin_consulta")}</h4> </div>


                        :

                        lista_grado.length > 0 ?
                            <>
                                <div className='body_container'>
                                    <div className='grado_feedback'>
                                        <span style={{ fontSize: '18px', fontWeight: 'normal', color: '#000', display: 'block', marginBottom: '30px' }}>
                                            {traducirPagina("Msg_consulta_opinion")}
                                        </span>
                                        <span style={{ fontSize: '16px', fontWeight: 'normal', color: '#0F6AFB', cursor: 'pointer', display: 'block', marginBottom: '30px' }} onClick={() => {
                                            setAbrirFichaInfoConsulta(true)
                                        }}><EyeOutlined style={{ fontSize: '20px', verticalAlign: 'inherit' }}></EyeOutlined> {traducirPagina("Msg_recordar")}</span>


                                        <span>{traducirPagina("grado_sastisfacion")}</span>

                                        <div className='container_caras_feedback'>
                                            {lista_grado.map((registro) =>
                                                <div className='caras_feedback' onClick={() => {
                                                    lista_grado.map((item) => {
                                                        if (item.ID === registro.ID) {
                                                            item.SELECCIONADO = true
                                                        } else {
                                                            item.SELECCIONADO = false
                                                        }
                                                    })

                                                    setLista_grado([...lista_grado])


                                                    const { ID } = lista_grado.find((registro) => registro.SELECCIONADO === true);
                                                    if (ID !== undefined) {
                                                        payLoadLocal.GRADO_SASTISSFACION = ID
                                                        setPayLoadLocal({ ...payLoadLocal })
                                                    }

                                                }}>

                                                    <img src={registro.URL} style={registro.SELECCIONADO ? {} : { opacity: '0.3' }}></img>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='texto_grado_feedback'>
                                        <span>{traducirPagina("grado_resolucion")}</span>

                                        <Input.TextArea style={{ fontSize: '16px', fontWeight: '500' }} rows={10} value={payLoadLocal.TEXTO_GRADO} onChange={(e) => {

                                            payLoadLocal.TEXTO_GRADO = e.target.value;
                                            setPayLoadLocal({ ...payLoadLocal })

                                        }} />
                                    </div>

                                </div>

                                <div className='body_botones'>
                                    <div onClick={() => {
                                        enviarComentarios();
                                    }}>
                                        <SendOutlined></SendOutlined>
                                        {traducirPagina("Enviar")}
                                    </div>
                                </div>
                            </>
                            :
                            <div style={{ display: 'flex', alignItems: 'center', minHeight: '20vh', margin: '20px auto' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled />  </div>

                    }
                </div>
            </Drawer>

            {
                abrirFichaInfoConsulta ?
                    <FichaInfoConsulta
                        title={traducirPagina("Consulta").toUpperCase()}
                        ABRIR_FICHA={abrirFichaInfoConsulta}
                        CERRAR_FICHA={setAbrirFichaInfoConsulta}
                        DOCUMENTO_CONSULTA={props.DATOS_GRADO}
                        HTML_TAREA={htmlTarea}
                    >
                    </FichaInfoConsulta>
                    :
                    null
            }
        </>

    )
}

FichaFeedback.propTypes = {
    title: PropTypes.string,
    ID_USUARIO: PropTypes.number,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    DATOS_GRADO: PropTypes.object,

    ACTUALIZAR_TABLA: PropTypes.func,
    FILTRO_FECHA: PropTypes.object,
    FILTROS: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(FichaFeedback);