import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBCol, MDBRow, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import { Drawer } from 'antd';
import { traducirPagina } from '../librerias/funciones';
const FichaSeleccionVehiculo = ({ props, configApp }) => {
    const [activeItemOuterTabs, setActiveItemOuterTabs] = React.useState("1");
    let datosCochePorMatricula = props.DATOS_COCHE;


    return (
        <Drawer
            width={configApp.width <= 900 ? '100%' : "90%"}
            closable={false}
            onClose={() => { props.CERRAR_FICHA(false) }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff', zIndex: '9999' }}>



            <div className="flex-container-Grow-Tercero header_ficha" style={{height:'56px'}}>
                <div style={{ textAlign: 'inherit' }}>
                    <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false) }} >
                        <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </button>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>


            <div className="container-fluid" style={{ height: "calc(100vh - 56px)", overflowY: 'auto', overflowX: 'hidden' }}>
                <MDBRow>
                <MDBCol md="5">
                        <MDBNav tabs className="nav-justified mt-3" color="primary">

                            <MDBNavItem>
                                <MDBNavLink className="headerTabReferencia" link to="#" active={activeItemOuterTabs === "1"} onClick={() => setActiveItemOuterTabs("1")} role="tab">{traducirPagina("Identificacion")}</MDBNavLink>
                            </MDBNavItem>

                            <MDBNavItem>
                                <MDBNavLink className="headerTabReferencia" link to="#" active={activeItemOuterTabs === "2"} onClick={() => setActiveItemOuterTabs("2")} role="tab" >DGT</MDBNavLink>
                            </MDBNavItem>

                            <MDBNavItem>
                                <MDBNavLink className="headerTabReferencia" link to="#" active={activeItemOuterTabs === "3"} onClick={() => setActiveItemOuterTabs("3")} role="tab">{traducirPagina("Ficha_tecnica")}</MDBNavLink>
                            </MDBNavItem>
                        </MDBNav>

                        <MDBTabContent activeItem={activeItemOuterTabs} style={{ height: (configApp.height - 120).toString() + "px", overflowY: 'auto', overflowX: 'hidden', backgroundColor: "#eee", padding: '40px 20px 21px 20px' }}>
                            <MDBTabPane tabId="1" role="tabpanel">
                                <div style={{ color: '#000', fontSize: '16px' }}>

                                    <h4 >{traducirPagina("Datos_tecnicos")}</h4>
                                    <MDBTable responsive hover>
                                        {Object.keys(datosCochePorMatricula).length > 0 ?
                                            datosCochePorMatricula.IDENTIFICACION_VEHICULO !== null && datosCochePorMatricula.IDENTIFICACION_VEHICULO !== undefined ?
                                                <MDBTableBody>
                                                    {datosCochePorMatricula.Matricula !== null ?
                                                        <tr>
                                                            <td style={{ textAlign: 'left' }}>{traducirPagina("Matricula")}</td>
                                                            <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.Matricula.Matricula}</td>
                                                        </tr>
                                                        : null
                                                    }
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{(traducirPagina("Bastidor"))}</td>
                                                        <td id="textoACopiar" style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.BASTIDOR}</td>
                                                       {/* <td style={{ textAlign: 'left' }}>{<button onClick={e => { copiarAlPortapapeles('textoACopiar') }} style={{ border: '0', cursor: 'pointer' }} ></button>}</td>*/}
                                                    </tr>


                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{`${traducirPagina("Fecha")} 1º ${(traducirPagina("Matricula").toLowerCase())}`} </td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.FECHA1ªMATRÍCULA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Fecha_de_matriculacion")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.FECHAMATRÍCULA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.MARCA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Mercado")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.MERCADO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.MODELO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo_generico")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.MODELOGENÉRICO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Numero_de_transmisiones")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.NÚMERODETRANSMISIONES}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Procedencia")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.PROCEDENCIA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Provincia_matricula")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.PROVINCIAMATRÍCULA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Segmento")}</td>
                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.SEGMENTO}</td>
                                                    </tr>
                                                </MDBTableBody>
                                                :
                                                null
                                            :
                                            null
                                        }
                                    </MDBTable>


                                </div>
                            </MDBTabPane>

                            <MDBTabPane tabId="2" role="tabpanel">
                                <div style={{ color: '#000', fontSize: '16px' }}>
                                    <div className="row">
                                        <MDBCol md="12">
                                            <h4 className="mt-1">{traducirPagina("Datos_tecnicos")}</h4>
                                            <MDBTable responsive hover>
                                                {Object.keys(datosCochePorMatricula).length > 0 ?
                                                    datosCochePorMatricula.DATOSTECNICOSDGT !== null && datosCochePorMatricula.DATOSTECNICOSDGT !== undefined ?
                                                        <MDBTableBody>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindrada")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.CILINDRADA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>CO2</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.CO2}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.COMBUSTIBLE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>KW</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.KW}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.MARCA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.MODELO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Peso_maximo")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.PESOMAXIMO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Plazas")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.PLAZAS}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_fiscal")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.POTENCIAFISCAL}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_aproximada")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.POTENCIAREALAPROX}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Tara")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.TARA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.TIPO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Variante")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.VARIANTE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Version")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.VERSION}</td>
                                                            </tr>
                                                        </MDBTableBody>
                                                        :
                                                        null
                                                    :
                                                    null
                                                }
                                            </MDBTable>
                                        </MDBCol>
                                    </div>
                                </div>
                            </MDBTabPane>

                            <MDBTabPane tabId="3" role="tabpanel">
                                <div style={{ color: '#000', fontSize: '16px' }}>
                                    <div className="row">
                                        <MDBCol md="12">
                                            <h4 className="mt-1">{traducirPagina("Datos_tecnicos")}</h4>
                                            <MDBTable responsive hover>
                                                {Object.keys(datosCochePorMatricula).length > 0 ?
                                                    datosCochePorMatricula.FICHATECNICA !== null && datosCochePorMatricula.FICHATECNICA != undefined ?
                                                        <MDBTableBody>

                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Carroceria")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CARROCERIA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("CAT_HOMOLOGACIÓN")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CAT_HOMOLOGACION}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindrada")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CILINDRADA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Clasif_Industria")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CLASIF_INDUSTRIA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>CO2</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CO2}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Codigos_ECO")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CODIGOS_ECO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.COMBUSTIBLE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Consumo")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CONSUMO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Contrasenia_homologacion")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CONTRASENA_HOLOMOGACION}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{"Denominacion_comercial"}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.DENOMINACION_COMERCIAL}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Dist_ejes")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.DIST_ENTRE_EJES}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("ECO_innova")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.ECO_INNOVA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Fabricante")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.FABRICANTE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>KW</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.KW}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.MARCA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Masa_carga")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.MASA_MAX_CARGA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Masa_vehiculo_servicio")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.MASA_VEHICULO_SERVICIO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Nivel_emisiones")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.NIVEL_EMISIONES}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Num_pie")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.NUM_PLAZAS_DE_PIE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Peso_maximo")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.PESOMAXIMO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Plazas")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.PLAZAS}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_fiscal")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.POTENCIAFISCAL}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_aprox")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.POTENCIAREALAPROX}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Procedencia")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.PROCEDENCIA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Reduccion_eco")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.REDUCCION_ECO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Relacion_potencia_peso")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.RELACION_POTENCIA_PESO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Tara")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.TARA}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.TIPO}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo_alimentacion")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.TIPO_ALIMENTACION}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Variante")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.VARIANTE}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Version")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.VERSION}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Via_anterior")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.VIA_ANTERIOR}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: 'left' }}>{traducirPagina("Via_posterior")}</td>
                                                                <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.VIA_POSTERIOR}</td>
                                                            </tr>
                                                        </MDBTableBody>
                                                        :
                                                        null
                                                    :
                                                    null
                                                }
                                            </MDBTable>
                                        </MDBCol>
                                    </div>
                                </div>
                            </MDBTabPane>
                        </MDBTabContent>
                    </MDBCol>
                    
                <MDBCol md="7" style={{ marginTop: '3.4rem' }} className="mdb-skin">
                        <MDBRow>
                            <MDBCol md="12">

                                <div >

                                    <div style={{ color: '#000', fontSize: '16px' }}>

                                        <p className="mt-1">{traducirPagina("Msg_vehiculo_descripcion") }</p>
                                        {Object.keys(datosCochePorMatricula).length > 0 ?
                                            datosCochePorMatricula.VEHICULOS_TECDOC !== undefined && datosCochePorMatricula.VEHICULOS_TECDOC !== null ?
                                                <MDBTable responsive hover>
                                                    <MDBTableHead>
                                                        <tr>
                                                            <th style={{ textAlign: 'left' }}>{traducirPagina("Motor")}</th>
                                                            <th style={{ textAlign: 'left' }}>{traducirPagina("Codigo")}</th>
                                                            <th style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</th>
                                                            <th>{traducirPagina("Anio")}</th>
                                                            <th>KW</th>
                                                            <th>CV</th>
                                                            <th>CC</th>
                                                            <th>{traducirPagina("Carroceria")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </MDBTableHead>

                                                    <MDBTableBody>
                                                        {datosCochePorMatricula.VEHICULOS_TECDOC.map((row, i) =>
                                                            <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }}>
                                                                <td style={{ textAlign: 'left' }} onClick={() => {
                                                                    props.VEHICULO_SELECCIONADO(row)

                                                                }} >{row.MOTORIZACION}</td>

                                                                <td style={{ textAlign: 'left' }} onClick={() => {
                                                                    props.VEHICULO_SELECCIONADO(row)
                                                                }}>{row.MOTORES === "" ? "..." : row.MOTORES}</td>

                                                                <td style={{ textAlign: 'left' }} onClick={() => {
                                                                    props.VEHICULO_SELECCIONADO(row)
                                                                }}>{row.MODELO === "" ? "..." : row.MODELO}</td>

                                                                <td onClick={() => {
                                                                    props.VEHICULO_SELECCIONADO(row)
                                                                }}>{row.DESDE === "" ? "..." : row.DESDE}</td>

                                                                <td onClick={() => {
                                                                    props.VEHICULO_SELECCIONADO(row)
                                                                }}>{row.KW === "" ? "..." : row.KW}</td>
                                                                <td onClick={() => {
                                                                    props.VEHICULO_SELECCIONADO(row)
                                                                }}>{row.CV === "" ? "..." : row.CV}</td>
                                                                <td onClick={() => {
                                                                    props.VEHICULO_SELECCIONADO(row)
                                                                }}>{row.CC === "" ? "..." : row.CC}</td>
                                                                <td onClick={() => {
                                                                    props.VEHICULO_SELECCIONADO(row)
                                                                }}>{row.CARROCERIA === "" ? "..." : row.CARROCERIA}
                                                                </td>

                                                            </tr>
                                                        )}
                                                    </MDBTableBody>
                                                </MDBTable>
                                                : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}>  <div className="spinner-border  fast colorSubAzul" role="status" disabled /></div>

                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>

                    </MDBCol>
                    
                  

                  
                </MDBRow>
            </div>



        </Drawer>

    );
}




FichaSeleccionVehiculo.propTypes = {
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    DATOS_COCHE: PropTypes.object,
    VEHICULO_SELECCIONADO: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp

    };
}

export default connect(mapStateToProps)(FichaSeleccionVehiculo);