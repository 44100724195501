
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { MDBCard, MDBCardBody } from 'mdbreact';

import { obtener_fabricantesEnAPI, obtener_modelosEnAPI, obtener_imagen_vehiculoEnAPI, obtener_vehiculoEnAPI } from "../action/actionCreatorsRequests";
import { MSGERROR, MSGOK, traducirPagina } from "../librerias/funciones"
import {
    SearchOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { Drawer } from 'antd';

const FichaVehiculoTecdoc = ({ props, configApp }) => {
    const [vehiculo, setVehiculo] = React.useState({});
    const [imagenVehiculo, setImagenVehiculo] = React.useState("");
    const [hayCliente, setHayCliente] = React.useState(false);


    const buscarImagenVehiculoEnAPI = async (ID_VEHICULO) => {
        setImagenVehiculo("");
        const vehiculo = await obtener_imagen_vehiculoEnAPI(ID_VEHICULO);
        if (vehiculo !== undefined && vehiculo !== null && vehiculo.IMAGEN !== null) {
            setImagenVehiculo(vehiculo.IMAGEN);
        } else {
            setImagenVehiculo(null);
        }
    }


    const buscarVehiculoAPI = async (codmotorizacion) => {
        const resultado = await obtener_vehiculoEnAPI(codmotorizacion);

        if (resultado !== undefined && resultado !== null && resultado.REGISTRO !== undefined && resultado.REGISTRO !== null && Object.keys(resultado.REGISTRO).length > 0) {
            setVehiculo(resultado.REGISTRO);
        } else {
            setVehiculo(null);
        }
    }


    if (!hayCliente && props.ABRIR_FICHA) {
        setHayCliente(true);

        buscarVehiculoAPI(props.COD_MOTOR)
        buscarImagenVehiculoEnAPI(props.COD_MOTOR);

    } else {
        if (!props.ABRIR_FICHA && hayCliente) {
            setHayCliente(false);
        }
    }

    return (
        <>
            <Drawer
                width={configApp.width <= 900 ? '100%' : 700}
                onClose={() => { props.CERRAR_FICHA(false) }}
                closable={false}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero header_ficha" style={{ height: '56px' }}>
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false) }} >
                            <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>
                    

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>

                </div>

                <div className='containerVehiculo'>

                    <div className='historialVehiculo'>
                      
                        {vehiculo !== null ?
                            <>
                                {
                                    vehiculo.MARCA === "" ?
                                        null :
                                        <>
                                        <div>
                                            {vehiculo.MARCA}
                                        </div>
                                        <div>
                                            /
                                        </div>
                                        </>
                                }
                              
                                {

                                    vehiculo.MODELO === "" ?
                                        null :
                                        <>
                                        <div>
                                            {vehiculo.MODELO}
                                        </div>
                                           <div>
                                           /
                                       </div>
                                       </>
                                }
                                {

                                    vehiculo.MOTORIZACION === "" ?
                                        null :
                                        <span>
                                            {vehiculo.MOTORIZACION}
                                        </span>
                                }
                            </>
                            :
                            null
                            }


                    </div>
                </div>



                <div className='bodyFichaVehciculo'>
                    <div className='bodyFichaVehciculo_item1'>
                        {traducirPagina("Anio_fabricacion")}

                        <table style={{ border: '1px solid #d9d9d9', width: '100%', color: '#000' }} >
                            <thead>
                                <tr style={{ backgroundColor: '#dcdcdc' }}>
                                    <th style={{ width: '120px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => { }}>{ }</th>
                                    <th style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} onClick={(e) => { }}>{ }</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ backgroundColor: '#fff' }}>
                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>{traducirPagina("Desde")}</td>
                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>{vehiculo !== null ? vehiculo.DESDE : ""}</td>
                                </tr>

                                <tr style={{ backgroundColor: '#fff' }}>
                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>{traducirPagina("Hasta")}</td>
                                    <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>{vehiculo !== null ? vehiculo.HASTA : ""}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div className='bodyFichaVehciculo_item2'>
                        <div className='cardImagenVehiculo'>
                            <div className='cardImagenVehiculoBody'>
                            {imagenVehiculo === null ?
                                    <img src={"./imagenes/sinimagen.jpg"}/>
                                    :
                                    imagenVehiculo === "" ?
                                        <div className='skeleton-box' style={{ width: '180px', height: '151px' }}></div>
                                        :
                                        <img src={imagenVehiculo} />
                                }
                            </div>
                        </div>
                    </div>


                    <div className='bodyFichaVehciculo_item3'>
                        {vehiculo === null ?
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <h4>{traducirPagina("Msg_sin_resultados")}</h4>
                            </div>
                            :
                            Object.keys(vehiculo).length > 0 ?
                                <>
                                   {traducirPagina("Datos_tecnicos")}

                                    <table style={{ border: '1px solid #d9d9d9', width: '100%', color: '#000' }}>
                                        <thead>
                                            <tr style={{ backgroundColor: '#dcdcdc' }}>
                                                <th style={{ width: '200px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => { }}>{ }</th>
                                                <th style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => { }}>{ }</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{"ID TECDOC"}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{props.COD_MOTOR}</td>
                                            </tr>

                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Carroceria")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.CARROCERIA}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Tipo_traccion")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.TRACCION}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{`${traducirPagina("Potencia")}[KW]`}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.KW}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{`${traducirPagina("Potencia")}[CV]`}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.CV}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{`${traducirPagina("Cilindrada")}[CC]`}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.CC}</td>
                                            </tr>

                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.COMBUSTIBLE}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Catalizador")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.CATALIZADOR}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Cilindrada")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.CILINDROS}</td>
                                            </tr>
                                      
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Litros")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.LITROS}</td>
                                            </tr>


                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.MARCA}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.MODELO}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Motores")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.MOTORES}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Tipo_motor")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.MOTORIZACION}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Proceso_combustion")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.PROCESO_COMBUSTION}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Sistema_frenado")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.SISTEMA_FRENADO}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{"TCS"}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.TCS}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Tipo_frenado")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.TIPO_FRENADO}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Tipo_motor")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.TIPO_MOTOR}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Tracción")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.TRACCION}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Transmisión")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.TRANSMISION}</td>
                                            </tr>
                                            <tr  style={{ backgroundColor: '#fff' }}>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{traducirPagina("Válvulas")}</td>
                                                <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }}>{vehiculo.VALVULAS}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </>
                                :
                                <div style={{ display: 'flex', alignItems: 'center', minHeight: '20vh', margin: '20px auto' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled />  </div>

                        }
                    </div>
                </div>

            </Drawer>
        </>
    )
}

FichaVehiculoTecdoc.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    COD_MOTOR: PropTypes.number,

};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        QUERY_USER: state.QUERY_USER

    };
}

export default connect(mapStateToProps)(FichaVehiculoTecdoc);