import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { solicitud_informacion_servicioEnAPI } from "../action/actionCreatorsRequests";

import {
    traducirPagina, MSGERROR,MSGOK
} from "../librerias/funciones"

import {
    CalendarOutlined,
    EyeOutlined
} from '@ant-design/icons';
import { Tabs, Select, Row, Col, Input, Collapse, Modal, Button, Drawer } from 'antd';


const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;


const FichaPublicidadAssist = ({ props, configApp }) => {

    const enviarInformacion = (SERVICIO) => {
        solicitud_informacion_servicioEnAPI(SERVICIO).then(mensaje => {
            if (mensaje !== undefined) {
                if (mensaje.OK) {
                    MSGOK(traducirPagina("mensaje_enviado_departamento"));
                } else {
                    MSGERROR(traducirPagina("sin_envio_email"));
                }
            }

        })

    }


    return (
        <div>
            <Drawer
                width={configApp.width <= 900 ? '100%' : "450px"}
                onClose={() => {
                    props.CERRAR_FICHA(false)
                }}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                closable={false}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero header_ficha" style={{ height: '56px' }}>
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false) }} >
                            <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="containerPublicidadAssit">
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <img src={"/imagenes/logonextassit.png"} style={{ width: '100%' }} ></img>
                    </div>

                    <br></br>

                    <div style={{ fontSize: '16px', textAlign: 'center' }}>
                        <div>
                            <h4 style={{ color: '#fff' }}>{traducirPagina("msgFicPubl_NextAssist_01")}</h4>
                            <br></br>
                            {traducirPagina("msgFicPubl_NextAssist_02")}
                            <br></br>
                            <br></br>
                            {traducirPagina("msgFicPubl_NextAssist_03")}
                            <br></br>
                            {traducirPagina("msgFicPubl_NextAssist_04")}
                            <br></br>
                            <br></br>
                            {traducirPagina("msgFicPubl_NextAssist_05")}
                        </div>

                        <br></br>
                        <a href={"https://servicenext.eu/nextassist/"} target="_blank">{traducirPagina("Mas_informacion")}</a>
                    </div>


                    <br></br>
                    <br></br>
                    <div style={{ fontSize: '16px', textAlign: 'center' }}>

                        {traducirPagina("Me_gustaria_contratar_servicio")}
                    </div>

                    <br></br>

                    <div style={{ fontSize: '16px', textAlign: 'center' }}>
                        <button style={{ padding: '10px', border: '0', borderRadius: '3px', fontSize: '18px', verticalAlign: 'bottom', backgroundColor: '#1e3a83', marginRight: '10px' }} onClick={(e) => { enviarInformacion("NEXT ASSIST") }}>{traducirPagina("Ponerse_contacto_conmigo")}</button>
                    </div>

                    <br></br>
                    <br></br>
                    <br></br>

                    <div>
                        <img src="/imagenes/next.jpeg" style={{ width: '100%' }} ></img>
                    </div>
                </div>

            </Drawer>

        </div>
    )
}

FichaPublicidadAssist.propTypes = {
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(FichaPublicidadAssist);