
import store from '../store'
import { getDateToday } from "../librerias/herramientas"
import alertify from 'alertifyjs'
import {  obtenerPaginas } from '../librerias/funciones'


const changeDimensionsApp = (width, height) => {
    return {
        type: "CHANGE_DIMENSIONS_APP",
        width,
        height
    }
}




const updateStateMasterPage = (estado) => {
    return {
        type: "HANDLE_STATE_MASTER_PAGE",
        showmasterpage: estado
    }
}


const cambioImagenPais = (event) => {
    let banderaActual = event.textContent.trim() === "PORTUGAL" ? "./imagenes/POR.png" : event.textContent.trim() === "FRANCIA" ? "./imagenes/FRA.png" : "./imagenes/ESP.png"
    let etiqueta = document.getElementById("paisVehiculo");
    if (etiqueta !== undefined) {
        let att = document.createAttribute("title");
        att.value = event.textContent.trim();
        etiqueta.setAttributeNode(att);
    }

    return {
        type: "REPLACE_BANDERA_PAIS",
        banderaImagen: banderaActual,
        codPaisIdioma: event.textContent.trim().charAt(0)
    }

}




export {
    changeDimensionsApp, updateStateMasterPage, cambioImagenPais

};