import React from "react";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import MasterPage from '../pages/MasterPage';
import {
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from 'mdbreact';
import { obtener_registros_paginadosEnAPI, obtener_anyos_EnAPI, obtener_paises_EnAPI, hay_feedback_pendientesEnAPI } from "../action/actionCreatorsRequests";

import FichaTarea from "./FichaTarea";
import FichaFeedback from "./FichaFeedback";
import store from "../store";

import { Tabs, Select, Row, Col, Input, Collapse, Modal, Button, Pagination, Checkbox } from 'antd';

import {
    convertirAMesACodigo, obtenerFechaLocalObjeto, convertirCodigoAMeses, obtenerToken, construirFecha,
    obtenerLicenciaLocal, recortarCadena, ordenarTablaLocal, traducirPagina

} from "../librerias/funciones"


import {
    CloseOutlined,
    SearchOutlined,
    PrinterOutlined,
    PlusOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    FileDoneOutlined,
    EyeOutlined,
    CheckSquareOutlined,
    CloseSquareOutlined,
    HistoryOutlined,
    QuestionCircleFilled,
    QuestionCircleOutlined

} from '@ant-design/icons';
import { Layout } from 'antd';
import FichaPublicidadAssist from "./FichaPublicidadAssist";
const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;



const ContainerTareas = ({ props, configApp, QUERY_USER,usuario }) => {
    const anchoPorDefecto = 1300;

    const [ID_GRADO_SAST, setID_GRADO_SAST] = React.useState(0);
    const [datosGrado, setDatosGrado] = React.useState({});



    const [esNuevaTarea, setEsNuevaTarea] = React.useState(false);

    const [abrirFeedback, setAbrirFeedback] = React.useState(false);

    const [filtrosMovil, setFiltrosMovil] = React.useState(false);

    const [registroSeleccionado, setRegistroSeleccionado] = React.useState({});
    const [hayLicencias, seHayLicencias] = React.useState(false);

    const [abirPublicidad, setAbirPublicidad] = React.useState(false);

    const [textoBuscador, setTextoBuscador] = React.useState("");
    const [cabeceraTabla, setCabeceraTabla] = React.useState(
        {
            ID_TAREA: false,
            USUARIO_TALLER_ALTA: false,
            MATRICULA: false,
            FECHA_ALTA: false,
            MATRICULA_MARCA: false,
            NOMBRE_GRUPO_MONTAJE: false,
            TEXTO_CONSULTA: false,
            NOMBRE_ESTADO: false
        }
    );

    const [licencias, setLicencias] = React.useState([]);
    const [licenciasAux, setLicenciasAux] = React.useState([]);
    const [datosPaginacion, setDatosPaginacion] = React.useState({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 });
    const [abrirFichaTarea, setAbrirFichaTarea] = React.useState(false);
    const [mesesDelAnio, setMesesDelAnio] = React.useState(
        [
            traducirPagina("Enero"),
            traducirPagina("Febrero"),
            traducirPagina("Marzo"),
            traducirPagina("Abril"),
            traducirPagina("Mayo"),
            traducirPagina("Junio"),
            traducirPagina("Julio"),
            traducirPagina("Agosto"),
            traducirPagina("Septiembre"),
            traducirPagina("Octubre"),
            traducirPagina("Noviembre"),
            traducirPagina("Diciembre"),
        ]
    );

    const [anios, setAnios] = React.useState([]);
    const [mesSeleccionado, setMesSeleccionado] = React.useState("")
    const [anioSeleccionado, setAnioSeleccionado] = React.useState("")

    const [filtroSeleccionado, setFiltroSeleccionado] = React.useState({
        ESTADO_TAREA: 0,
        GRUPO_MONTAJE: 0,
        TAREAS_CERRADAS: true
    });

    const [filtroFecha, setFiltroFecha] = React.useState({
        CAMBIO_DIA: false,
        CAMBIO_MES: false,
        CAMBIO_ANIO: true,
        CAMBIO_TODAS: false,
        ACCION_DESDE: 2,
        ACCION_HASTA: 3,
        FECHA_PARTIDA: ""
    });


    const buscarAnyosAPI = async () => {
        const anyos = await obtener_anyos_EnAPI();
        if (anyos !== undefined && anyos !== null && anyos.REGISTROS !== null && anyos.REGISTROS.length > 0) {
            setAnios(anyos.REGISTROS);
            let maximo = 0;


            for (let i = 0, len = anyos.REGISTROS.length; i < len; i++) {
                if (maximo < anyos.REGISTROS[i]) {
                    maximo = anyos.REGISTROS[i];
                }
            }

            setAnioSeleccionado(maximo)

        } else {
            setAnios(null);
        }
    }


    const permisoCrearConsulta = () => {
        let PERMITIR = false;
        let ESTADO_USUARIO = usuario;
        const { PROHIBIR_CONSULTAS } = ESTADO_USUARIO !== undefined && ESTADO_USUARIO !== null ? ESTADO_USUARIO.USUARIO : { PROHIBIR_CONSULTAS: false };

        if (PROHIBIR_CONSULTAS) {
            setAbirPublicidad(true)
            PERMITIR = PROHIBIR_CONSULTAS
        }


        return PERMITIR;
    }


    const hay_feedback_pendientes = () => {
        if (!permisoCrearConsulta()) {

            hay_feedback_pendientesEnAPI().then((GRADO) => {
                if (GRADO !== undefined && GRADO !== null && GRADO.ID !== null && GRADO.ID > 0) {
                    setID_GRADO_SAST(GRADO.ID)

                    //FICHA TAREA
                    //setRegistroSeleccionado({ ID_TAREA: GRADO.ID })
                    setDatosGrado(GRADO)

                    setEsNuevaTarea(true)
                    setAbrirFichaTarea(true);


                    //FICHA FEEDBACK
                    setAbrirFeedback(true)
                } else {
                    //setID_GRADO_SAST(0)
                    setDatosGrado({})

                    setEsNuevaTarea(true)
                    setAbrirFichaTarea(true);
                }
            })
            
        }
    }










    const filtrarDatosLocales = (licencias, params, textoBuscador) => {
        if (licencias !== undefined && licencias !== null) {
            let filtroLicencias = [...licencias];

            if (params.ESTADO_TAREA > 0) {
                filtroLicencias = filtroLicencias.filter((item) => params.ESTADO_TAREA === 1 ? item.FK_ID_MAE_ESTADO_TAREA !== 8 : item.FK_ID_MAE_ESTADO_TAREA === 8)
            }

            if (params.GRUPO_MONTAJE > 0) {
                filtroLicencias = filtroLicencias.filter((item) => item.FK_ID_MAE_GRUPO_MONTAJE === params.GRUPO_MONTAJE)
            }

            if (textoBuscador !== "") {
                filtroLicencias = filtrarEnTabla(textoBuscador, filtroLicencias)
            }

            let verificarLicencia = filtroLicencias === undefined || filtroLicencias === null ? null : filtroLicencias.length === 0 ? null : filtroLicencias
            setLicencias(verificarLicencia)
        }
    }



    const filtrarEnTabla = (palabra, registros) => {
        let resultado = []
        if (palabra !== "") {
            if (registros !== null) {
                let resultadoFiltro = registros.filter((item) => {
                    return item.ID_TAREA.toString().toUpperCase().includes(palabra.toUpperCase()) ||
                        item.MATRICULA.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.FECHA_ALTA.toUpperCase().includes(palabra.toUpperCase()) ||
                        item.MATRICULA_MARCA.toUpperCase().includes(palabra.toUpperCase()) ||

                        item.TEXTO_CONSULTA.toUpperCase().includes(palabra.toUpperCase())

                })
                resultado = resultadoFiltro;
                /*
                                if (resultadoFiltro.length > 0) {
                                    resultado = resultadoFiltro;
                                } else {
                                    resultado = registros;
                                }
                */
            }
        } else {
            resultado = licenciasAux;
        }
        return resultado;
    }



    const licenciasSertec = (ORDERCOLUM, ORDERDIR, INCLUIR_BAJAS, INCLUIR_CERRADAS, ID_TALLER, ID_USUARIO_TALLER, FECHA_PARTIDA, ACCION_DESDE, ACCION_HASTA) => {
        setLicencias([]);
        setLicenciasAux([]);
        setDatosPaginacion({ iniPage: 1, inicio: 1, final: 40, numPagina: 40 })

        obtener_registros_paginadosEnAPI(100000, 1, ORDERCOLUM, ORDERDIR, INCLUIR_BAJAS, INCLUIR_CERRADAS, ID_TALLER, ID_USUARIO_TALLER, FECHA_PARTIDA, ACCION_DESDE, ACCION_HASTA).then((licencias) => {

            if (licencias !== undefined && licencias !== null && licencias.REGISTROS !== undefined && licencias.REGISTROS !== null && licencias.REGISTROS.length > 0) {
                setLicencias(licencias.REGISTROS);
                setLicenciasAux(licencias.REGISTROS);

                filtrarDatosLocales(licencias.REGISTROS, { ESTADO_TAREA: filtroSeleccionado.ESTADO_TAREA, GRUPO_MONTAJE: filtroSeleccionado.GRUPO_MONTAJE }, textoBuscador)
            } else {
                setLicencias(null)
                setLicenciasAux(null)
            }
        })
    }

    const actualizarCabeceraTabla = (e, valor) => {
        const ID_TAREA = e === 'ID_TAREA' ? valor : false;
        const USUARIO_TALLER_ALTA = e === 'USUARIO_TALLER_ALTA' ? valor : false;
        const MATRICULA = e === 'MATRICULA' ? valor : false;
        const FECHA_ALTA = e === 'FECHA_ALTA' ? valor : false
        const MATRICULA_MARCA = e === 'MATRICULA_MARCA' ? valor : false;
        const NOMBRE_GRUPO_MONTAJE = e === 'NOMBRE_GRUPO_MONTAJE' ? valor : false;
        const TEXTO_CONSULTA = e === 'TEXTO_CONSULTA' ? valor : false;
        const NOMBRE_ESTADO = e === 'NOMBRE_ESTADO' ? valor : false;


        setCabeceraTabla(
            {
                ID_TAREA: ID_TAREA,
                USUARIO_TALLER_ALTA: USUARIO_TALLER_ALTA,

                MATRICULA: MATRICULA,
                FECHA_ALTA: FECHA_ALTA,
                MATRICULA_MARCA: MATRICULA_MARCA,
                NOMBRE_GRUPO_MONTAJE: NOMBRE_GRUPO_MONTAJE,
                TEXTO_CONSULTA: TEXTO_CONSULTA,
                NOMBRE_ESTADO: NOMBRE_ESTADO
            }
        )
    }




    const responsivedDescripcionTarea = (anchuraActual) => {
        let anchura = 0;
        if (anchuraActual > 600 && anchuraActual <= 1200) {
            anchura = 40;
        } else if (anchuraActual > 1200 && anchuraActual < 1500) {
            anchura = 40;
        } else if (anchuraActual > 1500) {
            anchura = 100;
        } else if (anchuraActual > 500 && anchuraActual < 600) {
            anchura = 30;
        } else if (anchuraActual < 500) {
            anchura = 10;
        }

        return anchura;
    }


    const List = React.memo(({ list }) => {
        let datos = [];

        datos = list.map((licencia, i) => (
            (i + 1) >= datosPaginacion.inicio && (i + 1) <= datosPaginacion.final ?
                <ListItem key={licencia.ID} licencia={licencia} />
                :
                null
        ))
        return (
            datos
        );
    })




    const ListItem = React.memo(({ licencia }) => {
        return <tr key={licencia.ID} style={{ backgroundColor: '#fff' }}>
            <td style={{ border: '1px solid #d9d9d9', padding: '4px' }} title={licencia.ID_TAREA}>{licencia.ID_TAREA}</td>
            <td style={{ border: '1px solid #d9d9d9', padding: '4px' }} title={licencia.USUARIO_TALLER_ALTA}><span style={{ fontWeight: 500 }}> {recortarCadena(licencia.USUARIO_TALLER_ALTA, 30, "...")}</span></td>

            <td style={{ border: '1px solid #d9d9d9', padding: '4px' }} title={licencia.MATRICULA}>{recortarCadena(licencia.MATRICULA, 20, "...")}</td>
            <td style={{ border: '1px solid #d9d9d9', padding: '4px' }} title={licencia.FECHA_ALTA}><span style={{ fontWeight: 500 }}> {recortarCadena(licencia.FECHA_ALTA, 30, "...")}</span></td>


            <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} title={`${licencia.MATRICULA_MARCA} ${licencia.MATRICULA_MODELO} ${licencia.MATRICULA_MOTOR}`}>{recortarCadena(`${licencia.MATRICULA_MARCA} ${licencia.MATRICULA_MODELO} ${licencia.MATRICULA_MOTOR}`, 35, "...")}</td>

            <td style={{ border: '1px solid #d9d9d9', padding: '4px' }} title={licencia.TEXTO_CONSULTA}>{recortarCadena(licencia.TEXTO_CONSULTA, responsivedDescripcionTarea(configApp.width), "...")}</td>

            <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} title={licencia.FK_ID_MAE_ESTADO_TAREA === 8 ? traducirPagina("Finalizado") : traducirPagina("Enviado")}>{licencia.FK_ID_MAE_ESTADO_TAREA === 8 ? traducirPagina("Finalizado") : traducirPagina("Enviado")}</td>


            <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} title={licencia.FK_ID_MAE_ESTADO_TAREA === 8 ? "FINALIZADO" : "PENDIENTE"}>{licencia.FK_ID_MAE_ESTADO_TAREA === 8 ? <CheckSquareOutlined style={{ fontSize: '18px', color: 'green' }} /> : <CloseSquareOutlined style={{ fontSize: '18px', color: 'red' }} />}</td>




            <td style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>
                <div className="flex-container-Grow-Simple">

                    <div style={{ margin: '0px 5px' }}>
                        <button title={traducirPagina("Ver_consulta")} onClick={() => {
                            setAbrirFichaTarea(true)
                            setEsNuevaTarea(false)

                            setRegistroSeleccionado(licencia)

                            /*
                            if (!licencia.HAY_FEEDBACK_CLIENTE) {
                                setDatosGrado({ ID: licencia.ID_TAREA, HTML_TAREA: "" })

                                //FICHA FEEDBACK
                                setAbrirFeedback(true)
                            }
                            */

                        }}
                            className="btn-matricula  estiloBotonRemove paddindDeTres NuevoPadding">
                            <EyeOutlined style={{ cursor: 'pointer', fontSize: '20px', color: '#000' }} />
                        </button>

                    </div>

                    <div style={{ margin: '0px 5px' }}>
                        <button title={traducirPagina("Nueva")} style={licencia.FK_ID_MAE_ESTADO_TAREA === 8 ? { display: 'flex' } : { pointerEvents: 'none', opacity: '0.4', display: 'flex' }} onClick={() => {

                            if (!permisoCrearConsulta()) {

                                setAbrirFichaTarea(true)
                                setEsNuevaTarea(true)
                                setRegistroSeleccionado(licencia)


                                if (!licencia.HAY_FEEDBACK_CLIENTE) {
                                    setDatosGrado({ ID: licencia.ID_TAREA, HTML_TAREA: "" })
                                    //FICHA FEEDBACK
                                    setAbrirFeedback(true)
                                }
                            }

                        }}
                            className="btn-matricula  estiloBotonRemove paddindDeTres NuevoPadding">
                            <QuestionCircleOutlined style={{ cursor: 'pointer', fontSize: '20px', color: '#000', marginRight: '10px' }} /> <span>{` ${traducirPagina("Nueva")}`}</span>
                        </button>

                    </div>

                </div>
            </td>
        </tr>
    });


    const paginarTabla = (page, pageSize) => {
        if (licencias.length > 0) {
            let pInicio = (pageSize * page) - (datosPaginacion.numPagina);
            let pFinal = pageSize * page;
            setDatosPaginacion(
                {
                    iniPage: page,
                    inicio: pInicio,
                    final: pFinal,
                    numPagina: datosPaginacion.numPagina
                }
            )
        }
    }

    const updateNumberPage = (current, size) => {
        setDatosPaginacion(
            {
                iniPage: 1,
                inicio: 1,
                final: size,
                numPagina: size
            }
        )
    }

    const ObtenerTextoMes = (CODIGO_MES) => {
        let TEXTO_MES = mesesDelAnio[0];
        if (CODIGO_MES !== undefined && CODIGO_MES !== null && CODIGO_MES !== "") {
            for (let i = 0; i < mesesDelAnio.length; i++) {

                let MES_PARAMETRO = ((parseInt(CODIGO_MES)) - 1);
                console.log("CODIGO", i, " TEXTO", mesesDelAnio[i], "PARAM", CODIGO_MES, "CORRECCIO", MES_PARAMETRO)


                if (i === MES_PARAMETRO) {
                    TEXTO_MES = mesesDelAnio[i];
                    break;
                }

            }
        }
        return TEXTO_MES.toUpperCase()
    }



    if (!hayLicencias && obtenerToken() !== undefined) {
        seHayLicencias(true)

        let fechaLocal = obtenerFechaLocalObjeto(false);

        buscarAnyosAPI();
        setMesSeleccionado(fechaLocal.MES)


        const FECHA_INICIAL = construirFecha(fechaLocal.MES, fechaLocal.ANIO.toString(), { DESDE: 2, HASTA: 3 })
        filtroFecha.FECHA_PARTIDA = FECHA_INICIAL


        setFiltroFecha({ ...filtroFecha })

        licenciasSertec(3, "D", false, filtroSeleccionado.TAREAS_CERRADAS, obtenerLicenciaLocal().ID_TALLER, null, FECHA_INICIAL, 2, 3);

        //MATRICULA AUTOMATICA
        if (Object.keys(QUERY_USER).length > 0) {
            setAbrirFichaTarea(true)
            setEsNuevaTarea(true)
        }

    }

    const filtroConsultasCerradas = (
        <Checkbox name="TAREAS_CERRADAS" checked={filtroSeleccionado.TAREAS_CERRADAS} onChange={(e) => {
            filtroSeleccionado.TAREAS_CERRADAS = e.target.checked;
            setFiltroSeleccionado({ ...filtroSeleccionado })
            licenciasSertec(3, "D", false, e.target.checked, obtenerLicenciaLocal().ID_TALLER, null, filtroFecha.FECHA_PARTIDA, filtroFecha.ACCION_DESDE, filtroFecha.ACCION_HASTA);

        }} ><span style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>{traducirPagina("Consulta_cerradas")}</span> </Checkbox>
    );

    const filtroConsultasEstados = (
        <Select

            allowClear={true}
            style={{ width: "100%", textAlign: 'left' }}
            value={filtroSeleccionado.ESTADO_TAREA === 0 ? traducirPagina("Filtrar_por_estado") : filtroSeleccionado.ESTADO_TAREA}
            optionFilterProp="children"
            size="large"
            onChange={(e) => {
                if (e === undefined) {
                    filtroSeleccionado.ESTADO_TAREA = 0;
                    setFiltroSeleccionado({ ...filtroSeleccionado })
                } else {
                    filtroSeleccionado.ESTADO_TAREA = e;
                    setFiltroSeleccionado({ ...filtroSeleccionado })
                }
                filtrarDatosLocales(licenciasAux, { ESTADO_TAREA: e === undefined ? 0 : e, GRUPO_MONTAJE: filtroSeleccionado.GRUPO_MONTAJE }, textoBuscador)

            }}
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            <Option value={-1}>{traducirPagina("Todos").toUpperCase()}</Option>
            <Option value={1}>{traducirPagina("Pendiente").toUpperCase()}</Option>
            <Option value={2}>{traducirPagina("Finalizado").toUpperCase()}</Option>
        </Select>
    );



    const filtroConsultasFecha = (
        <>
            <div className={filtroFecha.CAMBIO_DIA ? 'fondoPorDefecto' : 'fondosecundarioPorDefecto'} style={{ padding: '10px 20px', display: 'inline', fontSize: '16px', fontWeight: '500', margin: '0px 10px', color: '#fff' }}
                onClick={(e) => {
                    let anioActual = obtenerFechaLocalObjeto(false).ANIO;
                    const FECHA_PARTIDA = construirFecha(obtenerFechaLocalObjeto(false).MES, anioActual, { DESDE: 4, HASTA: 5 });
                    //setAnioSeleccionado(anioActual);

                    filtroFecha.CAMBIO_DIA = true;
                    filtroFecha.CAMBIO_MES = false;
                    filtroFecha.CAMBIO_ANIO = false;
                    filtroFecha.CAMBIO_TODAS = false;

                    filtroFecha.ACCION_DESDE = 4;
                    filtroFecha.ACCION_HASTA = 5;
                    filtroFecha.FECHA_PARTIDA = FECHA_PARTIDA;

                    setFiltroFecha({ ...filtroFecha })

                    licenciasSertec(3, "D", false, filtroSeleccionado.TAREAS_CERRADAS, obtenerLicenciaLocal().ID_TALLER, null, FECHA_PARTIDA, 4, 5);

                }}>
                {traducirPagina("Hoy").toUpperCase()}
            </div>


            <MDBDropdown>
                <MDBDropdownToggle nav>
                    <div className={filtroFecha.CAMBIO_MES ? 'fondoPorDefecto' : 'fondosecundarioPorDefecto'} style={{ padding: '8px 20px', fontSize: '16px', fontWeight: '500', marginRight: '10px', color: '#fff' }}>
                        {ObtenerTextoMes(mesSeleccionado)}
                    </div>
                </MDBDropdownToggle>

                <MDBDropdownMenu right >
                    {mesesDelAnio.map((mes, i) =>
                        <MDBDropdownItem style={{ padding: '10px' }} key={i} onClick={(e) => {
                            const FECHA_PARTIDA = construirFecha((i + 1), anioSeleccionado, { DESDE: 0, HASTA: 1 });

                            setMesSeleccionado((i + 1));

                            filtroFecha.CAMBIO_DIA = false;
                            filtroFecha.CAMBIO_MES = true;
                            filtroFecha.CAMBIO_ANIO = false;
                            filtroFecha.CAMBIO_TODAS = false;

                            filtroFecha.ACCION_DESDE = 0;
                            filtroFecha.ACCION_HASTA = 1;
                            filtroFecha.FECHA_PARTIDA = FECHA_PARTIDA
                            setFiltroFecha({ ...filtroFecha })

                            licenciasSertec(3, "D", false, filtroSeleccionado.TAREAS_CERRADAS, obtenerLicenciaLocal().ID_TALLER, null, FECHA_PARTIDA, 0, 1);


                        }}>  {mes.toUpperCase()}</MDBDropdownItem>
                    )}
                </MDBDropdownMenu>
            </MDBDropdown>

            <MDBDropdown>
                <MDBDropdownToggle nav>
                    <div className={filtroFecha.CAMBIO_ANIO ? 'fondoPorDefecto' : 'fondosecundarioPorDefecto'} style={{ padding: '8px 20px', fontSize: '16px', fontWeight: '500', color: '#fff' }}>
                        {anioSeleccionado}
                    </div>
                </MDBDropdownToggle>

                <MDBDropdownMenu right >
                    {anios !== null ?
                        anios.map((anio, i) =>
                            <MDBDropdownItem key={i} onClick={(e) => {
                                const FECHA_PARTIDA = construirFecha(mesSeleccionado, e.target.innerText, { DESDE: 2, HASTA: 3 });

                                setAnioSeleccionado(e.target.innerText);

                                filtroFecha.CAMBIO_DIA = false;
                                filtroFecha.CAMBIO_MES = false;
                                filtroFecha.CAMBIO_ANIO = true;
                                filtroFecha.CAMBIO_TODAS = false;

                                filtroFecha.ACCION_DESDE = 2;
                                filtroFecha.ACCION_HASTA = 3;
                                filtroFecha.FECHA_PARTIDA = FECHA_PARTIDA;
                                setFiltroFecha({ ...filtroFecha })

                                licenciasSertec(3, "D", false, filtroSeleccionado.TAREAS_CERRADAS, obtenerLicenciaLocal().ID_TALLER, null, FECHA_PARTIDA, 2, 3);

                            }}>  {anio}</MDBDropdownItem>
                        )
                        :
                        null
                    }
                </MDBDropdownMenu>
            </MDBDropdown>


            <div className={filtroFecha.CAMBIO_TODAS ? 'fondoPorDefecto' : 'fondosecundarioPorDefecto'} style={{ padding: '10px 20px', display: 'inline', fontSize: '16px', fontWeight: '500', margin: '0px 10px', color: '#fff', cursor: 'pointer' }}
                onClick={(e) => {
                    let anioActual = obtenerFechaLocalObjeto(false).ANIO;
                    //const FECHA_PARTIDA = construirFecha(obtenerFechaLocalObjeto(false).MES, anioActual, { DESDE: 2, HASTA: 3 });

                    filtroFecha.CAMBIO_DIA = false;
                    filtroFecha.CAMBIO_MES = false;
                    filtroFecha.CAMBIO_ANIO = false;

                    filtroFecha.CAMBIO_TODAS = true;



                    filtroFecha.ACCION_DESDE = 0;
                    filtroFecha.ACCION_HASTA = 0;
                    filtroFecha.FECHA_PARTIDA = "";

                    setFiltroFecha({ ...filtroFecha })

                     licenciasSertec(3, "D", false, filtroSeleccionado.TAREAS_CERRADAS, obtenerLicenciaLocal().ID_TALLER, null, filtroFecha.FECHA_PARTIDA,  filtroFecha.ACCION_DESDE,  filtroFecha.ACCION_HASTA);

                }}>
                {traducirPagina("Todos").toUpperCase()}
            </div>


        </>
    );


    const vistaConsultaWeb = (
        <div style={{ height: (configApp.height - 190).toString() + "px", overflow: 'auto', margin: '0px 10px' }}>
            <table style={{ border: '1px solid #d9d9d9', width: '100%' }}>
                <thead>
                    <tr style={{ backgroundColor: '#e0e0e0' }}>
                        <th style={{ width: '100px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => {
                            actualizarCabeceraTabla("ID_TAREA", !cabeceraTabla.ID_TAREA);

                            setLicencias(ordenarTablaLocal(cabeceraTabla.ID_TAREA ? "ASC" : "DESC", licencias, "ID_TAREA"))
                        }}> {"ID"} {cabeceraTabla.ID ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>

                        <th style={{ width: '170px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => {
                            actualizarCabeceraTabla("USUARIO_TALLER_ALTA", !cabeceraTabla.USUARIO_TALLER_ALTA);

                            setLicencias(ordenarTablaLocal(cabeceraTabla.USUARIO_TALLER_ALTA ? "ASC" : "DESC", licencias, "FECHA_ALTA"))
                        }} > {traducirPagina("Nombre_usuario")} {cabeceraTabla.USUARIO_TALLER_ALTA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>



                        <th style={{ width: '190px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => {
                            actualizarCabeceraTabla("MATRICULA", !cabeceraTabla.MATRICULA);

                            setLicencias(ordenarTablaLocal(cabeceraTabla.MATRICULA ? "ASC" : "DESC", licencias, "MATRICULA"))
                        }}> {traducirPagina("Matricula")} {cabeceraTabla.MATRICULA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />} </th>

                        <th style={{ width: '170px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => {
                            actualizarCabeceraTabla("FECHA_ALTA", !cabeceraTabla.FECHA_ALTA);

                            setLicencias(ordenarTablaLocal(cabeceraTabla.FECHA_ALTA ? "ASC" : "DESC", licencias, "FECHA_ALTA"))
                        }} > {traducirPagina("Fecha")} {cabeceraTabla.FECHA_ALTA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>





                        <th style={{ width: '330px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} onClick={(e) => {
                            actualizarCabeceraTabla("MATRICULA_MARCA", !cabeceraTabla.MATRICULA_MARCA);
                            setLicencias(ordenarTablaLocal(cabeceraTabla.MATRICULA_MARCA ? "ASC" : "DESC", licencias, "MATRICULA_MARCA"))
                        }} > {traducirPagina("Vehiculo")} {cabeceraTabla.MATRICULA_MARCA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>



                        <th style={{ border: '1px solid #d9d9d9', padding: '4px', textAlign: 'left' }} onClick={(e) => {
                            actualizarCabeceraTabla("TEXTO_CONSULTA", !cabeceraTabla.TEXTO_CONSULTA);

                            setLicencias(ordenarTablaLocal(cabeceraTabla.TEXTO_CONSULTA ? "ASC" : "DESC", licencias, "TEXTO_CONSULTA"))
                        }} > {traducirPagina("Consulta")} {cabeceraTabla.TEXTO_CONSULTA ? <ArrowUpOutlined className="orderTable" /> : <ArrowDownOutlined className="orderTable" />}</th>


                        <th style={{ width: '80px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }}>  </th>


                        <th style={{ width: '80px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} > {traducirPagina("Finalizado")} </th>


                        <th style={{ width: '50px', border: '1px solid #d9d9d9', padding: '4px', textAlign: 'center' }} > </th>

                    </tr>
                </thead>
                <tbody>
                    <List list={licencias} />
                </tbody>

            </table>
        </div>
    );

    return (
        <>
            <MasterPage
                ABRIR_FICHA={filtrosMovil}
                CERRAR_FICHA={setFiltrosMovil}
            />
            <div>
                <div className="centerMenu2">
                    <>
                        <div style={{ textAlign: 'left', fontSize: '18px', marginRight: '10px', lineHeight: '40px', display: configApp.width <= anchoPorDefecto ? 'none' : 'inline' }}>{traducirPagina("Consultas").toUpperCase()} </div>

                        <div className="grid-container  mdb-skin" style={{ backgroundColor: '#eee', marginRight: '10px' }}>
                            <div className="grid-item">
                                <input className="form-control" type="text" aria-label="Search" placeholder={traducirPagina("Buscar_consulta")} style={{ backgroundColor: "transparent", width: '200px', border: '0px', height: '100%', color: '#000' }} name="txtInputCliente" autoComplete="off" onChange={(e) => {
                                    filtrarDatosLocales(licenciasAux, { ESTADO_TAREA: filtroSeleccionado.ESTADO_TAREA, GRUPO_MONTAJE: filtroSeleccionado.GRUPO_MONTAJE }, e.target.value)
                                    setTextoBuscador(e.target.value)
                                }} value={textoBuscador} />
                            </div>

                            <div className="grid-item" >
                                <button style={{ border: '0', padding: '4px 5px 5px 7px', margin: '0', boxShadown: 'none', visibility: textoBuscador.trim() === "" ? 'hidden' : 'visible' }} className="btn-matriculav2" onClick={(e) => { setTextoBuscador(""); filtrarDatosLocales(licenciasAux, { ESTADO_TAREA: filtroSeleccionado.ESTADO_TAREA, GRUPO_MONTAJE: filtroSeleccionado.GRUPO_MONTAJE }, "") }}>  <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                            </div>

                            <div className="grid-item barraLateral" ></div>

                            <div className="grid-item">
                                <button style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </button>
                            </div>
                        </div>



                        <div style={{ display: 'inline', float: 'right' }}>
                            <button className="colorNuevaConsulta" style={{ height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', backgroundColor: '#efefef', cursor: 'pointer', padding: '0px 10px' }} onClick={() => {
                                hay_feedback_pendientes();

                            }}><PlusOutlined style={{ fontSize: '18px' }} /> {configApp.width <= anchoPorDefecto ? null : <span style={{ fontSize: '14px', fontWeight: '500', verticalAlign: 'sub' }}>{traducirPagina("Nueva_consulta").toUpperCase()} </span>} </button>
                        </div>

                        <div style={{ display: configApp.width <= anchoPorDefecto ? 'none' : 'inline', marginRight: '10px', float: 'right' }}>
                            {filtroConsultasFecha}
                        </div>

                        <div style={{ display: configApp.width <= anchoPorDefecto ? 'none' : 'inline', float: 'right' }}>
                            {filtroConsultasEstados}
                        </div>

                        <div style={{ float: 'right', display: configApp.width <= anchoPorDefecto ? 'none' : 'inline', lineHeight: '40px' }}>
                            {filtroConsultasCerradas}
                        </div>

                    </>
                </div>

                <div style={{ margin: '10px' }}>
                    {licencias !== null ?
                        licencias.length > 0 ?
                            <div style={{ fontSize: '20px', fontWeight: '500', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                <div style={{ width: '100%', textAlign: 'right' }}>

                                    <Pagination
                                        showSizeChanger
                                        current={datosPaginacion.iniPage}
                                        onShowSizeChange={(current, size) => { updateNumberPage(current, size) }}
                                        total={licencias.length}
                                        showTotal={() => ` ${licencias.length} ${traducirPagina("Consultas")}`}
                                        defaultPageSize={40}
                                        onChange={(page, pageSize) => { paginarTabla(page, pageSize) }}
                                    > </Pagination>

                                </div>
                            </div>

                            :
                            null
                        : null
                    }
                    {licencias === null ?
                        <div style={{ textAlign: 'center' }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: 'calc(100vh - 130px)' }}>   <h4 style={{ margin: '0px' }}>{traducirPagina("Msg_sin_consulta")}</h4> </div>

                        </div>
                        :
                        licencias.length > 0 ?

                            (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ?

                                <div className="containerConsultas">

                                    {
                                        licencias.map((licencia, i) => (
                                            (i + 1) >= datosPaginacion.inicio && (i + 1) <= datosPaginacion.final ?

                                                <div className="containerConsultaCard" key={i} onClick={() => {
                                                    setAbrirFichaTarea(true)
                                                    setEsNuevaTarea(false)
                                                    setRegistroSeleccionado(licencia)
                                                }}>
                                                    <div className="containerConsultaCard_item1">
                                                        {licencia.FK_ID_MAE_ESTADO_TAREA === 8 ?
                                                            <img src="./imagenes/wrench.png" alt="estado"></img>

                                                            :
                                                            <img src="./imagenes/pending.png" alt="estado"></img>

                                                        }
                                                    </div>

                                                    <div className="containerConsultaCard_item2">
                                                        <div className="consulta_titulo">Consulta número  <span>{licencia.ID_TAREA} </span></div>
                                                        <div className="consulta_subtitulo">{licencia.FECHA_ALTA}</div>
                                                        <div className="separadorContenido"></div>


                                                        <div className="consulta_matricula">{licencia.MATRICULA}</div>
                                                        <div className="consulta_vehiculo">{`${licencia.MATRICULA_MARCA} ${licencia.MATRICULA_MODELO} ${licencia.MATRICULA_MOTOR}`}</div>
                                                    </div>
                                                </div>

                                                :
                                                null
                                        ))
                                    }
                                </div>

                                :

                                vistaConsultaWeb

                            :
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: 'calc(100vh - 130px)' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                    }
                </div>

                {
                    abrirFichaTarea ?
                        <FichaTarea
                            title={traducirPagina("Ficha_consulta").toUpperCase()}
                            ABRIR_FICHA={abrirFichaTarea}
                            CERRAR_FICHA={setAbrirFichaTarea}
                            REGISTRO_SELECCIONADO={registroSeleccionado}
                            ES_NUEVA_TAREA={esNuevaTarea}
                            ACTUALIZAR_TABLA={licenciasSertec}
                            FILTRO_FECHA={filtroFecha}
                            FILTROS={filtroSeleccionado}
                            MODIFICAR_REGISTRO_CONSULTA={setRegistroSeleccionado}
                        >
                        </FichaTarea>
                        :
                        null
                }
                {
                    abrirFeedback ?

                        <FichaFeedback
                            title={traducirPagina("Opinion_importante")}
                            ABRIR_FICHA={abrirFeedback}
                            CERRAR_FICHA={setAbrirFeedback}
                            DATOS_GRADO={datosGrado}

                            FILTRO_FECHA={filtroFecha}
                            FILTROS={filtroSeleccionado}
                            ACTUALIZAR_TABLA={licenciasSertec}
                        >

                        </FichaFeedback>
                        :
                        null
                }


                {abirPublicidad ?

                    <FichaPublicidadAssist
                        title={"NEXT ASSIST"}
                        ABRIR_FICHA={abirPublicidad}
                        CERRAR_FICHA={setAbirPublicidad}
                    >

                    </FichaPublicidadAssist>
                    :
                    null
                }

                {filtrosMovil ?
                    <Modal
                        visible={filtrosMovil}
                        style={{ zIndex: "999" }}
                        width={configApp.width <= 900 ? '95%' : 800}
                        centered
                        title={traducirPagina("Filtrar_consultas").toUpperCase()}
                        onOk={() => { setFiltrosMovil(true) }}
                        onCancel={() => { setFiltrosMovil(false) }}
                        footer={[
                            <Button key="submit" onClick={() => { setFiltrosMovil(false) }}>{traducirPagina("Salir")} </Button>,



                            <Button key="submit" type="primary" onClick={() => {
                                setFiltrosMovil(false)
                            }}> {traducirPagina("Filtrar")}</Button>
                        ]}>


                        <Row style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>
                            <Col md={24} className="mb-4">
                                {filtroConsultasFecha}
                            </Col>

                            <Col md={24} className="mb-4">
                                {filtroConsultasEstados}
                            </Col>

                            <Col md={24} className="mb-4">
                                {filtroConsultasCerradas}
                            </Col>
                        </Row>
                    </Modal>
                    :
                    null
                }
            </div>
        </>
    );
}

ContainerTareas.propTypes = {
    DATOS_SESION: PropTypes.object,
    ABRIR_FILTRO: PropTypes.bool

};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        usuario: state.usuario,
        QUERY_USER: state.QUERY_USER
    };
}

export default connect(mapStateToProps)(ContainerTareas);




