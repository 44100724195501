
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import React from 'react';
import store from "../store";

import { obtener_configuracion_EnAPI, obtener_fabricantesEnAPI, obtener_modelosEnAPI, obtener_motorizacionesEnAPI, obtener_tipo_tareaEnAPI, obtener_grupo_montajeEnAPI, buscar_matriculaEnAPI, buscar_bastidorEnAPI, nueva_tareaEnAPI, obtener_registrosEnAPI, insertar_ficheroEnAPI, obtener_fichero_tecnico_EnAPI, obtener_fichero_taller_EnAPI, obtener_paises_EnAPI, obtener_imagen_vehiculoEnAPI, obtener_registros_taller_EnAPI, obtener_tarea_EnAPI, END_POINT_ACTUAL, obtener_conectorEnAPI } from "../action/actionCreatorsRequests";
import { MSGERROR, MSGOK, traducirPagina, verificarExtension, obtenerLicenciaLocal, generateUUID, descargarDocPasandoBase64Generico, convertirTextoBastidor, verificarMatricula } from "../librerias/funciones"
import { MDBCard, MDBCardBody } from 'mdbreact';

import {
    SaveOutlined,
    DeleteOutlined,
    PlusOutlined,
    CloudDownloadOutlined,
    EyeOutlined,
    SearchOutlined,
    CloseOutlined,
    FileImageOutlined
} from '@ant-design/icons';
import { Select, Drawer, Modal, Button, Input, Row, Col, Tabs, Popover } from 'antd';

import FichaSeleccionVehiculo from './FichaSeleccionVehiculo';

import VisorGenerico from './VisorGenerico';
import FichaVehiculoTecdoc from './FichaVehiculoTecdoc';
import FichaDetalleVehiculo from './FichaDetalleVehiculo';
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;


const FichaTarea = ({ props, configApp, QUERY_USER }) => {
    const [visible, setVisible] = React.useState(false);

    const [conector, setConector] = React.useState([]);

    const [seModificaBastidor, setSeModificaBastidor] = React.useState(false);

    const [hayCliente, setHayCliente] = React.useState(false);
    const [tabPorDefecto, setTabPorDefecto] = React.useState("1");
    const [marcas, setMarcas] = React.useState([]);
    const [modelos, setModelos] = React.useState([]);
    const [motores, setMotores] = React.useState([]);
    const [paises, setPaises] = React.useState([]);
    const [configuracion, setConfiguracion] = React.useState([]);
    const [imagenVehiculo, setImagenVehiculo] = React.useState("");
    const [tipoTarea, setTipoTarea] = React.useState([]);
    const [grupoMontaje, setGrupoMontaje] = React.useState([]);

    const [cocheDGT, setCocheDGT] = React.useState({});
    const [abrirSeleccionVehiculo, setAbrirSeleccionVehiculo] = React.useState(false);
    const [creandoTarea, setCreandoTarea] = React.useState(false);

    const [abrirModalInfoVeh, setAbrirModalInfoVeh] = React.useState(false);
    const [cocheSeleccionado, setCocheSeleccionado] = React.useState({
        MARCA: 0,
        MODELO: 0,
        MOTOR: 0,
        TIPO_TAREA: 0,
        GRUPO_MONTAJE: 0,
        CODIGO_MOTORES: []
    });
    const [ficherosTaller, setFicherosTaller] = React.useState([]);
    const [ficherosTecnico, setFicherosTecnico] = React.useState([]);

    const [abrirVisor, setAbrirVisor] = React.useState(false);

    const [abrirFichaTecdoc, setAbrirFichaTecdoc] = React.useState(false);
    const [abrirFichaDetalleVeh, setAbrirFichaDetalleVeh] = React.useState(false);


    const [ficheroSeleccionado, setFicheroSeleccionado] = React.useState({});
    const [archivosLocal, setArchivosLocal] = React.useState([]);
    const [loaderTarea, setLoaderTarea] = React.useState({
        LOADER_MATRICULA: false,
        LOADER_BASTIDOR: false
    });



    const obtenerConfig = (registros, NOMBRE) => {
        const config = {
            DESCRIPCION: "",
            ID_APP_CONFIGURACION: 0,
            NOMBRE_PARAMETRO: "",
            VALOR: ""
        }

        const data = registros.find((registros) => registros.NOMBRE_PARAMETRO === NOMBRE);
        if (data !== undefined) {
            const { DESCRIPCION, ID_APP_CONFIGURACION, NOMBRE_PARAMETRO, VALOR } = data;
            config.DESCRIPCION = DESCRIPCION;
            config.ID_APP_CONFIGURACION = ID_APP_CONFIGURACION;
            config.NOMBRE_PARAMETRO = NOMBRE_PARAMETRO;
            config.VALOR = VALOR
        }

        return config;
    }

    const gestionarFicheroLocal = (input) => {
        const url = input.value;
        const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
        //const { ES_EXTENSION_PERMITIDA } = verificarExtension(ext);
        const { VALOR, DESCRIPCION, TIPOS_ARCHIVOS } = obtenerConfig(configuracion, "TAMANYO_MAXIMO_ARCHIVO")

        //obtenerConfig(configuracion, "TIPOS_ARCHIVOS").VALOR
        let EXTENSIONES_PERMITIDAS = obtenerConfig(configuracion, "TIPOS_ARCHIVOS").VALOR
        let extension = EXTENSIONES_PERMITIDAS.replaceAll(".", "");
        let Lista_ext = extension.split(",");
        let existe_ext = Lista_ext.filter((registro) => registro.trim() === ext);


        if (input.files && input.files[0] && existe_ext.length > 0) {
            if (input.files[0].size <= parseInt(VALOR)) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const registro =
                    {
                        ID: generateUUID(),
                        NOMBRE: input.files[0].name,
                        STATUS: 'pending',
                        EXTENSION: ext,
                        DATOS: e.target.result,
                        TYPE: input.files[0].type,
                        PROPIEDADES_FICHERO: verificarExtension(ext)
                    }

                    setArchivosLocal([...archivosLocal, registro])
                    input.value = '';
                }
                reader.readAsDataURL(input.files[0]);
            } else {
                MSGERROR(DESCRIPCION, "botton-right")
            }
        } else {
            MSGERROR(traducirPagina("Archivo_no_permitido"), "botton-right")
            input.value = '';
        }
    }


    const eliminarFichero = (fichero) => {
        let registros = archivosLocal.filter((registro) => registro.ID !== fichero.ID);

        setArchivosLocal([...registros])
    }


    const [payLoadLocal, setPayLoadLocal] = React.useState(
        {
            ID_MAE_PAIS_TAREA: 1,//id desplegable paises
            MATRICULA_PAIS: "E",// desplegable paises

            ID_VEHICULO_TECDOC: 0,
            MATRICULA_MARCA_ID: 0,
            MATRICULA_MODELO_ID: 0,
            MATRICULA_MOTOR_ID: 0,
            BASTIDOR: "",
            MATRICULA: "",
            MATRICULA_MARCA: "",
            MATRICULA_MODELO: "",
            MATRICULA_MOTOR: "",
            MATRICULA_CODIGO_MOTOR: "",

            ID_MAE_PLATAFORMA: "3",
            ID_MAE_GRUPO_MONTAJE: "",//desplegable
            ID_MAE_PRIORIDAD_TAREA: "3",
            ID_MAE_TIPO_TAREA: "",//Poner desplegable

            TEXTO: "",
            CODIGO_AVERIA: "",

            TEXTO_RESPUESTA: "",
            ID_MAE_GRADO_SATISFACCION: "",
            RESOLUCION_DE_TAREAS: "",
            ID_MAE_ESTADO_TAREA: "1",//Estado si asignar

            ID_USUARIO_ASIGNADO: "",
            ID_USUARIO_EN_CURSO: "",
            ID_USUARIO_RESPUESTA: "",
            NOMBRE_USUARIO_RESPUESTA: ""

        }
    )

    const selectBefore = (
        <Select
            style={{ width: '100px' }}
            defaultValue={1}
            value={payLoadLocal.ID_MAE_PAIS_TAREA}

            className="select-before">
            {
                paises !== undefined && paises !== null && paises.length > 0 ?
                    paises.map(pais =>
                        <Option key={pais.ID} value={pais.ID} onClick={() => {
                            payLoadLocal.ID_MAE_PAIS_TAREA = pais.ID;
                            payLoadLocal.MATRICULA_PAIS = pais.DESCRIPCION;
                            setPayLoadLocal({ ...payLoadLocal })

                        }} > {pais.NOMBRE.toUpperCase()}
                        </Option>
                    )
                    :
                    null
            }
        </Select>
    );



    const buscarImagenVehiculoEnAPI = async (ID_VEHICULO) => {
        setImagenVehiculo("");

        const vehiculo = await obtener_imagen_vehiculoEnAPI(ID_VEHICULO);
        if (vehiculo !== undefined && vehiculo !== null && vehiculo.IMAGEN !== null) {
            setImagenVehiculo(vehiculo.IMAGEN);
        } else {
            setImagenVehiculo(null);
        }
    }


    const obtener_conector = async (ID_VEHICULO, CODIGO_MOTOR) => {

        const conector = await obtener_conectorEnAPI(ID_VEHICULO, CODIGO_MOTOR);

        if (conector !== undefined && conector !== null && conector.REGISTROS !== null && conector.REGISTROS.length > 0) {
            setConector(conector.REGISTROS);
        } else {
            setConector(null);
        }
    }




    const obtenerCodigoMotores = (MOTORES) => {
        let RESULT_MOTORES = [];
        if (MOTORES !== undefined && MOTORES !== null) {
            MOTORES = MOTORES === "" ? "." : MOTORES;
            RESULT_MOTORES = MOTORES.split("|");
            RESULT_MOTORES = RESULT_MOTORES.filter((v, i, a) => a.indexOf(v) === i);
        }
        return RESULT_MOTORES
    }

    const cocheSeleccionadoLocal = async (vehiculo) => {
        buscarModelosAPI(vehiculo.ID_MARCA);
        buscarMotorizacionAPI(vehiculo.ID_MODELO);


        //payLoadLocal.MATRICULA_CODIGO_MOTOR = vehiculo.MOTORES;
        let LISTA_MOTORES = obtenerCodigoMotores(vehiculo.MOTORES);
        cocheSeleccionado.CODIGO_MOTORES = LISTA_MOTORES
        if (LISTA_MOTORES.length === 1) {
            payLoadLocal.MATRICULA_CODIGO_MOTOR = LISTA_MOTORES[0];

            obtener_conector(parseInt(vehiculo.ID), LISTA_MOTORES[0])
        }

        payLoadLocal.ID_VEHICULO_TECDOC = parseInt(vehiculo.ID);


        payLoadLocal.MATRICULA_MARCA_ID = vehiculo.ID_MARCA;
        payLoadLocal.MATRICULA_MODELO_ID = vehiculo.ID_MODELO;
        payLoadLocal.MATRICULA_MOTOR_ID = parseInt(vehiculo.ID);

        payLoadLocal.MATRICULA_MARCA = vehiculo.MARCA
        payLoadLocal.MATRICULA_MODELO = vehiculo.MODELO;
        payLoadLocal.MATRICULA_MOTOR = vehiculo.MOTORIZACION;




        setCocheSeleccionado({ ...cocheSeleccionado })
        setPayLoadLocal({ ...payLoadLocal })
        buscarImagenVehiculoEnAPI(parseInt(vehiculo.ID))


        setAbrirSeleccionVehiculo(false);
    }



    const ObtenerTiposTareas = () => {
        obtener_tipo_tareaEnAPI().then((tipos) => {
            if (tipos !== undefined && tipos !== null && tipos.REGISTROS !== null && tipos.REGISTROS.length > 0) {
                setTipoTarea(tipos.REGISTROS);
            } else {
                setTipoTarea(null);
            }
        })
    }

    const ObtenerGrupoMontajes = () => {
        obtener_grupo_montajeEnAPI().then((registros) => {
            if (registros !== undefined && registros !== null && registros.REGISTROS !== null && registros.REGISTROS.length > 0) {
                setGrupoMontaje(registros.REGISTROS);
            } else {
                setGrupoMontaje(null);
            }
        })
    }


    const buscarFabricanteAPI = (fabricante) => {
        obtener_fabricantesEnAPI(fabricante).then((fabricantes) => {
            if (fabricantes !== undefined && fabricantes !== null && fabricantes.REGISTROS !== null && fabricantes.REGISTROS.length > 0) {
                setMarcas(fabricantes.REGISTROS);
            } else {
                setMarcas(null);
            }
        })
    }


    const buscarModelosAPI = async (fabricante) => {
        const modelos = await obtener_modelosEnAPI(fabricante);
        if (modelos !== undefined && modelos !== null && modelos.REGISTROS !== null && modelos.REGISTROS.length > 0) {
            setModelos(modelos.REGISTROS);
        } else {
            setModelos(null);
        }
    }


    const buscarMotorizacionAPI = async (modelo) => {
        const motores = await obtener_motorizacionesEnAPI(modelo);
        if (motores !== undefined && motores !== null && motores.REGISTROS !== null && motores.REGISTROS.length > 0) {
            setMotores(motores.REGISTROS);

        } else {
            setMotores(null);
        }
    }




    const buscarPaisesEnAPI = async (ES_NUEVA_TAREA) => {
        const paises = await obtener_paises_EnAPI();
        if (paises !== undefined && paises !== null && paises.REGISTROS !== null && paises.REGISTROS.length > 0) {
            setPaises(paises.REGISTROS);

            if (ES_NUEVA_TAREA) {
                try {
                    const { ID_IDIOMA, CODIGO_IDIOMA } = store.getState().usuario.USUARIO;
                    const { ID, DESCRIPCION } = paises.REGISTROS.find((registro) => registro.DESCRIPCION === (CODIGO_IDIOMA.trim()));

                    payLoadLocal.ID_MAE_PAIS_TAREA = ID;//id desplegable paises
                    payLoadLocal.MATRICULA_PAIS = DESCRIPCION;// desplegable paises
                    setPayLoadLocal({ ...payLoadLocal });

                } catch (error) {
                    console.log("MSG -> ", error)
                }
            }
        } else {
            setPaises(null);
        }
    }


    const buscarConfiguracionEnAPI = async () => {
        const configuracion = await obtener_configuracion_EnAPI();
        if (configuracion !== undefined && configuracion !== null && configuracion.REGISTROS !== null && configuracion.REGISTROS.length > 0) {
            setConfiguracion(configuracion.REGISTROS);
        } else {
            setConfiguracion(null);
        }
    }

    const ObtenerFicherosAPI = (ID_TAREA) => {
        obtener_registrosEnAPI(ID_TAREA).then((fichero) => {
            if (fichero !== undefined && fichero !== null && fichero.ARCHIVOS !== undefined && fichero.ARCHIVOS !== null && fichero.ARCHIVOS.length > 0) {
                setFicherosTecnico(fichero.ARCHIVOS);
            } else {
                setFicherosTecnico(null);
            }
        })
    }


    const ObtenerFicherosTallerAPI = (ID_TAREA) => {
        obtener_registros_taller_EnAPI(ID_TAREA).then((fichero) => {
            if (fichero !== undefined && fichero !== null && fichero.ARCHIVOS !== undefined && fichero.ARCHIVOS !== null && fichero.ARCHIVOS.length > 0) {

                setFicherosTaller(fichero.ARCHIVOS);
            } else {
                setFicherosTaller(null);
            }
        })
    }


    const limpiezaVehiculoTecdoc = () => {
        payLoadLocal.MATRICULA_MARCA_ID = 0
        payLoadLocal.MATRICULA_MODELO_ID = 0
        payLoadLocal.MATRICULA_MOTOR_ID = 0


        payLoadLocal.MATRICULA_MARCA = "";
        payLoadLocal.MATRICULA_MODELO = "";
        payLoadLocal.MATRICULA_MOTOR = "";

        payLoadLocal.ID_VEHICULO_TECDOC = 0;

        setPayLoadLocal({ ...payLoadLocal })
    }




    const busquedaPorMatricula = (TXT_BUSQUEDA_COCHE, PAIS) => {
        loaderTarea.LOADER_MATRICULA = true;
        setLoaderTarea({ ...loaderTarea })

        if (TXT_BUSQUEDA_COCHE !== "") {
            setSeModificaBastidor(false)


            buscar_matriculaEnAPI(TXT_BUSQUEDA_COCHE, PAIS).then(coche => {
                if (coche !== undefined && coche !== null && coche.OK) {
                    coche.TIPO_BUSQUEDA = "MATRICULA";
                    payLoadLocal.BASTIDOR = coche.Matricula.Bastidor;
                    payLoadLocal.MATRICULA_CODIGO_MOTOR = "";

                    setPayLoadLocal({ ...payLoadLocal })
                    setCocheDGT(coche)
                    if (coche.VEHICULOS_TECDOC === null || coche.VEHICULOS_TECDOC.length === 0) {
                        //SIN DATOS DGT
                        setAbrirModalInfoVeh(true)

                        limpiezaVehiculoTecdoc();

                    } else {
                        if (coche.VEHICULOS_TECDOC.length > 0) {
                            if (coche.VEHICULOS_TECDOC.length > 1) {
                                //ABRIR FICHA PARA SELECCIONAR COCHE
                                setAbrirSeleccionVehiculo(true)


                            } else {
                                cocheSeleccionadoLocal(coche.VEHICULOS_TECDOC[0])

                            }
                        } else {
                            MSGERROR(traducirPagina("Msg_sin_resultados"));
                        }
                    }
                }

                loaderTarea.LOADER_MATRICULA = false;
                setLoaderTarea({ ...loaderTarea })
            })

        } else {
            MSGERROR(traducirPagina("Msg_formato_matricula"));
        }
    }


    const busquedaPorBastidor = (TXT_BUSQUEDA_COCHE, PAIS) => {
        loaderTarea.LOADER_BASTIDOR = true;
        setLoaderTarea({ ...loaderTarea })

        if (TXT_BUSQUEDA_COCHE !== "") {

            buscar_bastidorEnAPI(TXT_BUSQUEDA_COCHE, PAIS).then(coche => {
                if (coche !== undefined && coche !== null && coche.OK) {
                    coche.TIPO_BUSQUEDA = "BASTIDOR";
                    setCocheDGT(coche)
                    if (coche.VEHICULOS_TECDOC === null || coche.VEHICULOS_TECDOC.length === 0) {
                        //SIN DATOS DGT
                        setAbrirModalInfoVeh(true)
                        limpiezaVehiculoTecdoc();

                    } else {
                        if (coche.VEHICULOS_TECDOC.length > 0) {
                            if (coche.VEHICULOS_TECDOC.length > 1) {
                                //ABRIR FICHA PARA SELECCIONAR COCHE
                                setAbrirSeleccionVehiculo(true)


                            } else {
                                cocheSeleccionadoLocal(coche.VEHICULOS_TECDOC[0])
                            }

                        } else {
                            MSGERROR(traducirPagina("Msg_sin_resultados"));
                        }
                    }
                }
                loaderTarea.LOADER_BASTIDOR = false;
                setLoaderTarea({ ...loaderTarea })
            })
        } else {
            MSGERROR(traducirPagina("Ingrese_bastidor"));
        }
    }



    const Repreguntar = (ID_CONSULTA) => {
        obtener_tarea_EnAPI(ID_CONSULTA).then((data) => {
            if (data !== undefined && data !== null && data.REGISTRO !== undefined && data.REGISTRO !== null && Object.keys(data.REGISTRO).length > 0) {
                let VEHICULO = data.REGISTRO


                let LISTA_MOTORES = [VEHICULO.MATRICULA_CODIGO_MOTOR];


                payLoadLocal.ID_MAE_PAIS_TAREA = VEHICULO.FK_ID_MAE_PAIS_TAREA;
                payLoadLocal.MATRICULA_PAIS = VEHICULO.MATRICULA_CODIGO_PAIS;
                payLoadLocal.BASTIDOR = VEHICULO.BASTIDOR;
                payLoadLocal.MATRICULA = VEHICULO.MATRICULA;
                payLoadLocal.MATRICULA_MARCA = VEHICULO.MATRICULA_MARCA;
                payLoadLocal.MATRICULA_MODELO = VEHICULO.MATRICULA_MODELO;
                payLoadLocal.MATRICULA_MOTOR = VEHICULO.MATRICULA_MOTOR;

                payLoadLocal.MATRICULA_MARCA_ID = VEHICULO.MATRICULA_MARCA_ID;
                payLoadLocal.MATRICULA_MODELO_ID = VEHICULO.MATRICULA_MODELO_ID;
                payLoadLocal.MATRICULA_MOTOR_ID = VEHICULO.MATRICULA_MOTOR_ID;
                payLoadLocal.ID_VEHICULO_TECDOC = VEHICULO.MATRICULA_MOTOR_ID;


                //payLoadLocal.MATRICULA_CODIGO_MOTOR = VEHICULO.MATRICULA_CODIGO_MOTOR;
                payLoadLocal.MATRICULA_CODIGO_MOTOR = LISTA_MOTORES[0];

                payLoadLocal.CODIGO_AVERIA = VEHICULO.CODIGO_AVERIA;
                setPayLoadLocal({ ...payLoadLocal });


                setCocheSeleccionado({
                    MARCA: VEHICULO.MATRICULA_MARCA_ID,
                    MODELO: VEHICULO.MATRICULA_MODELO_ID,
                    MOTOR: VEHICULO.MATRICULA_MOTOR_ID,
                    TIPO_TAREA: 0,
                    GRUPO_MONTAJE: 0,
                    CODIGO_MOTORES: LISTA_MOTORES
                })


                buscarModelosAPI(VEHICULO.MATRICULA_MARCA_ID);
                buscarMotorizacionAPI(VEHICULO.MATRICULA_MODELO_ID);
                buscarImagenVehiculoEnAPI(VEHICULO.MATRICULA_MOTOR_ID)


                obtener_conector(VEHICULO.MATRICULA_MOTOR_ID, LISTA_MOTORES[0])
            }
        })

    }


    if (!hayCliente && props.ABRIR_FICHA) {
        setHayCliente(true);
        let PAIS_POR_DEFECTO = props.ES_NUEVA_TAREA && Object.keys(props.REGISTRO_SELECCIONADO).length === 0 ? true : false
        buscarPaisesEnAPI(PAIS_POR_DEFECTO);

        //setPaises(props.PAISES);

        buscarFabricanteAPI(1);
        ObtenerTiposTareas();
        ObtenerGrupoMontajes();

        buscarConfiguracionEnAPI();

        if (props.ES_NUEVA_TAREA) {
            //NUEVA TAREA

            setImagenVehiculo(null)

            const COPIA_QUERY = { ...QUERY_USER }
            store.dispatch({ type: "QUERY_USER", QUERY_USER: {} });

            if (Object.keys(COPIA_QUERY).length > 0) {
                payLoadLocal.MATRICULA = COPIA_QUERY.MATRICULA;
                setPayLoadLocal({ ...payLoadLocal });
                busquedaPorMatricula(payLoadLocal.MATRICULA, payLoadLocal.MATRICULA_PAIS);
            }


            if (Object.keys(props.REGISTRO_SELECCIONADO).length > 0) {
                Repreguntar(props.REGISTRO_SELECCIONADO.ID_TAREA)
            }
            

            if (window.location.hostname === "supporte85-flexfuel.servicenext.eu") {
                cocheSeleccionado.TIPO_TAREA = 2;
                setCocheSeleccionado({ ...cocheSeleccionado })
            }
            
            
            

        } else {
            //TAREA SELECCIONADA
            obtener_tarea_EnAPI(props.REGISTRO_SELECCIONADO.ID_TAREA).then((data) => {
                if (data !== undefined && data !== null && data.REGISTRO !== undefined && data.REGISTRO !== null && Object.keys(data.REGISTRO).length > 0) {
                    let VEHICULO = data.REGISTRO

                    let LISTA_MOTORES = [VEHICULO.MATRICULA_CODIGO_MOTOR];


                    //cocheSeleccionado.CODIGO_MOTORES = LISTA_MOTORES



                    setPayLoadLocal({
                        ID_MAE_PAIS_TAREA: VEHICULO.FK_ID_MAE_PAIS_TAREA,//id desplegable paises
                        MATRICULA_PAIS: VEHICULO.MATRICULA_CODIGO_PAIS,// desplegable paises
                        ID_VEHICULO_TECDOC: VEHICULO.ID_VEHICULO_TECDOC,
                        BASTIDOR: VEHICULO.BASTIDOR,
                        MATRICULA: VEHICULO.MATRICULA,
                        MATRICULA_MARCA: VEHICULO.MATRICULA_MARCA,
                        MATRICULA_MODELO: VEHICULO.MATRICULA_MODELO,
                        MATRICULA_MOTOR: VEHICULO.MATRICULA_MOTOR,
                        //MATRICULA_CODIGO_MOTOR: VEHICULO.MATRICULA_CODIGO_MOTOR,
                        MATRICULA_CODIGO_MOTOR: LISTA_MOTORES[0],

                        ID_MAE_PLATAFORMA: VEHICULO.FK_ID_MAE_PLATAFORMA,
                        ID_MAE_GRUPO_MONTAJE: VEHICULO.FK_ID_MAE_GRUPO_MONTAJE,//desplegable
                        ID_MAE_PRIORIDAD_TAREA: VEHICULO.FK_ID_MAE_PRIORIDAD_TAREA,
                        ID_MAE_TIPO_TAREA: VEHICULO.FK_ID_MAE_TIPO_TAREA,//Poner desplegable

                        TEXTO: VEHICULO.TEXTO_CONSULTA,
                        CODIGO_AVERIA: VEHICULO.CODIGO_AVERIA,

                        TEXTO_RESPUESTA: VEHICULO.TEXTO_RESPUESTA,
                        ID_MAE_GRADO_SATISFACCION: VEHICULO.FK_ID_MAE_GRADO_SATISFACCION,
                        RESOLUCION_DE_TAREAS: VEHICULO.RESOLUCION_DE_TAREAS,
                        ID_MAE_ESTADO_TAREA: VEHICULO.FK_ID_MAE_ESTADO_TAREA, //Estado si asignar

                        ID_USUARIO_ASIGNADO: VEHICULO.FK_ID_USUARIO_ASIGNADO,
                        ID_USUARIO_EN_CURSO: VEHICULO.FK_ID_USUARIO_EN_CURSO,
                        ID_USUARIO_RESPUESTA: VEHICULO.FK_ID_USUARIO_RESPUESTA,
                        NOMBRE_USUARIO_RESPUESTA: VEHICULO.NOMBRE_USUARIO_RESPUESTA,
                    })

                    setCocheSeleccionado({
                        MARCA: VEHICULO.MATRICULA_MARCA_ID,
                        MODELO: VEHICULO.MATRICULA_MODELO_ID,
                        MOTOR: VEHICULO.MATRICULA_MOTOR_ID,
                        TIPO_TAREA: VEHICULO.FK_ID_MAE_TIPO_TAREA,
                        GRUPO_MONTAJE: VEHICULO.FK_ID_MAE_GRUPO_MONTAJE,
                        CODIGO_MOTORES: LISTA_MOTORES
                    })


                    buscarModelosAPI(VEHICULO.MATRICULA_MARCA_ID);
                    buscarMotorizacionAPI(VEHICULO.MATRICULA_MODELO_ID);
                    ObtenerFicherosAPI(VEHICULO.ID_TAREA);
                    ObtenerFicherosTallerAPI(VEHICULO.ID_TAREA)
                    buscarImagenVehiculoEnAPI(VEHICULO.MATRICULA_MOTOR_ID)
                }
            })

        }
    } else {
        if (!props.ABRIR_FICHA && hayCliente) {
            setHayCliente(false);
            setTabPorDefecto("1")
            setPayLoadLocal({
                ID_MAE_PAIS_TAREA: "",//id desplegable paises
                ID_VEHICULO_TECDOC: 0,
                MATRICULA_MARCA_ID: 0,
                MATRICULA_MODELO_ID: 0,
                MATRICULA_MOTOR_ID: 0,
                BASTIDOR: "",
                MATRICULA: "",
                MATRICULA_MARCA: "",
                MATRICULA_MODELO: "",
                MATRICULA_MOTOR: "",
                MATRICULA_CODIGO_MOTOR: "",
                MATRICULA_PAIS: "E",// desplegable paises
                ID_MAE_PLATAFORMA: "3",
                ID_MAE_GRUPO_MONTAJE: "",//desplegable
                ID_MAE_PRIORIDAD_TAREA: "3",
                ID_MAE_TIPO_TAREA: "",//Poner desplegable

                TEXTO: "",
                CODIGO_AVERIA: "",

                TEXTO_RESPUESTA: "",
                ID_MAE_GRADO_SATISFACCION: "",
                RESOLUCION_DE_TAREAS: "",
                ID_MAE_ESTADO_TAREA: "1",//Estado si asignar

                ID_USUARIO_ASIGNADO: "",
                ID_USUARIO_EN_CURSO: "",
                ID_USUARIO_RESPUESTA: "",
                NOMBRE_USUARIO_RESPUESTA: ""
            })

        }
    }



    const guardarEstadoFormulario = (e) => {
        const ID_MAE_PAIS_TAREA = e.target.name === 'ID_MAE_PAIS_TAREA' ? e.target.value : payLoadLocal.ID_MAE_PAIS_TAREA;
        const ID_VEHICULO_TECDOC = e.target.name === 'ID_VEHICULO_TECDOC' ? e.target.value : payLoadLocal.ID_VEHICULO_TECDOC;
        const BASTIDOR = e.target.name === 'BASTIDOR' ? e.target.value : payLoadLocal.BASTIDOR;
        const MATRICULA = e.target.name === 'MATRICULA' ? e.target.value : payLoadLocal.MATRICULA;
        const MATRICULA_MARCA = e.target.name === 'MATRICULA_MARCA' ? e.target.value : payLoadLocal.MATRICULA_MARCA;
        const MATRICULA_MODELO = e.target.name === 'MATRICULA_MODELO' ? e.target.value : payLoadLocal.MATRICULA_MODELO;
        const MATRICULA_MOTOR = e.target.name === 'MATRICULA_MOTOR' ? e.target.value : payLoadLocal.MATRICULA_MOTOR;
        const MATRICULA_CODIGO_MOTOR = e.target.name === 'MATRICULA_CODIGO_MOTOR' ? e.target.value : payLoadLocal.MATRICULA_CODIGO_MOTOR;
        const MATRICULA_PAIS = e.target.name === 'MATRICULA_PAIS' ? e.target.value : payLoadLocal.MATRICULA_PAIS;
        const ID_MAE_PLATAFORMA = e.target.name === 'ID_MAE_PLATAFORMA' ? e.target.value : payLoadLocal.ID_MAE_PLATAFORMA;
        const ID_MAE_GRUPO_MONTAJE = e.target.name === 'ID_MAE_GRUPO_MONTAJE' ? e.target.value : payLoadLocal.ID_MAE_GRUPO_MONTAJE;
        const ID_MAE_PRIORIDAD_TAREA = e.target.name === 'ID_MAE_PRIORIDAD_TAREA' ? e.target.value : payLoadLocal.ID_MAE_PRIORIDAD_TAREA;
        const TEXTO = e.target.name === 'TEXTO' ? e.target.value : payLoadLocal.TEXTO;
        const CODIGO_AVERIA = e.target.name === 'CODIGO_AVERIA' ? e.target.value : payLoadLocal.CODIGO_AVERIA;
        const TEXTO_RESPUESTA = e.target.name === 'TEXTO_RESPUESTA' ? e.target.value : payLoadLocal.TEXTO_RESPUESTA;
        const ID_MAE_GRADO_SATISFACCION = e.target.name === 'ID_MAE_GRADO_SATISFACCION' ? e.target.value : payLoadLocal.ID_MAE_GRADO_SATISFACCION;
        const RESOLUCION_DE_TAREAS = e.target.name === 'RESOLUCION_DE_TAREAS' ? e.target.value : payLoadLocal.RESOLUCION_DE_TAREAS;
        const ID_MAE_ESTADO_TAREA = e.target.name === 'ID_MAE_ESTADO_TAREA' ? e.target.value : payLoadLocal.ID_MAE_ESTADO_TAREA;
        const ID_USUARIO_ASIGNADO = e.target.name === 'ID_USUARIO_ASIGNADO' ? e.target.value : payLoadLocal.ID_USUARIO_ASIGNADO;
        const ID_USUARIO_EN_CURSO = e.target.name === 'ID_USUARIO_EN_CURSO' ? e.target.value : payLoadLocal.ID_USUARIO_EN_CURSO;
        const ID_USUARIO_RESPUESTA = e.target.name === 'ID_USUARIO_RESPUESTA' ? e.target.value : payLoadLocal.ID_USUARIO_RESPUESTA;
        const ID_MAE_TIPO_TAREA = e.target.name === 'ID_MAE_TIPO_TAREA' ? e.target.value : payLoadLocal.ID_MAE_TIPO_TAREA;

        const MATRICULA_MARCA_ID = e.target.name === 'MATRICULA_MARCA_ID' ? e.target.value : payLoadLocal.MATRICULA_MARCA_ID;
        const MATRICULA_MODELO_ID = e.target.name === 'MATRICULA_MODELO_ID' ? e.target.value : payLoadLocal.MATRICULA_MODELO_ID;
        const MATRICULA_MOTOR_ID = e.target.name === 'MATRICULA_MOTOR_ID' ? e.target.value : payLoadLocal.MATRICULA_MOTOR_ID;
        const NOMBRE_USUARIO_RESPUESTA = e.target.name === 'NOMBRE_USUARIO_RESPUESTA' ? e.target.value : payLoadLocal.NOMBRE_USUARIO_RESPUESTA;




        setPayLoadLocal(
            {
                ID_MAE_PAIS_TAREA: ID_MAE_PAIS_TAREA,
                ID_VEHICULO_TECDOC: ID_VEHICULO_TECDOC,
                BASTIDOR: BASTIDOR,
                MATRICULA: MATRICULA,
                MATRICULA_MARCA: MATRICULA_MARCA,
                MATRICULA_MODELO: MATRICULA_MODELO,
                MATRICULA_MOTOR: MATRICULA_MOTOR,
                MATRICULA_CODIGO_MOTOR: MATRICULA_CODIGO_MOTOR,
                MATRICULA_PAIS: MATRICULA_PAIS,
                ID_MAE_PLATAFORMA: ID_MAE_PLATAFORMA,
                ID_MAE_GRUPO_MONTAJE: ID_MAE_GRUPO_MONTAJE,
                ID_MAE_PRIORIDAD_TAREA: ID_MAE_PRIORIDAD_TAREA,
                TEXTO: TEXTO,
                CODIGO_AVERIA: CODIGO_AVERIA,
                TEXTO_RESPUESTA: TEXTO_RESPUESTA,
                ID_MAE_GRADO_SATISFACCION: ID_MAE_GRADO_SATISFACCION,
                RESOLUCION_DE_TAREAS: RESOLUCION_DE_TAREAS,
                ID_MAE_ESTADO_TAREA: ID_MAE_ESTADO_TAREA,
                ID_USUARIO_ASIGNADO: ID_USUARIO_ASIGNADO,
                ID_USUARIO_EN_CURSO: ID_USUARIO_EN_CURSO,
                ID_USUARIO_RESPUESTA: ID_USUARIO_RESPUESTA,
                ID_MAE_TIPO_TAREA: ID_MAE_TIPO_TAREA,
                MATRICULA_MARCA_ID: MATRICULA_MARCA_ID,
                MATRICULA_MODELO_ID: MATRICULA_MODELO_ID,
                MATRICULA_MOTOR_ID: MATRICULA_MOTOR_ID,
                NOMBRE_USUARIO_RESPUESTA: NOMBRE_USUARIO_RESPUESTA
            }
        )
    }


    const limpiarCoche = () => {
        setModelos([]);
        setMotores([]);
        setImagenVehiculo(null);

        cocheSeleccionado.MARCA = 0;
        cocheSeleccionado.MODELO = 0;
        cocheSeleccionado.MOTOR = 0;
        cocheSeleccionado.CODIGO_MOTORES = [];
        payLoadLocal.MATRICULA_CODIGO_MOTOR = "";
        payLoadLocal.ID_VEHICULO_TECDOC = 0;
        payLoadLocal.MATRICULA = "";
        payLoadLocal.BASTIDOR = "";

        payLoadLocal.MATRICULA_MARCA = "";
        payLoadLocal.MATRICULA_MODELO = "";
        payLoadLocal.MATRICULA_MOTOR = "";



        setCocheSeleccionado({ ...cocheSeleccionado })
        setPayLoadLocal({ ...payLoadLocal })
    }





    const gestionarTarea = async () => {
        const TEXTO = payLoadLocal.TEXTO.trim();

        if (TEXTO === "") {
            MSGERROR(traducirPagina("Falta_texto_consulta").toUpperCase());
        } else {
            setCreandoTarea(true)

            let copiarPayload = { ...payLoadLocal };
            const { GRUPO_MONTAJE, TIPO_TAREA } = cocheSeleccionado;

            copiarPayload.ID_MAE_GRUPO_MONTAJE = GRUPO_MONTAJE;
            copiarPayload.ID_MAE_TIPO_TAREA = TIPO_TAREA;

            copiarPayload.ID_USUARIO_EN_CURSO = null;
            copiarPayload.ID_USUARIO_RESPUESTA = null;
            copiarPayload.ID_USUARIO_ASIGNADO = null;
            copiarPayload.ID_MAE_GRADO_SATISFACCION = null;



            const data = await nueva_tareaEnAPI(copiarPayload);

            if (data !== undefined && data !== null && data.OK) {

                const registroFicheros = archivosLocal.map(async (fichero) => {
                    let texto = `data:${fichero.TYPE};base64,`
                    let DATOS_ENVIAR = fichero.DATOS.replace(texto, "")
                    return await insertar_ficheroEnAPI(data.ID, fichero.NOMBRE, fichero.EXTENSION, DATOS_ENVIAR);
                })

                Promise.all(registroFicheros).then(data => {
                    //console.log("lista", data);
                    const registros = data.filter((registro) => registro.OK === false);
                    if (registros.length > 0) {
                        MSGERROR(JSON.stringify(registros))
                    } else {
                        MSGOK(traducirPagina("Consulta_enviada"));
                        props.CERRAR_FICHA(false);
                    }

                    setCreandoTarea(false);
                });

            } else {
                setCreandoTarea(false);
            }


            props.ACTUALIZAR_TABLA(3, "D", false, props.FILTROS.TAREAS_CERRADAS, obtenerLicenciaLocal().ID_TALLER, null, props.FILTRO_FECHA.FECHA_PARTIDA, props.FILTRO_FECHA.ACCION_DESDE, props.FILTRO_FECHA.ACCION_HASTA)


        }
    }

    const visualizador = async (fichero, esTecnico) => {

        if (fichero.NOMBRE_IMAGEN === "pdf.png" || fichero.NOMBRE_IMAGEN === "imagen.png" || fichero.NOMBRE_IMAGEN === "video.png") {
            const CONSTRUIR_URL = `${END_POINT_ACTUAL()}Archivos/${(esTecnico ? "Tecnico/" : "")}visualizar/${fichero.ID}`;

            setFicheroSeleccionado({ TITULO: fichero.NOMBRE, TIPO_DOCUMENTO: fichero.NOMBRE_IMAGEN === "pdf.png" ? "DOC" : fichero.NOMBRE_IMAGEN === "imagen.png" ? "IMG" : "VIDEO", URL: CONSTRUIR_URL })
            setAbrirVisor(true);

        }
    }

    const desacargarFichero = async (fichero, esTecnico) => {
        const CONSTRUIR_URL = `${END_POINT_ACTUAL()}Archivos/${(esTecnico ? "Tecnico/" : "")}Descargar/${fichero.ID}`;
        descargarDocPasandoBase64Generico(CONSTRUIR_URL, fichero.NOMBRE)
    }

    const actualizarVehiculoTecdoc = (VEHICULO) => {
        const { ID_MARCA, ID_MODELO, ID_MOTOR, MOTORES, DESCRIPCION_MARCA, DESCRIPCION_MODELO, DESCRIPCION_MOTOR } = VEHICULO

        let LISTA_MOTORES = obtenerCodigoMotores(MOTORES);
        cocheSeleccionado.CODIGO_MOTORES = LISTA_MOTORES
        if (LISTA_MOTORES.length === 1) {
            payLoadLocal.MATRICULA_CODIGO_MOTOR = LISTA_MOTORES[0];

            obtener_conector(ID_MOTOR, LISTA_MOTORES[0])
        }
        //cocheSeleccionado.CODIGO_MOTORES =  obtenerCodigoMotores(MOTORES);
        setCocheSeleccionado({ ...cocheSeleccionado })


        payLoadLocal.MATRICULA_MARCA_ID = ID_MARCA
        payLoadLocal.MATRICULA_MODELO_ID = ID_MODELO
        payLoadLocal.MATRICULA_MOTOR_ID = ID_MOTOR

        //payLoadLocal.MATRICULA_CODIGO_MOTOR = MOTORES 


        payLoadLocal.MATRICULA_MARCA = DESCRIPCION_MARCA;
        payLoadLocal.MATRICULA_MODELO = DESCRIPCION_MODELO;
        payLoadLocal.MATRICULA_MOTOR = DESCRIPCION_MOTOR;

        payLoadLocal.ID_VEHICULO_TECDOC = ID_MOTOR;
        buscarImagenVehiculoEnAPI(ID_MOTOR);
        setPayLoadLocal({ ...payLoadLocal })
    }

   

    const comprobarConector = (conector) =>{
        let MOSTRAR_MESAJE = false
        if(conector === null){
            MOSTRAR_MESAJE = true;
        }else{
          if(conector.length === 1){
              if(conector[0].CONECTOR === "" || conector[0].IMAGEN === ""){
                  MOSTRAR_MESAJE = true;
              }
          }    
       
          if(conector.length > 1){
              MOSTRAR_MESAJE = true;
          }
        }
        return MOSTRAR_MESAJE;
    }


    return (
        <>
            <Drawer
                width={configApp.width <= 900 ? '100%' : 800}
                onClose={() => {
                    props.MODIFICAR_REGISTRO_CONSULTA({})
                    props.CERRAR_FICHA(false)
                }}
                closable={false}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={creandoTarea ? { padding: '0px', pointerEvents: 'none', opacity: '0.7' } : { padding: '0px' }}
                style={{ color: '#fff' }}>

                <div className="flex-container-Grow-Tercero header_ficha" style={{ height: '56px' }}>
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {

                            props.MODIFICAR_REGISTRO_CONSULTA({})
                            props.CERRAR_FICHA(false)

                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                {payLoadLocal === null ?
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <h4>{traducirPagina("Msg_sin_info_consulta")}</h4>
                    </div>
                    :
                    <>
                        <Tabs defaultActiveKey={"1"} activeKey={tabPorDefecto} onChange={(tab) => setTabPorDefecto(tab)}
                            style={{ padding: '0px 20px 0px 20px' }}
                        >
                            <TabPane tab={traducirPagina("Vehiculo")} key="1" style={{ height: (configApp.height - 183).toString() + "px", overflowY: 'auto', overflowX: 'hidden' }} >
                                <div style={{ padding: '20px 5px 0px 0px', pointerEvents: props.ES_NUEVA_TAREA ? '' : 'none', opacity: props.ES_NUEVA_TAREA ? '' : '0.7' }} >

                                    <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                        <Col md={8}>
                                            <MDBCard style={{ margin: '0px 20px 0px 1px' }}>
                                                <MDBCardBody style={{ textAlign: 'center' }}>
                                                    {imagenVehiculo === null ?
                                                        <img
                                                            src={"./imagenes/sinimagen.jpg"}
                                                            alt=""
                                                            className="img-fluid"
                                                            style={{ height: '140px', cursor: 'pointer' }}
                                                        />
                                                        :
                                                        imagenVehiculo === "" ?

                                                            <div className='skeleton-box' style={{ width: '180px', height: '151px' }}></div>
                                                            :
                                                            <img
                                                                src={imagenVehiculo}
                                                                alt=""
                                                                className="img-fluid"
                                                                style={{ height: '140px', cursor: 'pointer' }}
                                                            />
                                                    }

                                                </MDBCardBody>
                                            </MDBCard>
                                        </Col>
                                        <Col md={16}>
                                            <span>{`${traducirPagina("Matricula")}: `} </span>
                                            <Search
                                                loading={loaderTarea.LOADER_MATRICULA}
                                                addonBefore={selectBefore}

                                                placeholder={traducirPagina("Ingrese_matricula")}
                                                allowClear
                                                size="large"
                                                enterButton
                                                value={payLoadLocal.MATRICULA}
                                                name="MATRICULA"
                                                style={{ marginTop: '8px' }}
                                                onChange={(e) => { guardarEstadoFormulario(e) }}
                                                onBlur={(e) => {
                                                    if (e.target.value !== undefined && e.target.value !== null && e.target.value !== "") {
                                                        if (!verificarMatricula(e.target.value)) {
                                                            MSGERROR(traducirPagina("Msg_info_matricula"));

                                                            payLoadLocal.MATRICULA = "";
                                                            setPayLoadLocal({ ...payLoadLocal })
                                                        }
                                                    }

                                                }}
                                                onSearch={(e) => {
                                                    if (e === "") {
                                                        limpiarCoche()
                                                    } else {
                                                        cocheSeleccionado.CODIGO_MOTORES = []
                                                        setCocheSeleccionado({ ...cocheSeleccionado })

                                                        busquedaPorMatricula(e, payLoadLocal.MATRICULA_PAIS)
                                                    }
                                                }}
                                            />

                                            <div style={{ marginTop: '20px' }}></div>
                                            <span>{`${traducirPagina("Bastidor")}: `} </span>
                                            <Search
                                                loading={loaderTarea.LOADER_BASTIDOR}
                                                addonBefore={selectBefore}
                                                placeholder={traducirPagina("Ingrese_bastidor")}
                                                allowClear
                                                size="large"
                                                enterButton
                                                value={seModificaBastidor ? payLoadLocal.BASTIDOR : convertirTextoBastidor(payLoadLocal.BASTIDOR)}
                                                name="BASTIDOR"
                                                style={{ marginTop: '8px' }}

                                                onChange={(e) => { guardarEstadoFormulario(e); setSeModificaBastidor(true) }}
                                                onSearch={(e) => {
                                                    if (e === "") {
                                                        limpiarCoche()
                                                    } else {
                                                        cocheSeleccionado.CODIGO_MOTORES = []
                                                        setCocheSeleccionado({ ...cocheSeleccionado })

                                                        payLoadLocal.MATRICULA = "";
                                                        payLoadLocal.MATRICULA_CODIGO_MOTOR = "";
                                                        setPayLoadLocal({ ...payLoadLocal })
                                                        //limpiarCoche();
                                                        busquedaPorBastidor(e, payLoadLocal.MATRICULA_PAIS)
                                                        //buscarMatricula(e);
                                                    }

                                                }}
                                            />
                                        </Col>

                                        <Col md={24}>
                                            <span>{traducirPagina("Vehiculo")} </span>
                                        </Col>

                                        <Col md={24}>

                                            <div className='v_consulta_Seleccionado'>
                                                <div className='v_consulta_Seleccionado_texto'>
                                                    <input type='text' value={`${payLoadLocal.MATRICULA_MARCA} ${payLoadLocal.MATRICULA_MODELO}  ${payLoadLocal.MATRICULA_MOTOR} `} disabled></input>
                                                </div>

                                                <div className='v_consulta_Seleccionado_remove'  >
                                                    {(`${payLoadLocal.MATRICULA_MARCA} ${payLoadLocal.MATRICULA_MODELO}  ${payLoadLocal.MATRICULA_MOTOR}`).trim() === "" ?
                                                        <SearchOutlined onClick={() => {
                                                            setAbrirFichaTecdoc(true)

                                                        }}></SearchOutlined>
                                                        :
                                                        <CloseOutlined onClick={() => {
                                                            setAbrirFichaTecdoc(true)
                                                            limpiarCoche()

                                                            payLoadLocal.MATRICULA_MARCA_ID = 0
                                                            payLoadLocal.MATRICULA_MODELO_ID = 0
                                                            payLoadLocal.MATRICULA_MOTOR_ID = 0
                                                            payLoadLocal.ID_VEHICULO_TECDOC = 0;

                                                            payLoadLocal.MATRICULA_CODIGO_MOTOR = "";

                                                            payLoadLocal.MATRICULA_MARCA = ""
                                                            payLoadLocal.MATRICULA_MODELO = ""
                                                            payLoadLocal.MATRICULA_MOTOR = ""
                                                            setPayLoadLocal({ ...payLoadLocal })
                                                            setConector([])

                                                        }}></CloseOutlined>
                                                    }

                                                </div>

                                                <div className='v_consulta_Seleccionado_barra'></div>


                                                <div className='v_consulta_Seleccionado_info' onClick={(e) => {
                                                    if (payLoadLocal.ID_VEHICULO_TECDOC > 0 && payLoadLocal.ID_VEHICULO_TECDOC !== "") {
                                                        setAbrirFichaDetalleVeh(true);
                                                    }

                                                }}>
                                                    <img src="./imagenes/cocheGris.svg" alt='coche' ></img>
                                                </div>
                                            </div>

                                        </Col>

                                        <Col md={12}>
                                            <span>{`${traducirPagina("Conector")}: `} </span>

                                            <Input.Group compact>
                                                <Input placeholder={comprobarConector(conector) ? traducirPagina("MontajeAComprobar"):""} disabled={true} style={{ width: 'calc(100% - 58px)', marginTop: '8px' }} size={"large"} type="text" name="CONECTOR" value={conector === null ? "" : conector.length > 0 ? conector[0].CONECTOR : "" } />

                                                <Popover
                                                    content={<div className='container_popover'><img src={conector === null ? "" : conector.length === 1  && conector[0].IMAGEN !== "" ? `./conectores/${conector[0].IMAGEN}.png` : ""} /> </div>}
                                                    title=""
                                                    trigger="click"
                                                    visible={visible}
                                                    onVisibleChange={(e) => { setVisible(e) }}
                                                >
                                                    <div className='button_Conector'>
                                                        <FileImageOutlined></FileImageOutlined>
                                                    </div>
                                                </Popover>
                                            </Input.Group>


                                            {/*
                                            
                                            <Input
                                                disabled={true}
                                                style={{ marginTop: '8px' }}

                                                size={"large"} type="text" name="CONECTOR" value={conector === null ? "" : conector.length > 0 ? conector[0].CONECTOR : ""} />
                                    */}

                                        </Col>

                                        <Col md={12}>
                                            <span>{`${traducirPagina("Codigo_motor")}: `} </span>

                                            <Select
                                                style={{ width: "100%", textAlign: 'left', marginTop: '8px' }}
                                                value={cocheSeleccionado.CODIGO_MOTORES === undefined || cocheSeleccionado.CODIGO_MOTORES.length === 0 ? "" : payLoadLocal.MATRICULA_CODIGO_MOTOR === "" ? `${traducirPagina("Msg_mas_codigo")}` : payLoadLocal.MATRICULA_CODIGO_MOTOR}
                                                optionFilterProp="children"
                                                size="large"
                                                onChange={(e) => {
                                                    if (e === undefined || e === "") {
                                                        payLoadLocal.MATRICULA_CODIGO_MOTOR = "";
                                                        setPayLoadLocal({ ...payLoadLocal })
                                                    } else {
                                                        payLoadLocal.MATRICULA_CODIGO_MOTOR = e;

                                                        obtener_conector(payLoadLocal.MATRICULA_MOTOR_ID, e)

                                                        setPayLoadLocal({ ...payLoadLocal })
                                                    }
                                                }}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>


                                                {
                                                    cocheSeleccionado.CODIGO_MOTORES !== undefined ?
                                                        cocheSeleccionado.CODIGO_MOTORES.map(grupo =>
                                                            <Option key={grupo} value={grupo}>{grupo}</Option>
                                                        )
                                                        :
                                                        null
                                                }
                                            </Select>
                                        </Col>




                                        <Col md={24}>
                                            <span>{`${traducirPagina("Codigo_averia")}: `} </span>
                                            <Input
                                                style={{ marginTop: '8px' }}
                                                autoComplete="new-password"
                                                size={"large"} type="text" name="CODIGO_AVERIA" value={payLoadLocal.CODIGO_AVERIA} onChange={(e) => { guardarEstadoFormulario(e) }} />
                                        </Col>


                                        <Col md={12} style={{ pointerEvents: props.ES_NUEVA_TAREA ? '' : 'none', opacity: props.ES_NUEVA_TAREA ? '' : '0.7' }}>
                                            <span>{`${traducirPagina("Tipo_tarea")}: `} </span>
                                            <Select
                                                disabled={tipoTarea !== null && tipoTarea.length > 0 ? false : true}
                                                style={{ width: "100%", textAlign: 'left', marginTop: '8px' }}
                                                value={cocheSeleccionado.TIPO_TAREA === 0 ? `${traducirPagina("Seleccione_tarea")}` : cocheSeleccionado.TIPO_TAREA}
                                                optionFilterProp="children"
                                                size="large"
                                                onChange={(e) => {
                                                    if (e === undefined) {
                                                        cocheSeleccionado.TIPO_TAREA = 0;
                                                        setCocheSeleccionado({ ...cocheSeleccionado })
                                                    } else {
                                                        cocheSeleccionado.TIPO_TAREA = e;
                                                        setCocheSeleccionado({ ...cocheSeleccionado })
                                                    }
                                                }}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {tipoTarea !== undefined && tipoTarea !== null && tipoTarea.length > 0 ?
                                                    tipoTarea.map(tipo =>
                                                        <Option key={tipo.ID} value={tipo.ID}>{tipo.NOMBRE}</Option>
                                                    )
                                                    :
                                                    null
                                                }
                                            </Select>
                                        </Col>

                                        <Col md={12} style={{ pointerEvents: props.ES_NUEVA_TAREA ? '' : 'none', opacity: props.ES_NUEVA_TAREA ? '' : '0.7' }}>
                                            <span>{`${traducirPagina("Grupo_montaje")}: `} </span>

                                            <Select
                                                disabled={grupoMontaje !== null && grupoMontaje.length > 0 ? false : true}
                                                style={{ width: "100%", textAlign: 'left', marginTop: '8px' }}
                                                value={cocheSeleccionado.GRUPO_MONTAJE === 0 ? `${traducirPagina("Seleccione_grupo")}` : cocheSeleccionado.GRUPO_MONTAJE}
                                                optionFilterProp="children"

                                                size="large"
                                                onChange={(e) => {
                                                    if (e === undefined) {
                                                        cocheSeleccionado.GRUPO_MONTAJE = 0;
                                                        setCocheSeleccionado({ ...cocheSeleccionado })
                                                    } else {
                                                        cocheSeleccionado.GRUPO_MONTAJE = e;
                                                        setCocheSeleccionado({ ...cocheSeleccionado })
                                                    }
                                                }}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }>
                                                {grupoMontaje !== undefined && grupoMontaje !== null && grupoMontaje.length > 0 ?
                                                    grupoMontaje.map(grupo =>
                                                        <Option key={grupo.ID} value={grupo.ID}>{grupo.NOMBRE}</Option>
                                                    )
                                                    :
                                                    null
                                                }
                                            </Select>
                                        </Col>
                                    </Row>

                                </div>
                            </TabPane>

                            <TabPane tab={traducirPagina("Consulta")} key="2" style={{ height: (configApp.height - 183).toString() + "px", overflowY: 'auto', overflowX: 'hidden' }}>
                                <div style={{ padding: '20px 5px 0px 0px' }}>
                                    <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>

                                        <Col md={24} style={{ pointerEvents: props.ES_NUEVA_TAREA ? '' : 'none', opacity: props.ES_NUEVA_TAREA ? '' : '0.7' }}>
                                            <span>{`${traducirPagina("Consulta")}: `} </span>
                                        </Col>
                                        <Col md={24} style={{ pointerEvents: props.ES_NUEVA_TAREA ? '' : 'none', opacity: props.ES_NUEVA_TAREA ? '' : '0.7' }}>
                                            <Input.TextArea name="TEXTO" rows={10} value={payLoadLocal.TEXTO} onChange={(e) => guardarEstadoFormulario(e)} style={{ fontSize: '16px', fontWeight: '500' }} />
                                        </Col>
                                        <Col md={24} >
                                            <div className="containerFicheros" style={{ margin: '0px 0px 0px 1px' }}>
                                                {props.ES_NUEVA_TAREA ?
                                                    <>
                                                        {archivosLocal !== undefined ?
                                                            archivosLocal.map((fichero, i) =>
                                                                <div key={i} className="containerFicheros_card" title={fichero.NOMBRE} >
                                                                    <div className='containerFicheros_card_body' style={{ height: '140px', width: '140px' }}>
                                                                        <img src={`./imagenes/iconos/${fichero.PROPIEDADES_FICHERO.TIPO}`} alt={fichero.NOMBRE} />

                                                                        <div className="image-mask">
                                                                            <div className="image-mask-info" onClick={() => { eliminarFichero(fichero) }}>
                                                                                <label><DeleteOutlined style={{ fontSize: '14px', display: 'block' }}></DeleteOutlined> {traducirPagina("Eliminar")}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) :
                                                            null
                                                        }

                                                        {
                                                            archivosLocal !== undefined && archivosLocal.length <= 4 && configuracion !== null ?
                                                                <>
                                                                    <label id="getFileLabel" htmlFor="getFile" style={{ fontSize: '18px' }}><PlusOutlined ></PlusOutlined> {traducirPagina("Adjuntar")}</label>
                                                                    <input type="file" id="getFile" accept={obtenerConfig(configuracion, "TIPOS_ARCHIVOS").VALOR} onChange={(e) => { gestionarFicheroLocal(e.target) }} />
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                    :
                                                    ficherosTaller !== null ?
                                                        ficherosTaller.length > 0 ?
                                                            <>
                                                                <span style={{ width: '100%' }}> {traducirPagina("Mis_documentos")}</span>
                                                                {ficherosTaller.map((fichero, i) =>
                                                                    <div key={i} className="containerFicheros_card" title={fichero.NOMBRE}>
                                                                        <div className='containerFicheros_card_body'>

                                                                            <img src={`./imagenes/iconos/${fichero.NOMBRE_IMAGEN}`} alt={fichero.NOMBRE} />

                                                                            <div className="image-mask">
                                                                                <div className="image-mask-info" onClick={() => { eliminarFichero(fichero) }}>
                                                                                    {fichero.NOMBRE_IMAGEN === "pdf.png" || fichero.NOMBRE_IMAGEN === "imagen.png" || fichero.NOMBRE_IMAGEN === "video.png" ?
                                                                                        <EyeOutlined title='Visualizar' style={{ fontSize: '24px', display: 'inline-block', marginRight: '20px' }} onClick={() => { visualizador(fichero, false); }}></EyeOutlined>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    <CloudDownloadOutlined title='Descargar' style={{ fontSize: '24px', display: 'inline-block' }} onClick={() => {
                                                                                        desacargarFichero(fichero, false)

                                                                                    }}></CloudDownloadOutlined>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                            :
                                                            <div style={{ display: 'flex', alignItems: 'center', minHeight: '70vh', margin: '20px auto' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled />  </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </TabPane>

                            {props.ES_NUEVA_TAREA ?
                                null
                                :
                                payLoadLocal.ID_MAE_ESTADO_TAREA === 8 ?
                                    <TabPane tab={traducirPagina("Respuesta")} key="3" style={{ height: (configApp.height - 183).toString() + "px", overflowY: 'auto', overflowX: 'hidden', display: payLoadLocal.ID_MAE_ESTADO_TAREA === 8 ? '' : 'none' }}>
                                        <div style={{ padding: '20px 5px 0px 0px' }}>
                                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>
                                                <Col md={24} style={{ pointerEvents: props.ES_NUEVA_TAREA ? '' : 'none', opacity: props.ES_NUEVA_TAREA ? '' : '0.7' }} >
                                                    <span>{`${traducirPagina("Respuesta_tecnico")} ${payLoadLocal.NOMBRE_USUARIO_RESPUESTA !== undefined
                                                        && payLoadLocal.NOMBRE_USUARIO_RESPUESTA !== null ? payLoadLocal.NOMBRE_USUARIO_RESPUESTA : ''}:`} </span>
                                                </Col>
                                                <Col md={24} style={{ opacity: props.ES_NUEVA_TAREA ? '' : '0.7' }}>

                                                    <div className='Container_respuesta_tecnico' dangerouslySetInnerHTML={{ __html: (payLoadLocal.TEXTO_RESPUESTA) }}>

                                                    </div>

                                                    {/* <Input.TextArea style={{ fontSize: '16px', fontWeight: '500' }} name="TEXTO_RESPUESTA" rows={10} value={payLoadLocal.TEXTO_RESPUESTA} onChange={(e) => props.ES_NUEVA_TAREA ? guardarEstadoFormulario(e) : null} />*/}
                                                </Col>

                                                <Col md={24}>
                                                    <div className="containerFicheros" style={{ margin: '0px 0px 0px 1px' }}>
                                                        {
                                                            ficherosTecnico !== null ?
                                                                ficherosTecnico.length > 0 ?
                                                                    <>
                                                                        <span style={{ width: '100%' }}> {traducirPagina("Documentos_tecnico")}</span>

                                                                        {ficherosTecnico.map((fichero, i) =>
                                                                            <div key={i} className="containerFicheros_card" title={fichero.NOMBRE}>
                                                                                <div className='containerFicheros_card_body' >
                                                                                    <img src={`./imagenes/iconos/${fichero.NOMBRE_IMAGEN}`} alt={fichero.NOMBRE} />

                                                                                    <div className="image-mask">
                                                                                        <div className="image-mask-info" onClick={() => { eliminarFichero(fichero) }}>
                                                                                            {fichero.NOMBRE_IMAGEN === "pdf.png" || fichero.NOMBRE_IMAGEN === "imagen.png" || fichero.NOMBRE_IMAGEN === "video.png" ?
                                                                                                <EyeOutlined title='Visualizar' style={{ fontSize: '24px', display: 'inline-block', marginRight: '20px' }} onClick={() => {
                                                                                                    visualizador(fichero, true);

                                                                                                }}></EyeOutlined>
                                                                                                :
                                                                                                null}
                                                                                            <CloudDownloadOutlined title='Descargar' style={{ fontSize: '24px', display: 'inline-block' }} onClick={() => { desacargarFichero(fichero, true) }}></CloudDownloadOutlined>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                    :
                                                                    <div style={{ display: 'flex', alignItems: 'center', minHeight: '20vh', margin: '20px auto' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled />  </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </TabPane>
                                    :
                                    null
                            }
                        </Tabs>
                        <div style={{ flexGrow: '6', textAlign: 'right', padding: '20px 25px 20px 20px', display: props.ES_NUEVA_TAREA ? '' : 'none' }} >
                            <button className="activebtnMenu2" style={{ height: '40px', padding: '0px 20px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', cursor: 'pointer' }} onClick={(e) => gestionarTarea()}> {creandoTarea ? <div className="spinner-border  fast colorSubAzul" role="status" disabled style={{ width: '1.4rem', height: '1.4rem', verticalAlign: 'middle' }} /> : <SaveOutlined style={{ fontSize: '20px' }} />}   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Guardar_salir").toUpperCase()} </span> </button>
                        </div>
                    </>
                }
            </Drawer>

            {abrirSeleccionVehiculo ?
                <FichaSeleccionVehiculo
                    title={traducirPagina("Seleccion_vehiculo").toUpperCase()}
                    ABRIR_FICHA={abrirSeleccionVehiculo}
                    CERRAR_FICHA={setAbrirSeleccionVehiculo}
                    DATOS_COCHE={cocheDGT}
                    VEHICULO_SELECCIONADO={cocheSeleccionadoLocal}
                >
                </FichaSeleccionVehiculo>
                :
                null
            }

            {abrirVisor ?
                <VisorGenerico
                    title={""}
                    ABRIR_VISOR={abrirVisor}
                    CERRAR_VISOR={setAbrirVisor}
                    PROPIEDADES_DOC={ficheroSeleccionado}
                    MOSTRAR_BOTON_DESCARGA={false}
                >

                </VisorGenerico>
                :
                null
            }

            {
                abrirFichaTecdoc ?
                    <FichaVehiculoTecdoc
                        title={traducirPagina("Seleccione_marca").toUpperCase()}
                        ABRIR_FICHA={abrirFichaTecdoc}
                        CERRAR_FICHA={setAbrirFichaTecdoc}
                        ACTUALIZAR_VEHICULO={actualizarVehiculoTecdoc}
                    >

                    </FichaVehiculoTecdoc>
                    :
                    null
            }

            {
                abrirFichaDetalleVeh ?
                    <FichaDetalleVehiculo
                        title={traducirPagina("Ficha_vehiculo").toUpperCase()}
                        ABRIR_FICHA={abrirFichaDetalleVeh}
                        CERRAR_FICHA={setAbrirFichaDetalleVeh}
                        COD_MOTOR={payLoadLocal.ID_VEHICULO_TECDOC}
                    >
                    </FichaDetalleVehiculo>
                    :
                    null
            }


            {abrirModalInfoVeh ?
                <Modal
                    visible={abrirModalInfoVeh}
                    style={{ zIndex: "999" }}
                    width={700}
                    centered
                    title={traducirPagina("Informacion_matricula_DGT")}
                    onOk={() => { setAbrirModalInfoVeh(true) }}
                    onCancel={() => { setAbrirModalInfoVeh(false) }}
                    footer={[
                        <Button className="btnColor" key="submit" type="primary" onClick={() => { setAbrirModalInfoVeh(false) }}>{traducirPagina("Salir")} </Button>
                    ]}>

                    {Object.keys(cocheDGT.Matricula).length > 0 ?
                        cocheDGT.Matricula.Matricula !== null ?
                            <Row style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>
                                <Col md={24} className="mb-2">
                                    <h4 style={{ color: '#4285f4' }}>{traducirPagina("msgDGT_no_info_bastidor")}</h4>
                                </Col>

                                <Col md={12}>
                                    <span>{traducirPagina("Bastidor") + ": " + cocheDGT.Matricula.Bastidor}</span>
                                </Col>

                                <Col md={12}>
                                    <span>{traducirPagina("Carburante") + ": " + cocheDGT.Matricula.Carburante}</span>
                                </Col>

                                <Col md={12}>
                                    <span>{traducirPagina("Fecha_de_matriculacion") + ": " + cocheDGT.Matricula.FechaMatricula}</span>
                                </Col>

                                <Col md={12}>
                                    <span>{traducirPagina("Marca") + ": " + cocheDGT.Matricula.Marca}</span>
                                </Col>

                                <Col md={12}>
                                    <span>{traducirPagina("Matricula") + ": " + cocheDGT.Matricula.Matricula}</span>
                                </Col>

                                <Col md={12}>
                                    <span>{traducirPagina("Modelo") + ": " + cocheDGT.Matricula.Modelo}</span>
                                </Col>

                            </Row>
                            : null
                        : null
                    }
                </Modal>
                :
                null
            }
        </>
    )
}

FichaTarea.propTypes = {
    title: PropTypes.string,
    ID_TAREA: PropTypes.number,

    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    REGISTRO_SELECCIONADO: PropTypes.object,
    ES_NUEVA_TAREA: PropTypes.bool,
    ACTUALIZAR_TABLA: PropTypes.func,
    FILTRO_FECHA: PropTypes.object,
    FILTROS: PropTypes.object,
    PAISES: PropTypes.array
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        QUERY_USER: state.QUERY_USER

    };
}

export default connect(mapStateToProps)(FichaTarea);