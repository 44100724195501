import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    PlusOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { Select, Drawer, Checkbox, Tabs } from 'antd';

import store from '../store'
const { Option } = Select;
const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;

const FichaTutorial = ({ props, configApp }) => {
    return (
        <Drawer
            width={configApp.width <= 900 ? '100%' : 600}
            onClose={() => { props.CERRAR_FICHA(false) }}
            closable={false}

            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff' }}>


            <div className="flex-container-Grow-Tercero header_ficha" style={{ height: '56px' }}>
                <div style={{ textAlign: 'inherit' }}>
                    <button className="backgrounNegroClaro soloColorBlanco close_header_ficha" aria-label="Close" style={{ border: 'none', padding: '15px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { props.CERRAR_FICHA(false); }} >
                        <i aria-label="icon: close" className="anticon anticon-close" style={{ verticalAlign: 'text-bottom' }}>
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </button>
                </div>

                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
            </div>


            <div className='continaerVideo' style={{height:'calc(100vh - 65px)'}}>
                <video width={configApp.width <= 900 ? '100%' : 600} controls>
                    <source src={"./ficheros/tutorial_flex.mp4"} />
                </video>
            </div>

        </Drawer>

    )
}

FichaTutorial.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func

};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(FichaTutorial);