import React from "react";
import { useSearchParams } from "react-dom";
import { connect } from 'react-redux';
import { checkuserlogin } from "../action/actionCreators";
import { LoginEnAPI, recuperar_contrasenyaEnAPI } from "../action/actionCreatorsRequests";

import { obtenerIdioma, MSGERROR, MSGOK } from "../librerias/funciones"
import history from '../history';

import { Modal, Button, Input, Row, Col } from 'antd';
import store from "../store";

const MOADAL_ALERTAS_LOIN = (COD_IDIOMA, TEXTO) => {

    Modal.warning({
        centered: true,
        okText: COD_IDIOMA !== undefined && COD_IDIOMA === "FR" ? "SORTIR" : "SALIR",
        okType: "modalAlertas",
        content: (<div className='textoAlertaUsuario'>{TEXTO} </div>),
    });
}

const LOGIN_VOLTAF = (props) => {
    return <div>
        <div className="container" style={{ width: props.DIMENSIONS.width < 600 ? '340px' : '450px' }}>
            <div className="form-container sign-in-container">
                <div className="form">
                    <div  > <img src="./imagenes/logo_voltamper.png" style={{ height: '200px' }} /></div>
                    <input type="text" placeholder="Usuario" autoComplete="new-password" name="USUARIO" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} />
                    <input type="password" placeholder="Contraseña" autoComplete="new-password" name="PWD" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} onKeyPress={(e) => e.which === 13 ? props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, e.target.value) : null} />
                    <a onClick={(e) => { props.CERRAR_MODAL(true) }}>Olvido su contraseña?</a>
                    <button onClick={() => { props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, props.PAYLOAD.PWD); document.body.style.overflow = "hidden"; }} className="btn_Aser">Entrar</button>
                </div>
            </div>
        </div>

        <Modal
            visible={props.ABRIR_MODAL}
            width={600}
            centered
            title={"RECUPERAR MI CONTRASEÑA"}
            closable={false}
            afterClose={() => { props.MODIFICAR_EMAIL_RECUPERACION("") }}
            onCancel={() => props.CERRAR_MODAL(false)}
            style={{ zIndex: '999' }}
            footer={[
                <Button className="btn_modal_login btn_modal_login_salir" key="back" onClick={() => { props.CERRAR_MODAL(false) }}>
                    Salir
                </Button>,
                <Button className="btn_modal_login" key="submit" type="primary" onClick={() => {
                    props.RECUPERAR_PWD(props.EMAIL_RECUPERACION)
                }}> Enviar</Button>
            ]}
        >

            <Row>
                <Col md={24} className="mt-2 mb-2">
                    <span className="tituloRecuperarEmail">Ingrese el email de recuperación </span>
                </Col>
                <Col md={24}>
                    <Input value={props.EMAIL_RECUPERACION} onChange={(e) => props.MODIFICAR_EMAIL_RECUPERACION(e.target.value)} />
                </Col>
            </Row>
        </Modal>


    </div>;
};


const LOGIN_QTALLER = (props) => {
    return <div>
        <div className="container" style={{ width: props.DIMENSIONS.width < 600 ? '340px' : '450px' }}>
            <div className="form-container sign-in-container">
                <div className="form">
                    <div className="containerTitulo" > <img src="./imagenes/logo_qtaller.png" style={{ height: '180px' }} /></div>
                    <input type="text" placeholder="Usuario" autoComplete="new-password" name="USUARIO" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} />
                    <input type="password" placeholder="Contraseña" autoComplete="new-password" name="PWD" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} onKeyPress={(e) => e.which === 13 ? props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, e.target.value) : null} />
                    <a onClick={(e) => { props.CERRAR_MODAL(true) }}>Olvido su contraseña?</a>
                    <button onClick={() => { props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, props.PAYLOAD.PWD); document.body.style.overflow = "hidden"; }} className="btn_taller">Entrar</button>
                </div>
            </div>
        </div>

        <Modal
            visible={props.ABRIR_MODAL}
            width={600}
            centered
            title={"RECUPERAR MI CONTRASEÑA"}
            closable={false}
            afterClose={() => { props.MODIFICAR_EMAIL_RECUPERACION("") }}
            onCancel={() => props.CERRAR_MODAL(false)}
            style={{ zIndex: '999' }}
            footer={[
                <Button className="btn_modal_login btn_modal_login_salir" key="back" onClick={() => { props.CERRAR_MODAL(false) }}>
                    Salir
                </Button>,
                <Button className="btn_modal_login" key="submit" type="primary" onClick={() => {
                    props.RECUPERAR_PWD(props.EMAIL_RECUPERACION)
                }}> Enviar</Button>
            ]}
        >

            <Row>
                <Col md={24} className="mt-2 mb-2">
                    <span className="tituloRecuperarEmail">Ingrese el email de recuperación </span>
                </Col>
                <Col md={24}>
                    <Input value={props.EMAIL_RECUPERACION} onChange={(e) => props.MODIFICAR_EMAIL_RECUPERACION(e.target.value)} />
                </Col>
            </Row>
        </Modal>


    </div>;
};

const LOGIN_ASER = (props) => {
    return <div>
        <div className="container" style={{ width: props.DIMENSIONS.width < 600 ? '340px' : '450px' }}>
            <div className="form-container sign-in-container">
                <div className="form">
                    <div  > <img src="./imagenes/tech.png" style={{ height: '200px' }} /></div>
                    <input type="text" placeholder="Usuario" autoComplete="new-password" name="USUARIO" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} />
                    <input type="password" placeholder="Contraseña" autoComplete="new-password" name="PWD" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} onKeyPress={(e) => e.which === 13 ? props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, e.target.value) : null} />
                    <a onClick={(e) => { props.CERRAR_MODAL(true) }}>Olvido su contraseña?</a>
                    <button onClick={() => { props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, props.PAYLOAD.PWD); document.body.style.overflow = "hidden"; }} className="btn_Aser">Entrar</button>
                </div>
            </div>
        </div>

        <Modal
            visible={props.ABRIR_MODAL}
            width={600}
            centered
            title={"RECUPERAR MI CONTRASEÑA"}
            closable={false}
            afterClose={() => { props.MODIFICAR_EMAIL_RECUPERACION("") }}
            onCancel={() => props.CERRAR_MODAL(false)}
            style={{ zIndex: '999' }}
            footer={[
                <Button className="btn_modal_login btn_modal_login_salir" key="back" onClick={() => { props.CERRAR_MODAL(false) }}>
                    Salir
                </Button>,
                <Button className="btn_modal_login" key="submit" type="primary" onClick={() => {
                    props.RECUPERAR_PWD(props.EMAIL_RECUPERACION)
                }}> Enviar</Button>
            ]}
        >

            <Row>
                <Col md={24} className="mt-2 mb-2">
                    <span className="tituloRecuperarEmail">Ingrese el email de recuperación </span>
                </Col>
                <Col md={24}>
                    <Input value={props.EMAIL_RECUPERACION} onChange={(e) => props.MODIFICAR_EMAIL_RECUPERACION(e.target.value)} />
                </Col>
            </Row>
        </Modal>


    </div>;
};

const FLEX_FUEL = (props) => {
    return <div>
        <div className="container" style={{ width: props.DIMENSIONS.width < 600 ? '340px' : '450px' }}>
            <div className="form-container sign-in-container">
                <div className="form" style={{ backgroundColor: '#1e2330' }}>
                    <div className="containerTitulo"> <img style={{ height: '140px' }} src="./imagenes/logo_flex_fuel_green.svg" /></div>
                    <input type="text" placeholder="Utilisateur" autoComplete="new-password" name="USUARIO" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} />
                    <input type="password" placeholder="Le mot de passe" autoComplete="new-password" name="PWD" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} onKeyPress={(e) => e.which === 13 ? props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, e.target.value, "FR") : null} />
                    <a style={{ color: '#c8d400' }} onClick={(e) => { props.CERRAR_MODAL(true) }}>Vous avez oublié votre mot de passe?</a>
                    <button onClick={() => { props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, props.PAYLOAD.PWD, "FR"); document.body.style.overflow = "hidden"; }} style={{ backgroundColor: '#c8d400', color: '#000', borderRadius: '5px' }}>Entrer</button>
                </div>
            </div>
        </div>

        <Modal
            visible={props.ABRIR_MODAL}
            width={600}
            centered
            title={"RÉCUPÉRER MON MOT DE PASSE"}
            closable={false}
            afterClose={() => { props.MODIFICAR_EMAIL_RECUPERACION("") }}
            onCancel={() => props.CERRAR_MODAL(false)}
            style={{ zIndex: '999' }}
            footer={[
                <Button className="btn_modal_login btn_modal_login_salir" key="back" onClick={() => { props.CERRAR_MODAL(false) }}>
                    Sortir
                </Button>,
                <Button className="btn_modal_login" key="submit" type="primary" onClick={() => {
                    props.RECUPERAR_PWD(props.EMAIL_RECUPERACION, "FR")
                }}> Envoyer</Button>
            ]}
        >

            <Row>
                <Col md={24} className="mt-2 mb-2">
                    <span className="tituloRecuperarEmail">Saisissez l'e-mail de récupération</span>
                </Col>
                <Col md={24}>
                    <Input value={props.EMAIL_RECUPERACION} onChange={(e) => props.MODIFICAR_EMAIL_RECUPERACION(e.target.value)} />
                </Col>
            </Row>
        </Modal>
    </div>;
};


const LOGIN_OPTIMA_CAR = (props) => {
    return <div>
        <div className="container" style={{ width: props.DIMENSIONS.width < 600 ? '340px' : '450px' }}>
            <div className="form-container sign-in-container">
                <div className="form">
                    <div className="containerTitulo" > <img src="./imagenes/logo_optima.png" style={{ height: '120px' }} /></div>
                    <input type="text" placeholder="Usuario" autoComplete="new-password" name="USUARIO" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} />
                    <input type="password" placeholder="Contraseña" autoComplete="new-password" name="PWD" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} onKeyPress={(e) => e.which === 13 ? props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, e.target.value) : null} />
                    <a onClick={(e) => { props.CERRAR_MODAL(true) }}>Olvido su contraseña?</a>
                    <button onClick={() => { props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, props.PAYLOAD.PWD); document.body.style.overflow = "hidden"; }} className="btn_taller">Entrar</button>
                </div>
            </div>
        </div>

        <Modal
            visible={props.ABRIR_MODAL}
            width={600}
            centered
            title={"RECUPERAR MI CONTRASEÑA"}
            closable={false}
            afterClose={() => { props.MODIFICAR_EMAIL_RECUPERACION("") }}
            onCancel={() => props.CERRAR_MODAL(false)}
            style={{ zIndex: '999' }}
            footer={[
                <Button className="btn_modal_login btn_modal_login_salir" key="back" onClick={() => { props.CERRAR_MODAL(false) }}>
                    Salir
                </Button>,
                <Button className="btn_modal_login" key="submit" type="primary" onClick={() => {
                    props.RECUPERAR_PWD(props.EMAIL_RECUPERACION)
                }}> Enviar</Button>
            ]}
        >

            <Row>
                <Col md={24} className="mt-2 mb-2">
                    <span className="tituloRecuperarEmail">Ingrese el email de recuperación </span>
                </Col>
                <Col md={24}>
                    <Input value={props.EMAIL_RECUPERACION} onChange={(e) => props.MODIFICAR_EMAIL_RECUPERACION(e.target.value)} />
                </Col>
            </Row>
        </Modal>


    </div>;
};

const ASSIST_FR = (props) => {
    return <div>
        <div className="container" style={{ width: props.DIMENSIONS.width < 600 ? '340px' : '450px' }}>
            <div className="form-container sign-in-container">
                <div className="form">
                    <div className="containerTitulo"> <img src="./imagenes/logo_login.png" /></div>
                    <input type="text" placeholder="Utilisateur" autoComplete="new-password" name="USUARIO" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} />
                    <input type="password" placeholder="Le mot de passe" autoComplete="new-password" name="PWD" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} onKeyPress={(e) => e.which === 13 ? props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, e.target.value, "FR") : null} />
                    <a onClick={(e) => { props.CERRAR_MODAL(true) }}>Vous avez oublié votre mot de passe?</a>
                    <button onClick={() => { props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, props.PAYLOAD.PWD, "FR"); document.body.style.overflow = "hidden"; }} >Entrer</button>
                </div>
            </div>
        </div>

        <Modal
            visible={props.ABRIR_MODAL}
            width={600}
            centered
            title={"RÉCUPÉRER MON MOT DE PASSE"}
            closable={false}
            afterClose={() => { props.MODIFICAR_EMAIL_RECUPERACION("") }}
            onCancel={() => props.CERRAR_MODAL(false)}
            style={{ zIndex: '999' }}
            footer={[
                <Button className="btn_modal_login btn_modal_login_salir" key="back" onClick={() => { props.CERRAR_MODAL(false) }}>
                    Sortir
                </Button>,
                <Button className="btn_modal_login" key="submit" type="primary" onClick={() => {
                    props.RECUPERAR_PWD(props.EMAIL_RECUPERACION, "FR")
                }}> Envoyer</Button>
            ]}
        >

            <Row>
                <Col md={24} className="mt-2 mb-2">
                    <span className="tituloRecuperarEmail">Saisissez l'e-mail de récupération</span>
                </Col>
                <Col md={24}>
                    <Input value={props.EMAIL_RECUPERACION} onChange={(e) => props.MODIFICAR_EMAIL_RECUPERACION(e.target.value)} />
                </Col>
            </Row>
        </Modal>
    </div>;
};


const LOGIN_DEFAULT = (props) => {
    return <div>
        <div className="container" style={{ width: props.DIMENSIONS.width < 600 ? '340px' : '450px' }}>
            <div className="form-container sign-in-container">
                <div className="form">
                    <div className="containerTitulo"> <img src="./imagenes/logo_login.png" /></div>
                    <input type="text" placeholder="Usuario" autoComplete="new-password" name="USUARIO" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} />
                    <input type="password" placeholder="Contraseña" autoComplete="new-password" name="PWD" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} onKeyPress={(e) => e.which === 13 ? props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, e.target.value) : null} />
                    <a onClick={(e) => { props.CERRAR_MODAL(true) }}>Olvido su contraseña?</a>
                    <button onClick={() => { props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, props.PAYLOAD.PWD); document.body.style.overflow = "hidden"; }}>Entrar</button>
                </div>
            </div>
        </div>

        <Modal
            visible={props.ABRIR_MODAL}
            width={600}
            centered
            title={"RECUPERAR MI CONTRASEÑA"}
            closable={false}
            afterClose={() => { props.MODIFICAR_EMAIL_RECUPERACION("") }}
            onCancel={() => props.CERRAR_MODAL(false)}
            style={{ zIndex: '999' }}
            footer={[
                <Button className="btn_modal_login btn_modal_login_salir" key="back" onClick={() => { props.CERRAR_MODAL(false) }}>
                    Salir
                </Button>,
                <Button className="btn_modal_login" key="submit" type="primary" onClick={() => {
                    props.RECUPERAR_PWD(props.EMAIL_RECUPERACION)
                }}> Enviar</Button>
            ]}
        >

            <Row>
                <Col md={24} className="mt-2 mb-2">
                    <span className="tituloRecuperarEmail">Ingrese el email de recuperación </span>
                </Col>
                <Col md={24}>
                    <Input value={props.EMAIL_RECUPERACION} onChange={(e) => props.MODIFICAR_EMAIL_RECUPERACION(e.target.value)} />
                </Col>
            </Row>
        </Modal>


    </div>;
};



const LOGIN_ANDEL = (props) => {
    return <div>
        <div className="container" style={{ width: props.DIMENSIONS.width < 600 ? '340px' : '450px' }}>
            <div className="form-container sign-in-container">
                <div className="form">
                    <div className="containerTitulo"> <img style={{height:'auto',width:'350px'}} src="./imagenes/logo_andel.png" /></div>
                    <input type="text" placeholder="Usuario" autoComplete="new-password" name="USUARIO" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} />
                    <input type="password" placeholder="Contraseña" autoComplete="new-password" name="PWD" onChange={(e) => props.GUARDAR_DATOS_FORM(e)} onKeyPress={(e) => e.which === 13 ? props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, e.target.value) : null} />
                    <a onClick={(e) => { props.CERRAR_MODAL(true) }}>Olvido su contraseña?</a>
                    <button style={{background:'#ff5000', border:'none'}} onClick={() => { props.VERIFICA_USUARIO(props.PAYLOAD.USUARIO, props.PAYLOAD.PWD); document.body.style.overflow = "hidden"; }}>Entrar</button>
                </div>
            </div>
        </div>

        <Modal
            visible={props.ABRIR_MODAL}
            width={600}
            centered
            title={"RECUPERAR MI CONTRASEÑA"}
            closable={false}
            afterClose={() => { props.MODIFICAR_EMAIL_RECUPERACION("") }}
            onCancel={() => props.CERRAR_MODAL(false)}
            style={{ zIndex: '999' }}
            footer={[
                <Button className="btn_modal_login btn_modal_login_salir" key="back" onClick={() => { props.CERRAR_MODAL(false) }}>
                    Salir
                </Button>,
                <Button className="btn_modal_login" style={{background:'#ff5000'}} key="submit" type="primary" onClick={() => {
                    props.RECUPERAR_PWD(props.EMAIL_RECUPERACION)
                }}> Enviar</Button>
            ]}
        >

            <Row>
                <Col md={24} className="mt-2 mb-2">
                    <span className="tituloRecuperarEmail">Ingrese el email de recuperación </span>
                </Col>
                <Col md={24}>
                    <Input value={props.EMAIL_RECUPERACION} onChange={(e) => props.MODIFICAR_EMAIL_RECUPERACION(e.target.value)} />
                </Col>
            </Row>
        </Modal>


    </div>;
};






const Login = ({ props }) => {
    const [emailRecuperacion, setEmailRecuperacion] = React.useState("");
    const [cargarLogin, setCargarLogin] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const query = new URLSearchParams(props.location.search);
    const [payloadLocal, setPayloadLocal] = React.useState(
        {
            EMAIL: "",
            USUARIO: "",
            PWD: ""
        }
    );

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })



    React.useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
    }, [])



    const guardarEstadoFormulario = (e) => {
        const EMAIL = e.target.name === 'EMAIL' ? e.target.value : payloadLocal.EMAIL;
        const USUARIO = e.target.name === 'USUARIO' ? e.target.value : payloadLocal.USUARIO;
        const PWD = e.target.name === 'PWD' ? e.target.value : payloadLocal.PWD;

        setPayloadLocal(
            {
                EMAIL: EMAIL,
                USUARIO: USUARIO,
                PWD: PWD
            }
        )
    }


    const verificarUsuario = async (USER, PWD, COD_IDIOMA) => {
        if (USER !== "" || PWD !== "") {
            const registro = await LoginEnAPI(USER, PWD);

            if (registro !== undefined) {
                if (registro.OK) {
                    store.dispatch({ type: "SET_SESION_USER", usuario: registro });
                    store.dispatch(
                        {
                            type: "UPDATE_IDIOMA_SERTEC",
                            idiomaActual: obtenerIdioma(registro.USUARIO.CODIGO_IDIOMA)
                        }
                    )
                    const MATRICULA = query.get('matricula');
                    if (MATRICULA !== null) {
                        store.dispatch({ type: "QUERY_USER", QUERY_USER: { MATRICULA: MATRICULA } });
                    }

                    history.push(`${process.env.PUBLIC_URL}/home`);
                } else {
                    if (COD_IDIOMA === "FR") {
                        MOADAL_ALERTAS_LOIN(COD_IDIOMA, "Les identifiants sont incorrects")
                        //MSGERROR("Les identifiants sont incorrects")
                    } else {
                        MOADAL_ALERTAS_LOIN("", "Las credenciales son incorrectas")

                        //MSGERROR("Las credenciales son incorrectas")
                    }
                }
            } else {
                if (COD_IDIOMA === "FR") {
                    MOADAL_ALERTAS_LOIN(COD_IDIOMA, "Problèmes de connexion à l'API")

                    //MSGERROR("Problèmes de connexion à l'API")
                } else {
                    //MSGERROR("Problemas al conectar con la API")
                    MOADAL_ALERTAS_LOIN("", "Problemas al conectar con la API")
                }
            }
        } else {
            if (COD_IDIOMA === "FR") {
                // MSGERROR("Nom d'utilisateur ou mot de passe manquant")
                MOADAL_ALERTAS_LOIN(COD_IDIOMA, "Nom d'utilisateur ou mot de passe manquant")

            } else {
                //MSGERROR("Falta usuario o contraseña")
                MOADAL_ALERTAS_LOIN("", "Falta usuario o contraseña")

            }
        }
    }





    const recuperarPWD = async (EMAIL_RECUP, COD_IDIOMA) => {
        if (EMAIL_RECUP.trim() === "") {
            if (COD_IDIOMA === "FR") {
                //MSGERROR("Saisissez votre e-mail de récupération")
                MOADAL_ALERTAS_LOIN(COD_IDIOMA, "Saisissez votre e-mail de récupération")

            } else {
                //MSGERROR("Ingrese su email de recuparción")
                MOADAL_ALERTAS_LOIN("", "Ingrese su email de recuparción")

            }

        } else {
            const { OK } = await recuperar_contrasenyaEnAPI(EMAIL_RECUP);
            if (OK) {
                if (COD_IDIOMA === "FR") {
                    //MSGOK("E-mail envoyé avec succès");
                    MOADAL_ALERTAS_LOIN(COD_IDIOMA, "E-mail envoyé avec succès")


                } else {
                    //MSGOK("Email enviado correctamente");
                    MOADAL_ALERTAS_LOIN("", "Email enviado correctamente")

                }
                setVisible(false);
            }
        }
    }


    const TIPO_LOGIN = () => {
        let HOST_NAME = window.location.hostname

        switch (HOST_NAME) {
            case "assist.servicenext.eu":
                return (<LOGIN_DEFAULT
                    ABRIR_MODAL={visible}
                    CERRAR_MODAL={setVisible}
                    EMAIL_RECUPERACION={emailRecuperacion}
                    MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                    RECUPERAR_PWD={recuperarPWD}
                    GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                    VERIFICA_USUARIO={verificarUsuario}
                    PAYLOAD={payloadLocal}
                    DIMENSIONS={dimensions}
                ></LOGIN_DEFAULT>)

            case "asistencia.qtaller.com":
                return (<LOGIN_QTALLER
                    ABRIR_MODAL={visible}
                    CERRAR_MODAL={setVisible}
                    EMAIL_RECUPERACION={emailRecuperacion}
                    MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                    RECUPERAR_PWD={recuperarPWD}
                    GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                    VERIFICA_USUARIO={verificarUsuario}
                    PAYLOAD={payloadLocal}
                    DIMENSIONS={dimensions}
                ></LOGIN_QTALLER>)

            case "asertech.aserauto.com":
                return (<LOGIN_ASER
                    ABRIR_MODAL={visible}
                    CERRAR_MODAL={setVisible}
                    EMAIL_RECUPERACION={emailRecuperacion}
                    MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                    RECUPERAR_PWD={recuperarPWD}
                    GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                    VERIFICA_USUARIO={verificarUsuario}
                    PAYLOAD={payloadLocal}
                    DIMENSIONS={dimensions}
                ></LOGIN_ASER>)

            case "supporte85-flexfuel.servicenext.eu":
                return (<FLEX_FUEL
                    ABRIR_MODAL={visible}
                    CERRAR_MODAL={setVisible}
                    EMAIL_RECUPERACION={emailRecuperacion}
                    MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                    RECUPERAR_PWD={recuperarPWD}
                    GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                    VERIFICA_USUARIO={verificarUsuario}
                    PAYLOAD={payloadLocal}
                    DIMENSIONS={dimensions}
                ></FLEX_FUEL>)
            case "optimacar.servicenext.eu":
                return (<LOGIN_OPTIMA_CAR
                    ABRIR_MODAL={visible}
                    CERRAR_MODAL={setVisible}
                    EMAIL_RECUPERACION={emailRecuperacion}
                    MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                    RECUPERAR_PWD={recuperarPWD}
                    GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                    VERIFICA_USUARIO={verificarUsuario}
                    PAYLOAD={payloadLocal}
                    DIMENSIONS={dimensions}
                ></LOGIN_OPTIMA_CAR>)
            case "voltaf.assist.servicenext.eu":
                return (<LOGIN_VOLTAF
                    ABRIR_MODAL={visible}
                    CERRAR_MODAL={setVisible}
                    EMAIL_RECUPERACION={emailRecuperacion}
                    MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                    RECUPERAR_PWD={recuperarPWD}
                    GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                    VERIFICA_USUARIO={verificarUsuario}
                    PAYLOAD={payloadLocal}
                    DIMENSIONS={dimensions}
                ></LOGIN_VOLTAF>)

            case "assistfr.servicenext.eu":
                return (<ASSIST_FR
                    ABRIR_MODAL={visible}
                    CERRAR_MODAL={setVisible}
                    EMAIL_RECUPERACION={emailRecuperacion}
                    MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                    RECUPERAR_PWD={recuperarPWD}
                    GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                    VERIFICA_USUARIO={verificarUsuario}
                    PAYLOAD={payloadLocal}
                    DIMENSIONS={dimensions}
                ></ASSIST_FR>)

                case "asistencia-pt.andelautomocion.com":
                    return (<LOGIN_ANDEL
                        ABRIR_MODAL={visible}
                        CERRAR_MODAL={setVisible}
                        EMAIL_RECUPERACION={emailRecuperacion}
                        MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                        RECUPERAR_PWD={recuperarPWD}
                        GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                        VERIFICA_USUARIO={verificarUsuario}
                        PAYLOAD={payloadLocal}
                        DIMENSIONS={dimensions}
                    ></LOGIN_ANDEL>)

            default:
                return (<LOGIN_DEFAULT
                    ABRIR_MODAL={visible}
                    CERRAR_MODAL={setVisible}
                    EMAIL_RECUPERACION={emailRecuperacion}
                    MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
                    RECUPERAR_PWD={recuperarPWD}
                    GUARDAR_DATOS_FORM={guardarEstadoFormulario}
                    VERIFICA_USUARIO={verificarUsuario}
                    PAYLOAD={payloadLocal}
                    DIMENSIONS={dimensions}
                ></LOGIN_DEFAULT>)

        }
    }

    if (!cargarLogin) {
        setTimeout(() => {
            setCargarLogin(true)
        }, 2000)

        const USER = query.get('user');
        const PWD = query.get('pwd');
        const MATRICULA = query.get('matricula');

        if (USER !== null && PWD !== null) {
            verificarUsuario(USER, PWD);
        }
    }

    return (
        <>
            {!cargarLogin ?
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '100vh' }}> <div className="spinner-border  fast colorSubAzul" role="status" disabled /> </div>
                :
                <>
                    {TIPO_LOGIN()}
                </>
            }

            <link rel="stylesheet" type="text/css" href={"plantillas/estiloLogin.css"} />
        </>
    );
}

Login.propTypes = {
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}

export default connect(mapStateToProps)(Login);
