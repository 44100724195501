import React, { Component } from 'react';

import store from '../store';

import {
    updateStateMasterPage, showItemReferencia,
} from "../action/actionCreators";
import { obtener_vehiculoEnAPI, abortFetch } from "../action/actionCreatorsRequests";
import { Modal, Button, Space } from 'antd';

import alertify from 'alertifyjs';
import history from '../history';
import txt_esp from "../json/esp.json"
import txt_fra from "../json/fra.json"
import txt_por from "../json/por.json"
import txt_ale from "../json/ale.json"
import txt_ita from "../json/ita.json"
import txt_ing from "../json/ing.json"




export function obtenerTipoOrdenacion(listaDeReferencias) {
    //if (listaDeReferencias.find(element => element.DESTACADO !== 0) !== undefined) { return 9; }
    if (listaDeReferencias.find(element => element.CODIGO_MARCA > 0 && element.STOCK_ALMACEN > 0 && element.PRECIO >= 0) !== undefined) { return 9; }
    if (listaDeReferencias.find(element => element.CODIGO_MARCA > 0 && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL > 0 && element.PRECIO >= 0) !== undefined) { return 8; }
    if (listaDeReferencias.find(element => element.CODIGO_MARCA <= 0 && element.STOCK_ALMACEN > 0 && element.PRECIO >= 0) !== undefined) { return 7; }
    if (listaDeReferencias.find(element => element.CODIGO_MARCA <= 0 && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL > 0 && element.PRECIO >= 0) !== undefined) { return 6; }
    if (listaDeReferencias.find(element => element.CODIGO_MARCA > 0 && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO >= 0) !== undefined) { return 5; }
    if (listaDeReferencias.find(element => element.CODIGO_MARCA > 0 && element.REFERENCIA_PROVEEDOR === "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO >= 0) !== undefined) { return 4; }
    if (listaDeReferencias.find(element => element.CODIGO_MARCA <= 0 && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO >= 0) !== undefined) { return 3; }
    if (listaDeReferencias.find(element => element.PRECIO === null) !== undefined) { return 2; }
    return 1;
}

export function obtenerListaOrdenadaPorStock(listaDeReferencias) {

    let lista = listaDeReferencias.sort(dynamicSortMultipleDesc("STOCK_ALMACEN", "NETO_ORDENADO"));

    return lista;
}


export function obtenerStockAlmacen(listaDeReferencias) {
    let stockMaximo = 0;
    let item = listaDeReferencias.sort(dynamicSortDesc("STOCK_ALMACEN"));
    stockMaximo = item[0].STOCK_ALMACEN;
    if (stockMaximo === 0) {
        let item2 = listaDeReferencias.sort(dynamicSortDesc("STOCK_TOTAL"));
        stockMaximo = item2[0].STOCK_TOTAL;
    }
    return stockMaximo;
}

export function obtenerPrecioMinimo(listaDeReferencias) {
    let precioMinimo = 0;
    let item = listaDeReferencias.sort(dynamicSortAsc("NETO"));
    precioMinimo = item[0].NETO * -1;

    return precioMinimo;
}

export function ordenarGruposMarcas(listaDeGrupoDeMarcas) {
    let lista = listaDeGrupoDeMarcas.sort(dynamicSortMultipleDesc("tipoOrdenacion", "stock", "precio"));
    return lista;
}


export function dynamicSortDesc(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}


export function dynamicSortAsc(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function dynamicSortMultipleDesc() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
            result = dynamicSortDesc(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}

export function dynamicSortMultipleAsc() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;

        while (result === 0 && i < numberOfProperties) {
            result = dynamicSortAsc(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}

export function obtenerPaginas(registros, numeroRegistroPorPagina) {
    let numeroDePaginas = 0; let numReferenciasPagina = 0;

    let listaMarcas = registros.marcas;

    if (registros.marcasSeleccionadas !== undefined) {
        if (registros.marcasSeleccionadas.length > 0) {
            listaMarcas = registros.marcasSeleccionadas
        }
    }
    let listaMarcasOrdenadas = [];

    listaMarcas.map((marca) => {
        let listaDeReferenciasDeLaMarca = registros.referencias.filter(referencia => referencia.NOMRE_MARCA === marca);
        if (listaDeReferenciasDeLaMarca.length > 0) {
            let tipoOrdenacionDeLaMarca = obtenerTipoOrdenacion(listaDeReferenciasDeLaMarca);
            let stockMaximoDeLaMarca = obtenerStockAlmacen(listaDeReferenciasDeLaMarca);
            let precioMinimoDeLaMarca = obtenerPrecioMinimo(listaDeReferenciasDeLaMarca);

            listaMarcasOrdenadas.push({
                marca: marca,
                tipoOrdenacion: tipoOrdenacionDeLaMarca,
                stock: stockMaximoDeLaMarca,
                precio: precioMinimoDeLaMarca
            })
        }
    })

    listaMarcasOrdenadas = ordenarGruposMarcas(listaMarcasOrdenadas);

    listaMarcas = [];
    listaMarcasOrdenadas.map((marcaOrdenada) => {
        listaMarcas.push(marcaOrdenada.marca);
    })

    let paginas = []; let marcaIncio = ''; let marcaFin = ''; let inicial = 0;

    for (var i = 0; i < listaMarcas.length; i++) {
        if (inicial === 0) {
            marcaIncio = listaMarcas[i];
            inicial = 1;
        }

        let numeroDeReferenciasEnLaMarca = registros.referencias.filter(registro => registro.NOMRE_MARCA === listaMarcas[i]);

        numReferenciasPagina += numeroDeReferenciasEnLaMarca.length;
        if (i === (listaMarcas.length - 1)) {
            numeroRegistroPorPagina = numReferenciasPagina
        }

        if (numReferenciasPagina >= numeroRegistroPorPagina) {
            marcaFin = listaMarcas[i];

            numeroDePaginas += 1;

            paginas.push({ marcaIncio: marcaIncio, marcaFin: marcaFin });
            inicial = 0;

            numReferenciasPagina = 0;
        } else {

            marcaFin = listaMarcas[i];
        }
    }
    return paginas;
}



export function distinctMarcas(lista) {
    let lookup = {}; let distinctMarcas = [];
    if (lista !== undefined) {
        lista.map((item, pos) => {
            var name = item.NOMRE_MARCA;
            if (!(name in lookup)) {
                lookup[name] = 1;
                distinctMarcas.push(item);
            }
        })

        distinctMarcas = distinctMarcas.sort(dynamicSortAsc("NOMRE_MARCA"));
    }
    return distinctMarcas;
}

export function distinctGrupoMontaje(referencias) {
    let distinctGrupoMontajeLista = [];
    let lookup = {};
    if (referencias !== undefined) {
        referencias.map((item, pos) => {
            if (item.CODIGO_MARCA > 0) {
                var name = item.NOMBRE_GRUPO_MONTAJE;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    distinctGrupoMontajeLista.push(item);
                }
            }
        })
        distinctGrupoMontajeLista = distinctGrupoMontajeLista.sort(dynamicSortAsc("NOMBRE_GRUPO_MONTAJE"));
    }
    return distinctGrupoMontajeLista
}

export function buscarNodoTree(lista) {
    if (lista.children.length > 0) {
        let lista = []
        lista.push(lista.title);

        return buscarNodoTree(lista.children);
    } else {
        return lista.title
    }
}




export function modelarObjetoReferencia(referencias, marcasSeleccionadas, gruposSeleccionadas, ladosSeleccionados) {

    let agrupacionMarcas = [];
    let agrupacionGrupoMontaje = [];
    let grupoDeReferencias;
    let listaReferencias = [];
    referencias.map(referencia => {
        if (referencia.NETO === 0) {
            referencia.NETO_ORDENADO = -999999999;
        } else {
            referencia.NETO_ORDENADO = (referencia.NETO * -1)
        }
        listaReferencias.push(referencia)
    })

    /*+**************+++*MODELAR OBJETO REFERENCIA*******************************/
    agrupacionMarcas = referencias.map(item => item.NOMRE_MARCA)
        .filter((value, index, self) => self.indexOf(value) === index)
    agrupacionMarcas = agrupacionMarcas.sort((a, b) => a.localeCompare(b));

    agrupacionGrupoMontaje = referencias.map(item => item.NOMBRE_GRUPO_MONTAJE)
        .filter((value, index, self) => self.indexOf(value) === index)
    agrupacionGrupoMontaje = agrupacionGrupoMontaje.sort((a, b) => a.localeCompare(b));

    if (marcasSeleccionadas === undefined) { marcasSeleccionadas = []; }
    if (gruposSeleccionadas === undefined) { gruposSeleccionadas = []; }
    if (ladosSeleccionados === undefined) { ladosSeleccionados = []; }

    grupoDeReferencias = {
        marcas: agrupacionMarcas,
        referencias: listaReferencias,
        auxReferencia: listaReferencias,
        grupoMontaje: agrupacionGrupoMontaje,
        gruposMontajeSeleccionados: gruposSeleccionadas,
        ladosSeleccionados: ladosSeleccionados,
        marcasSeleccionadas: marcasSeleccionadas
    }
    return grupoDeReferencias;
}

export function filtrarReferenciasLocalmente(listaReferencias) {
    let marcasSeleccionadas = listaReferencias.marcasSeleccionadas;
    let grupoDeMontajeSeleccionados = listaReferencias.gruposMontajeSeleccionados;
    let ladosSeleccionados = listaReferencias.ladosSeleccionados;
    let listaReferenciasFiltradas = [];
    let objReferenciasFiltradas = listaReferencias;
    if (marcasSeleccionadas.length === 0) {
        listaReferenciasFiltradas = listaReferencias.referencias
    } else {
        listaReferenciasFiltradas = listaReferencias.referencias.filter(referencia => marcasSeleccionadas.includes(referencia.NOMRE_MARCA))
    }

    if (grupoDeMontajeSeleccionados.length === 0) {
        listaReferenciasFiltradas = listaReferenciasFiltradas
    } else {
        listaReferenciasFiltradas = listaReferenciasFiltradas.filter(referencia => grupoDeMontajeSeleccionados.includes(referencia.NOMBRE_GRUPO_MONTAJE))
    }

    if (ladosSeleccionados.length === 0) {
        listaReferenciasFiltradas = listaReferenciasFiltradas
    } else {
        let referencias = [];
        listaReferenciasFiltradas.map(registro => {
            for (let i = 0; i < ladosSeleccionados.length; i++) {
                let n = registro.LADO_MONTAJE.search(ladosSeleccionados[i])
                if (n !== -1) {
                    referencias.push(registro)
                    break;
                }
            }

        })
        listaReferenciasFiltradas = referencias;
    }
    objReferenciasFiltradas.referencias = listaReferenciasFiltradas;
    return objReferenciasFiltradas;
}


export function crearPaginacion(referencias, listaPaginacion) {


    let inicioRegistroPaginación = 10;
    if (Object.keys(listaPaginacion).length > 0) {
        inicioRegistroPaginación = listaPaginacion.numeroRegistroPagina === undefined ? 10 : listaPaginacion.numeroRegistroPagina;
    }

    let listaPaginas = obtenerPaginas(referencias, inicioRegistroPaginación);
    let paginationPropiedades = {
        "numeroTotalRegistro": referencias.referencias.length - 1,
        "numeroTotalRegistroPaginacion": listaPaginas.length * inicioRegistroPaginación,
        "numeroRegistroPagina": inicioRegistroPaginación,
        "numeroDePaginas": listaPaginas.length,
        "paginas": listaPaginas,
        "paginaAMostrar": 0
    }

    return paginationPropiedades;

}

export function obtenerDatosUsuarioYLicenciaActual(activarMensaje) {
    let getCookieUser = JSON.parse(localStorage.getItem("usersertec"));
    let accesoLicencia = {};
    if (getCookieUser !== null) {
        accesoLicencia = getCookieUser.LoginSertec.List_LicenciaSERTE[0];
    } else {
        let verificarMensaje = activarMensaje === undefined ? true : activarMensaje;
        if (verificarMensaje) {
            MSGERROR("LA PETICION NO ESTA LOGEADA");
        }
    }

    return accesoLicencia;
}


export function verficarSiEsCatalogo(usuario) {
    let esCatalogo = true;
    if (usuario.LoginSertec !== undefined) {
        esCatalogo = usuario.LoginSertec.List_LicenciaSERTE[0].SOLO_CATALOGO;
    }
    return esCatalogo
}

export function colorCabeceraPorElPrecioDeLaReferencia(marcas) {
    let colorPorDefecto = "#999"
    if (marcas.referencias !== undefined) {
        for (let i = 0; i < marcas.referencias.length; i++) {
            if (marcas.referencias[i].PRECIO > 0) {
                colorPorDefecto = "#1f1e1e"
                break;
            }
        }
    }

    for (let i = 0; i < marcas.referencias.length; i++) {
        if (marcas.referencias[i].MARCA_PATROCINADA) {
            colorPorDefecto = "#1b3867"
            break;
        }
    }

    return colorPorDefecto;
}

export function colorCabeceraFuente(marcas) {
    let colorPorDefecto = "#fff";

    for (let i = 0; i < marcas.referencias.length; i++) {
        if (marcas.referencias[i].MARCA_PATROCINADA) {
            colorPorDefecto = "#e4ba1c"
            break;
        }
    }
    return colorPorDefecto;
}


export function obtenerPathNodo(nodos) {
    let retornoNodos = "";
    nodos.map((nodo) => {

        retornoNodos = retornoNodos + nodo.Term + " " + " / ";
    })
    retornoNodos = retornoNodos.substring(0, retornoNodos.length - 2);
    return retornoNodos
}



export function diaPorNumero(numero) {
    let dia = ""
    switch (numero) {
        case 1:
            dia = "LUNES";
            break;
        case 2:
            dia = "MARTES";
            break;
        case 3:
            dia = "MIERCOLES";
            break;
        case 4:
            dia = "JUEVES";
            break;
        case 5:
            dia = "VIERNES";
            break;
        case 6:
            dia = "SABADO";
            break;
        case 7:
            dia = "DOMINGO";
            break;
        default:

            dia = "LUNES";
            break;
    }
    return dia;
}

export function convertirCodigoAMeses(CODIGO) {
    let MES = ""
    switch (CODIGO) {
        case "01":
            MES = "Enero";
            break;
        case "02":
            MES = "Febrero";
            break;
        case "03":
            MES = "Marzo";
            break;
        case "04":
            MES = "Abril";
            break;
        case "05":
            MES = "Mayo";
            break;
        case "06":
            MES = "Junio";
            break;
        case "07":
            MES = "Julio";
            break;
        case "08":
            MES = "Agosto";
            break;
        case "09":
            MES = "Septiembre";
            break;
        case "10":
            MES = "Octubre";
            break;
        case "11":
            MES = "Noviembre";
            break;
        case "12":
            MES = "Diciembre";
            break;
        default:
            MES = "Enero";
            break;
    }
    return MES;
}
export function convertirAMesACodigo(CODIGO) {
    let MES = ""
    switch (CODIGO.toUpperCase()) {
        case "ENERO":
            MES = "01";
            break;
        case "FEBRERO":
            MES = "02";
            break;
        case "MARZO":
            MES = "03";
            break;
        case "ABRIL":
            MES = "04";
            break;
        case "MAYO":
            MES = "05";
            break;
        case "JUNIO":
            MES = "06";
            break;
        case "JULIO":
            MES = "07";
            break;
        case "AGOSTO":
            MES = "08";
            break;
        case "SEPTIEMBRE":
            MES = "09";
            break;
        case "OCTUBRE":
            MES = "10";
            break;
        case "NOVIEMBRE":
            MES = "11";
            break;
        case "DICIEMBRE":
            MES = "12";
            break;
        default:
            MES = "01";
            break;
    }
    return MES;
}



export function mostrarPaginaPorFiltro(aplicarFiltroPor) {
    let listaMarca = store.getState().grupoDeMarcas;
    let listaPaginacion = store.getState().pagination;
    let agrupacionMarcas = []; let listaDeReferencias = [];
    let listaDeGrupoMontaje = distinctGrupoMontaje(listaMarca.referencias)
    let posicionPagina = 0;
    let listaMarcaFiltro = listaPaginacion.tipoFiltroPorMarca;


    if (listaMarca.referencias !== undefined) {
        if (aplicarFiltroPor === "PorMarcas") {
            //words.filter(word => word.length > 6);
            for (var i = 0; i < listaMarcaFiltro.length; i++) {
                listaDeReferencias.push(listaMarca.referencias.filter(referencia => referencia.NOMRE_MARCA === listaMarcaFiltro[i]));
            }
        }
    }

}





export function mostrarPaginaActual(registros, pagination) {
    let marcas = [];
    let continuar = false;
    let posicionPagina = pagination.paginaAMostrar === undefined ? 0 : pagination.paginaAMostrar === -1 ? 0 : pagination.paginaAMostrar;

    let listaMarcas = registros.marcas;

    if (registros.marcasSeleccionadas !== undefined) {
        if (registros.marcasSeleccionadas.length > 0) {
            listaMarcas = registros.marcasSeleccionadas
        }
    }


    let listaMarcasOrdenadas = [];

    listaMarcas.map((marca) => {
        let listaDeReferenciasDeLaMarca = registros.referencias.filter(referencia => referencia.NOMRE_MARCA === marca);
        if (listaDeReferenciasDeLaMarca.length > 0) {
            let tipoOrdenacionDeLaMarca = obtenerTipoOrdenacion(listaDeReferenciasDeLaMarca);
            let stockMaximoDeLaMarca = obtenerStockAlmacen(listaDeReferenciasDeLaMarca);
            let precioMinimoDeLaMarca = obtenerPrecioMinimo(listaDeReferenciasDeLaMarca);

            listaMarcasOrdenadas.push({
                marca: marca,
                tipoOrdenacion: tipoOrdenacionDeLaMarca,
                stock: stockMaximoDeLaMarca,
                precio: precioMinimoDeLaMarca
            })
        }
    })
    listaMarcasOrdenadas = ordenarGruposMarcas(listaMarcasOrdenadas);

    listaMarcas = [];
    listaMarcasOrdenadas.map((marcaOrdenada) => {
        listaMarcas.push(marcaOrdenada.marca);
    })
    //let listaMarcaFiltro = pagination.tipoFiltroPorMarca;
    if (pagination.paginas !== undefined) {
        if (pagination.paginas.length > posicionPagina) {
            for (var i = 0; i < listaMarcas.length; i++) {
                if (pagination.paginas[posicionPagina].marcaIncio === listaMarcas[i] || continuar) {
                    continuar = true;
                    marcas.push(listaMarcas[i]);
                    if (pagination.paginas[posicionPagina].marcaFin === listaMarcas[i]) {
                        break;
                    }
                }
            }

        }
    }

    return marcas;
}


export function mostarReferenciasGenericasEnPantalla(referenciasEncontradas, marcaSeleccionada, grupoSeleccionado, ladosSeleccionados) {
    let registros = [];
    let nuevoObjetoRegistro = {};
    //store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }))
    if (referenciasEncontradas !== null) {
        //Inicializo las referencias con su propiedad detalles a null
        referenciasEncontradas.map((x, pos) => { x.detalles = {} })

        let objReferencias = modelarObjetoReferencia(referenciasEncontradas, marcaSeleccionada, grupoSeleccionado, ladosSeleccionados);
        //Filtramos marcas y grupos de montaje
        objReferencias = filtrarReferenciasLocalmente(objReferencias)

        //Actualizo el estado de las referencias.
        registros = objReferencias

        //Definimos cada marca en cada página va
        let pagination = crearPaginacion(objReferencias, {})

        nuevoObjetoRegistro = { Pagination: pagination, Referencias: registros }

    }
    return nuevoObjetoRegistro
}





export function obtenerToken() {
    let data = store.getState().usuario;
    return data.TOKEN;
}

export function obtenerUsuario() {
    let getCookieUser = JSON.parse(localStorage.getItem("userbackoffice"));
    return getCookieUser;
}


export function efectoRemoveBuscadorReferencia(id, estado) {
    /*
    if (document.getElementById(id).value !== null) {
        document.getElementById(id).value = "";
    }

    if (document.getElementById("closeBuscadorReferencia") !== null) {
        document.getElementById("closeBuscadorReferencia").style.visibility = estado ? 'hidden' : 'visible';
    }
    */
}

export function efectoRemoveBuscador(id, estado, nombreClose) {
    if (document.getElementById(id).value !== null) {
        document.getElementById(id).value = "";
    }

    if (document.getElementById(nombreClose) !== null) {
        document.getElementById(nombreClose).style.visibility = estado ? 'hidden' : 'visible';
    }
}

export function getFocus(id) {
    setTimeout(() => {
        if (document.getElementById(id) !== null) {
            document.getElementById(id).focus();
        }
    }, 500)
}

export function removeAllClassCoche() {
    let x = document.getElementById("svg");
    let y = x.getElementsByTagName("polygon");

    for (let i = 0; i < y.length; i++) {
        y[i].classList.remove("activeArcauto");
    }
}


export function addClassCoche(e) {
    let x = document.getElementById("svg");
    let y = x.getElementsByTagName("polygon");

    for (let i = 0; i < y.length; i++) {
        if (y[i].attributes[0].nodeValue === e.target.attributes[0].nodeValue) {

            y[i].classList.add("activeArcauto");
        }
    }
}


export function removeClassCoche(e) {
    let x = document.getElementById("svg");
    let y = x.getElementsByTagName("polygon");

    for (let i = 0; i < y.length; i++) {
        if (y[i].attributes[0].nodeValue === e.target.attributes[0].nodeValue) {
            y[i].classList.remove("activeArcauto");
        }
    }
}


export function warningCliente(texto) {
    alertify.set('notifier', 'position', 'top-left');
    alertify.warning(texto);

    setTimeout(() => {
        //localStorage.removeItem('usersertec');
        //localStorage.removeItem('tokensertec');
        //store.dispatch(showChildrenDrawerLevel2(false));
        history.push("/");
        store.dispatch(updateStateMasterPage(false));
        //store.dispatch(logOutSesion())

    }, 1000)
}

export function MSGERROR(texto, posicion) {
    switch (texto) {
        case "LA PETICION NO ESTA LOGEADA":
            warningCliente(texto)
            break;
        case "NO SE HA INDICADO EL TOKEN":
            warningCliente(texto)
            break;

        case "LA SESSION NO EXISTE":
            abortFetch();
            warningCliente(texto)
            break;
        default:
            if (texto !== undefined && texto !== null) {
                if (texto.trim() !== "") {

                    Modal.warning({
                        centered: true,
                        okText: traducirPagina("Salir").toUpperCase(),
                        okType: history.location.pathname === "/" ? "modalAlertas" : "",
                        content: (<div className='textoAlertaUsuario'>{texto} </div>),
                    });

                    /*
                    alertify.set('notifier', 'position', posicion === undefined ? 'top-left' : posicion);
                    alertify.warning(texto);
                    */
                }
            }
            break;
    }
}
export function MSGOK(texto, posicion) {
    switch (texto) {
        case "":
            break;
        default:
            if (texto !== undefined) {
                if (texto.trim() !== "") {

                    Modal.success({
                        centered: true,
                        okText: traducirPagina("Salir").toUpperCase(),
                        okType: history.location.pathname === "/" ? "modalAlertas" : "",
                        content: (<div className='textoAlertaUsuario'>{texto} </div>),
                    });

                    /*
                    alertify.set('notifier', 'position', posicion === undefined ? 'top-left' : posicion);
                    alertify.success(texto);
                    */
                }
            }
            break;
    }
}

export function estadoMasterPage() {
    if (history.location.pathname === "/") {
        store.dispatch(updateStateMasterPage(false));

    } else {
        store.dispatch(updateStateMasterPage(true));
    }
}


export function crearListaKeysTree(nodoIDOrigen) {
    let listaArbol = store.getState().listaTree;
    if (listaArbol.length > 0) {
        let buscarNodo = listaArbol.find(element => element.key === nodoIDOrigen);
        if (buscarNodo !== undefined) {
            return buscadorNodo(buscarNodo)
        }
    }
}

export function buscadorNodo(lista) {
    if (lista.children === 0) {
        return "";
    } else {
        let almacenar = "";
        lista.children.map((item) => {
            almacenar += item.key + ";"
            item.children.map((item2) => {
                almacenar += item2.key + ";"
                item2.children.map((item3) => {
                    almacenar += item3.key + ";"
                    item3.children.map((item4) => {
                        almacenar += item4.key + ";"
                    })
                })
            })
        })
        return almacenar.length > 0 ? almacenar.slice(0, -1) : almacenar;
    }
}

export function busquedaNodoRecursivo(lista) {
    let almacenar = "";
    lista.children.map((item) => {
        almacenar += item.key + ";"
        if (item.children > 0) {
            almacenar += busquedaNodoRecursivo(item);
        }
    })
    return almacenar;

}

export function busquedaCochePorMatriculaOBastiidor(esFichaDGT) {
    let tipoBusqueda = store.getState().nameDropDownCar;
    let obtenerBuscadores = store.getState().buscadorCoche;

    if (Object.keys(obtenerBuscadores).length > 0) {
        if (obtenerBuscadores.txtBuscadorCoche !== undefined) {
            obtenerBuscadores.txtBuscadorCoche = obtenerBuscadores.txtBuscadorCoche.replaceAll(" ", "");
            obtenerBuscadores.txtBuscadorCoche = obtenerBuscadores.txtBuscadorCoche.replaceAll("-", "");

            if (verificarMatricula(obtenerBuscadores.txtBuscadorCoche)) {
                if (obtenerBuscadores.txtBuscadorCoche !== "") {
                    if (tipoBusqueda.toUpperCase() === traducirPagina("Matricula").toUpperCase()) {
                        /*
                        buscarMatriculaCoche(obtenerBuscadores.txtBuscadorCoche).then(coche => {
                            if (coche !== null) {
                                if (coche.VEHICULOS_TECDOC.length === 0 && coche.MATRICULA !== null) {
                                    store.dispatch({ type: "STATE_MODAL_VEHICULO", abrirModalVehiculo: true })
                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                                } else {
                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                                    gestionarBusquedasVehiculo(coche, esFichaDGT);
                                }
                            }
                        })
                        */
                    } else if (tipoBusqueda.toUpperCase() === traducirPagina("Bastidor").toUpperCase()) {
                        /*
                        buscarBastidorCoche(obtenerBuscadores.txtBuscadorCoche).then(coche => {
                            if (coche !== null) {
                                if (coche.VEHICULOS_TECDOC.length === 0 && coche.MATRICULA !== null) {
                                    store.dispatch({ type: "STATE_MODAL_VEHICULO", abrirModalVehiculo: true })

                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                                } else {
                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                                    gestionarBusquedasVehiculo(coche, esFichaDGT);
                                }
                            }
                        })
                        */
                    }
                } else {
                    MSGERROR("Ingrese un vehículo");
                }

            } else {
                MSGERROR("El formato introducido no es correcto, sólo se aceptan números y letras.");
            }
        }
    } else {
        MSGERROR("Ingrese un vehículo");
    }
}


/*
export function gestionarBusquedasVehiculo(datos, esFichaDGT) {

    let vehiculo = datos.VEHICULOS_TECDOC === null ? [] : datos.VEHICULOS_TECDOC;

    if (vehiculo.length > 0) {
        if (vehiculo.length > 1) {
            store.dispatch(showChildrenDrawerIntegraciones(true));
            store.dispatch(activeItemTipoIntegracion("busquedaVehiculo"))
        } else {
            if (esFichaDGT) {
                store.dispatch({
                    type: "SET_ID_MOTORIZACION_FICHA_HISTORIAL",
                    codMotorizacionFichaHistorial: vehiculo[0].ID,
                })

            } else {
                store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                store.dispatch(showChildrenDrawerLevel2(false));
                // store.dispatch(getIdTecdocMotorByIdHaynes(vehiculo[0].ID));
                historialGrupoMontaje(vehiculo[0]);

                gestionarContenedorCoche(true);
                store.dispatch(updateVisualizacionFiltro(true));
                store.dispatch(showChildrenDrawerIntegraciones(false));
                store.dispatch(showItemReferencia("tree"));
            }
        }
    } else {
        MSGERROR("No hay datos en VEHICULOS_TECDOC");
    }
}
*/



export function cambiarEstadoDropVehiculo() {
    setTimeout(() => {
        let listaCodigosBandera = ["ESP", "POR", "FRA", "MAT", "BAS"];
        listaCodigosBandera.map((i) => {
            let etiqueta = document.getElementById(i)
            if (etiqueta !== undefined && etiqueta !== null) {
                etiqueta.classList.remove("active");
            }
        })

        let tagPrincipal = document.getElementById("paisVehiculo").title;
        let tagBusquedaVeh = document.getElementById("busquedaVehiculo").title;
        let find = listaCodigosBandera.find(element => tagPrincipal.includes(element))

        let findVeh = listaCodigosBandera.find(element => tagBusquedaVeh.includes(element))
        if (findVeh !== undefined) {
            if (document.getElementById(findVeh) !== undefined && document.getElementById(findVeh) !== null) {
                document.getElementById(findVeh).classList.add("active")
            }
        }
        if (find !== undefined) {
            if (document.getElementById(find) !== undefined && document.getElementById(find) !== null) {
                document.getElementById(find).classList.add("active")
            }
        }
    }, 100)
}




export function limpiarStoreDeRedux() {

}



export function limpiarFichaClienteSeleccionado() {
    store.dispatch({
        type: "STATE_FICHA_CLIENTE_SELECCIONADO",
        nombre: "",
        codigo: "",
        cif: "",
        registroCliente: {}
    })
}


export function traducirPagina(id) {
    let idioma = store.getState().idiomaActual;
    let retorno = {};
    if (idioma !== undefined) {
        if (Object.keys(idioma).length > 0) {
            switch (idioma.IDIOMA) {
                case "ESP":
                    retorno = txt_esp.textos.find((r) => r.id === id)
                    break;
                case "POR":
                    retorno = txt_por.textos.find((r) => r.id === id)
                    break;
                case "FRA":
                    retorno = txt_fra.textos.find((r) => r.id === id)
                    break;
                case "ALE":
                    retorno = txt_ale.textos.find((r) => r.id === id)
                    break;
                case "ITA":
                    retorno = txt_ita.textos.find((r) => r.id === id)
                    break;
                case "REN":
                    retorno = txt_ing.textos.find((r) => r.id === id)
                    break;
                default:
                    retorno = txt_esp.textos.find((r) => r.id === id)
                    break;
            }
        } else {
            retorno = txt_esp.textos.find((r) => r.id === id)
        }
    }

    return retorno === undefined ? "" : retorno.value;
}



export function buscarPrecioRefOE(lista) {
    let crearListaREF = []
    lista.map((item, indice) => {
        crearListaREF.push({ PartNo: item.REFERENCIA_ORIGINAL, EuconId: item.EUCON_ID, NOM_FABRICANTE: item.NOMBRE_FABRICANTE })
    })

    // getPricePageOEM(crearListaREF);
}



export function formatoPrecio(numero) {
    let input = numero.toString();
    let resultado = input;

    //aquí elimino todo lo que no sea números o comas (,)
    let num = input.replace(/\,/g, '');
    if (!isNaN(num)) {
        //convierto a string
        num = num.toString();
        //separo los caracteres del string
        num = num.split('');
        //invierto el orden del string
        num = num.reverse();
        //junto todos los caracteres de la cadena
        num = num.join('');
        //busco los dos primeros caracteres y le coloco una coma en la siguiente posición
        num = num.replace(/(?=\d*\.?)(\d{2})/, '$1,');
        //invierto del contenido de la cadena y reemplazo todo lo que no sea números o comas
        num = num.split('').reverse().join('').replace(/^[\,]/, '');
        //asigno la cadena formateada al input
        resultado = num;
    }
    return resultado
}

export function calcularNeto(precio, descuento) {
    let resultado = Math.floor(precio * descuento) / 100;
    return resultado;
}



export function convertirMoneda(moneda, simbolo) {
    if (moneda === "" || moneda === " ") {
        moneda = 0;
    }

    let convertirMonedaAString = moneda.toString();
    convertirMonedaAString = convertirMonedaAString.replace(",", ".")

    let buscarPunto = convertirMonedaAString.indexOf(".");


    if (buscarPunto === -1) {
        convertirMonedaAString = convertirMonedaAString + ".00";
        buscarPunto = convertirMonedaAString.indexOf(".");
    }

    let obtenerDecimales = convertirMonedaAString.substring(buscarPunto + 1, convertirMonedaAString.length);

    if (obtenerDecimales.length < 2) {
        obtenerDecimales = obtenerDecimales + "0";
        convertirMonedaAString = convertirMonedaAString + "0";
    }

    let obtenerEntero = convertirMonedaAString.substring(0, buscarPunto);
    if (obtenerEntero.length > 3) {
        obtenerEntero = obtenerEntero.substring(0, obtenerEntero.length - 3) + "#" + obtenerEntero.substring(obtenerEntero.length - 3, obtenerEntero.length);

        convertirMonedaAString = obtenerEntero + "." + obtenerDecimales;
    }
    let obtenerMoneda = ""
    if (convertirMonedaAString.includes("#")) {
        obtenerMoneda = convertirMonedaAString.substring(0, buscarPunto + 4);
    } else {
        obtenerMoneda = convertirMonedaAString.substring(0, buscarPunto + 3);
    }


    let ponerComaALaMoneda = obtenerMoneda.replace(".", ",");
    ponerComaALaMoneda = ponerComaALaMoneda.replace("#", ".");




    if (simbolo !== undefined && simbolo !== "") {
        ponerComaALaMoneda = ponerComaALaMoneda + " " + simbolo;
    }


    let buscarComa = ponerComaALaMoneda.indexOf(",");
    ponerComaALaMoneda = ponerComaALaMoneda.substr(0, buscarComa);

    return ponerComaALaMoneda;
}


export function calcularImporteBruto(precio, cantidad) {
    let resultado = 0;

    let operacion = cantidad * precio;

    operacion = redondeo(operacion, 2);
    resultado = operacion

    return resultado
}

export function calculoLineaFacturacion(cantidad, precio, DTO, iva) {
    let objetoLineaFacturacion = {
        cantidad: 0,
        precio: 0,
        descuento: 0,
        iva: 0,
        importeBruto: 0,
        importeDescuento: 0,
        baseImp: 0,
        impuesto: 0,
        total: 0
    }

    let resultadoImporteBruto = 0;
    let resultadoDescuento = 0;
    let resultadoBaseImp = 0;
    let resultadoImpuesto = 0;
    let resultadoTotal = 0;


    cantidad = cantidad === "" ? "0" : cantidad.toString();
    precio = precio === "" ? "0" : precio.toString();
    DTO = DTO === "" ? "0" : DTO.toString();
    iva = iva === "" ? "0" : iva.toString();



    cantidad = cantidad.replace(",", ".");
    precio = precio.replace(",", ".");
    DTO = DTO.replace(",", ".");
    iva = iva.replace(",", ".");

    cantidad = parseFloat(cantidad);
    precio = parseFloat(precio);
    DTO = parseFloat(DTO);
    iva = parseFloat(iva);

    resultadoImporteBruto = calcularImporteBruto(precio, cantidad);

    resultadoDescuento = calcularImporteDescuento(resultadoImporteBruto, DTO);
    resultadoBaseImp = calcularBaseImp(resultadoImporteBruto, resultadoDescuento);
    resultadoImpuesto = calcularImporteImpuesto(resultadoBaseImp, iva)
    resultadoTotal = resultadoBaseImp + resultadoImpuesto;

    resultadoImporteBruto = redondeo(resultadoImporteBruto, 2);
    resultadoDescuento = redondeo(resultadoDescuento, 2);
    resultadoBaseImp = redondeo(resultadoBaseImp, 2);
    resultadoImpuesto = redondeo(resultadoImpuesto, 2);
    resultadoTotal = redondeo(resultadoTotal, 2);


    objetoLineaFacturacion.cantidad = cantidad;
    objetoLineaFacturacion.precio = precio;
    objetoLineaFacturacion.descuento = DTO;
    objetoLineaFacturacion.iva = iva;
    objetoLineaFacturacion.importeBruto = resultadoImporteBruto;
    objetoLineaFacturacion.importeDescuento = resultadoDescuento;
    objetoLineaFacturacion.baseImp = resultadoBaseImp;
    objetoLineaFacturacion.impuesto = resultadoImpuesto;
    objetoLineaFacturacion.total = resultadoTotal;


    return objetoLineaFacturacion;
}

export function asignarImportes(props, resultTotales) {
    props.payLoadLocal.VALOR_IVA_1 = resultTotales.iva_1
    props.payLoadLocal.VALOR_IVA_2 = resultTotales.iva_2
    props.payLoadLocal.VALOR_IVA_3 = resultTotales.iva_3
    props.payLoadLocal.IMPORTE_BRUTO_1 = resultTotales.importeBruto_1
    props.payLoadLocal.IMPORTE_BRUTO_2 = resultTotales.importeBruto_2
    props.payLoadLocal.IMPORTE_BRUTO_3 = resultTotales.importeBruto_3
    props.payLoadLocal.IMPORTE_BRUTO = resultTotales.importeBruto
    props.payLoadLocal.IMPORTE_DESCUENTOS_1 = resultTotales.importeDescuento_1
    props.payLoadLocal.IMPORTE_DESCUENTOS_2 = resultTotales.importeDescuento_2
    props.payLoadLocal.IMPORTE_DESCUENTOS_3 = resultTotales.importeDescuento_3
    props.payLoadLocal.IMPORTE_DESCUENTOS = resultTotales.importeDescuento
    props.payLoadLocal.IMPORTE_BASE_IMPONIBLE_1 = resultTotales.baseImp_1
    props.payLoadLocal.IMPORTE_BASE_IMPONIBLE_2 = resultTotales.baseImp_2
    props.payLoadLocal.IMPORTE_BASE_IMPONIBLE_3 = resultTotales.baseImp_3
    props.payLoadLocal.IMPORTE_BASE_IMPONIBLE = resultTotales.baseImp
    props.payLoadLocal.IMPORTE_IMPUESTOS_1 = resultTotales.impuesto_1
    props.payLoadLocal.IMPORTE_IMPUESTOS_2 = resultTotales.impuesto_2
    props.payLoadLocal.IMPORTE_IMPUESTOS_3 = resultTotales.impuesto_3
    props.payLoadLocal.IMPORTE_IMPUESTOS = resultTotales.impuesto
    props.payLoadLocal.IMPORTE_TOTAL_1 = resultTotales.total_1
    props.payLoadLocal.IMPORTE_TOTAL_2 = resultTotales.total_2
    props.payLoadLocal.IMPORTE_TOTAL_3 = resultTotales.total_3
    props.payLoadLocal.IMPORTE_TOTAL = resultTotales.total

    return props.payLoadLocal
}


export function calculoTotalesPresupuesto(lineasManoObra, lineasArticulos) {
    let objetoTotalFacturacion = {
        iva_1: "",
        iva_2: "",
        iva_3: "",
        importeBruto_1: 0,
        importeBruto_2: 0,
        importeBruto_3: 0,
        importeBruto: 0,
        importeDescuento_1: 0,
        importeDescuento_2: 0,
        importeDescuento_3: 0,
        importeDescuento: 0,
        baseImp_1: 0,
        baseImp_2: 0,
        baseImp_3: 0,
        baseImp: 0,
        impuesto_1: 0,
        impuesto_2: 0,
        impuesto_3: 0,
        impuesto: 0,
        total_1: 0,
        total_2: 0,
        total_3: 0,
        total: 0
    }


    let totalLinea = lineasManoObra.concat(lineasArticulos);
    let result = [];
    totalLinea.reduce(function (res, value) {
        if (!res[value.IVA_VALOR]) {
            res[value.IVA_VALOR] = {
                IVA_VALOR: value.IVA_VALOR, IMPORTE_BRUTO: 0, IMPORTE_DESCUENTOS: 0,
                IMPORTE_BASE_IMPONIBLE: 0, IMPORTE_IMPUESTOS: 0, IMPORTE_TOTAL: 0
            };
            result.push(res[value.IVA_VALOR])
        }
        res[value.IVA_VALOR].IMPORTE_BRUTO += value.IMPORTE_BRUTO;
        res[value.IVA_VALOR].IMPORTE_DESCUENTOS += value.IMPORTE_DESCUENTOS;
        res[value.IVA_VALOR].IMPORTE_BASE_IMPONIBLE += value.IMPORTE_BASE_IMPONIBLE;
        res[value.IVA_VALOR].IMPORTE_IMPUESTOS += value.IMPORTE_IMPUESTOS;
        res[value.IVA_VALOR].IMPORTE_TOTAL += value.IMPORTE_TOTAL;

        return res;
    }, {});

    if (result.length > 0) {
        objetoTotalFacturacion.iva_1 = result[0].IVA_VALOR
        objetoTotalFacturacion.importeBruto_1 = result[0].IMPORTE_BRUTO
        objetoTotalFacturacion.importeDescuento_1 = result[0].IMPORTE_DESCUENTOS
        objetoTotalFacturacion.baseImp_1 = result[0].IMPORTE_BASE_IMPONIBLE
        objetoTotalFacturacion.impuesto_1 = result[0].IMPORTE_IMPUESTOS
        objetoTotalFacturacion.total_1 = result[0].IMPORTE_TOTAL
    } else {

        objetoTotalFacturacion.iva_1 = ""
        objetoTotalFacturacion.importeBruto_1 = ""
        objetoTotalFacturacion.importeDescuento_1 = ""
        objetoTotalFacturacion.baseImp_1 = ""
        objetoTotalFacturacion.impuesto_1 = ""
        objetoTotalFacturacion.total_1 = ""
    }

    if (result.length > 1) {
        objetoTotalFacturacion.iva_2 = result[1].IVA_VALOR
        objetoTotalFacturacion.importeBruto_2 = result[1].IMPORTE_BRUTO
        objetoTotalFacturacion.importeDescuento_2 = result[1].IMPORTE_DESCUENTOS
        objetoTotalFacturacion.baseImp_2 = result[1].IMPORTE_BASE_IMPONIBLE
        objetoTotalFacturacion.impuesto_2 = result[1].IMPORTE_IMPUESTOS
        objetoTotalFacturacion.total_2 = result[1].IMPORTE_TOTAL
    } else {
        objetoTotalFacturacion.iva_2 = ""
        objetoTotalFacturacion.importeBruto_2 = ""
        objetoTotalFacturacion.importeDescuento_2 = ""
        objetoTotalFacturacion.baseImp_2 = ""
        objetoTotalFacturacion.impuesto_2 = ""
        objetoTotalFacturacion.total_2 = ""
    }

    if (result.length > 2) {
        objetoTotalFacturacion.iva_3 = result[2].IVA_VALOR
        objetoTotalFacturacion.importeBruto_3 = result[2].IMPORTE_BRUTO
        objetoTotalFacturacion.importeDescuento_3 = result[2].IMPORTE_DESCUENTOS
        objetoTotalFacturacion.baseImp_3 = result[2].IMPORTE_BASE_IMPONIBLE
        objetoTotalFacturacion.impuesto_3 = result[2].IMPORTE_IMPUESTOS
        objetoTotalFacturacion.total_3 = result[2].IMPORTE_TOTAL
    } else {
        objetoTotalFacturacion.iva_3 = ""
        objetoTotalFacturacion.importeBruto_3 = ""
        objetoTotalFacturacion.importeDescuento_3 = ""
        objetoTotalFacturacion.baseImp_3 = ""
        objetoTotalFacturacion.impuesto_3 = ""
        objetoTotalFacturacion.total_3 = ""
    }

    if (result.length > 0) {
        objetoTotalFacturacion.importeBruto = objetoTotalFacturacion.importeBruto + objetoTotalFacturacion.importeBruto_1
        objetoTotalFacturacion.importeDescuento = objetoTotalFacturacion.importeDescuento + objetoTotalFacturacion.importeDescuento_1
        objetoTotalFacturacion.baseImp = objetoTotalFacturacion.baseImp + objetoTotalFacturacion.baseImp_1
        objetoTotalFacturacion.impuesto = objetoTotalFacturacion.impuesto + objetoTotalFacturacion.impuesto_1
        objetoTotalFacturacion.total = objetoTotalFacturacion.total + objetoTotalFacturacion.total_1
    } else {
        objetoTotalFacturacion.importeBruto = ""
        objetoTotalFacturacion.importeDescuento = ""
        objetoTotalFacturacion.baseImp = ""
        objetoTotalFacturacion.impuesto = ""
        objetoTotalFacturacion.total = ""
    }

    if (result.length > 1) {
        objetoTotalFacturacion.importeBruto = objetoTotalFacturacion.importeBruto + objetoTotalFacturacion.importeBruto_2
        objetoTotalFacturacion.importeDescuento = objetoTotalFacturacion.importeDescuento + objetoTotalFacturacion.importeDescuento_2
        objetoTotalFacturacion.baseImp = objetoTotalFacturacion.baseImp + objetoTotalFacturacion.baseImp_2
        objetoTotalFacturacion.impuesto = objetoTotalFacturacion.impuesto + objetoTotalFacturacion.impuesto_2
        objetoTotalFacturacion.total = objetoTotalFacturacion.total + objetoTotalFacturacion.total_2
    }

    if (result.length > 2) {
        objetoTotalFacturacion.importeBruto = objetoTotalFacturacion.importeBruto + objetoTotalFacturacion.importeBruto_3
        objetoTotalFacturacion.importeDescuento = objetoTotalFacturacion.importeDescuento + objetoTotalFacturacion.importeDescuento_3
        objetoTotalFacturacion.baseImp = objetoTotalFacturacion.baseImp + objetoTotalFacturacion.baseImp_3
        objetoTotalFacturacion.impuesto = objetoTotalFacturacion.impuesto + objetoTotalFacturacion.impuesto_3
        objetoTotalFacturacion.total = objetoTotalFacturacion.total + objetoTotalFacturacion.total_3
    }



    objetoTotalFacturacion.importeBruto = objetoTotalFacturacion.importeBruto === "" ? objetoTotalFacturacion.importeBruto : convertirMoneda(objetoTotalFacturacion.importeBruto, "€");
    objetoTotalFacturacion.importeBruto_1 = objetoTotalFacturacion.importeBruto_1 === "" ? objetoTotalFacturacion.importeBruto_1 : convertirMoneda(objetoTotalFacturacion.importeBruto_1, "€");
    objetoTotalFacturacion.importeBruto_2 = objetoTotalFacturacion.importeBruto_2 === "" ? objetoTotalFacturacion.importeBruto_2 : convertirMoneda(objetoTotalFacturacion.importeBruto_2, "€");
    objetoTotalFacturacion.importeBruto_3 = objetoTotalFacturacion.importeBruto_3 === "" ? objetoTotalFacturacion.importeBruto_3 : convertirMoneda(objetoTotalFacturacion.importeBruto_3, "€");

    objetoTotalFacturacion.importeDescuento = objetoTotalFacturacion.importeDescuento === "" ? objetoTotalFacturacion.importeDescuento : convertirMoneda(objetoTotalFacturacion.importeDescuento, "€");
    objetoTotalFacturacion.importeDescuento_1 = objetoTotalFacturacion.importeDescuento_1 === "" ? objetoTotalFacturacion.importeDescuento_1 : convertirMoneda(objetoTotalFacturacion.importeDescuento_1, "€");
    objetoTotalFacturacion.importeDescuento_2 = objetoTotalFacturacion.importeDescuento_2 === "" ? objetoTotalFacturacion.importeDescuento_2 : convertirMoneda(objetoTotalFacturacion.importeDescuento_2, "€");
    objetoTotalFacturacion.importeDescuento_3 = objetoTotalFacturacion.importeDescuento_3 === "" ? objetoTotalFacturacion.importeDescuento_3 : convertirMoneda(objetoTotalFacturacion.importeDescuento_3, "€");

    objetoTotalFacturacion.baseImp = objetoTotalFacturacion.baseImp === "" ? objetoTotalFacturacion.baseImp : convertirMoneda(objetoTotalFacturacion.baseImp, "€");
    objetoTotalFacturacion.baseImp_1 = objetoTotalFacturacion.baseImp_1 === "" ? objetoTotalFacturacion.baseImp_1 : convertirMoneda(objetoTotalFacturacion.baseImp_1, "€");
    objetoTotalFacturacion.baseImp_2 = objetoTotalFacturacion.baseImp_2 === "" ? objetoTotalFacturacion.baseImp_2 : convertirMoneda(objetoTotalFacturacion.baseImp_2, "€");
    objetoTotalFacturacion.baseImp_3 = objetoTotalFacturacion.baseImp_3 === "" ? objetoTotalFacturacion.baseImp_3 : convertirMoneda(objetoTotalFacturacion.baseImp_3, "€");


    objetoTotalFacturacion.impuesto = objetoTotalFacturacion.impuesto === "" ? objetoTotalFacturacion.impuesto : convertirMoneda(objetoTotalFacturacion.impuesto, "€");
    objetoTotalFacturacion.impuesto_1 = objetoTotalFacturacion.impuesto_1 === "" ? objetoTotalFacturacion.impuesto_1 : convertirMoneda(objetoTotalFacturacion.impuesto_1, "€");
    objetoTotalFacturacion.impuesto_2 = objetoTotalFacturacion.impuesto_2 === "" ? objetoTotalFacturacion.impuesto_2 : convertirMoneda(objetoTotalFacturacion.impuesto_2, "€");
    objetoTotalFacturacion.impuesto_3 = objetoTotalFacturacion.impuesto_3 === "" ? objetoTotalFacturacion.impuesto_3 : convertirMoneda(objetoTotalFacturacion.impuesto_3, "€");

    objetoTotalFacturacion.total = objetoTotalFacturacion.total === "" ? objetoTotalFacturacion.total : convertirMoneda(objetoTotalFacturacion.total, "€");


    objetoTotalFacturacion.iva_1 = objetoTotalFacturacion.iva_1 === "" ? objetoTotalFacturacion.iva_1 : convertirMoneda(objetoTotalFacturacion.iva_1, "%");
    objetoTotalFacturacion.iva_2 = objetoTotalFacturacion.iva_2 === "" ? objetoTotalFacturacion.iva_2 : convertirMoneda(objetoTotalFacturacion.iva_2, "%");
    objetoTotalFacturacion.iva_3 = objetoTotalFacturacion.iva_3 === "" ? objetoTotalFacturacion.iva_3 : convertirMoneda(objetoTotalFacturacion.iva_3, "%");

    return objetoTotalFacturacion;
}


export function calcularImporteDescuento(precio, descuento) {
    let des = ((precio * descuento) / 100);
    return des
}

export function calcularBaseImp(bruto, descuento) {
    let valor = bruto - descuento
    return valor;
}

export function calcularImporteImpuesto(precio, iva) {
    let imp = ((precio * iva) / 100);
    return imp
}


export function redondeo(num, numDec) {
    num = num * Math.pow(10, numDec)
    num = Math.round(num)
    num = num / Math.pow(10, numDec)
    return num
}

export function verificarMatricula(matricula) {

    let expresion = "^[A-Za-z0-9]{1,50}$";
    let regExpMatricula = new RegExp(expresion);
    let esOk = true;

    if (!regExpMatricula.test(matricula.toUpperCase())) {
        esOk = false
    }
    return esOk;
}




export function obtenerParametrosPorQueryString(params = "") {
    let rawParams = params.replace("?", "").split("&");
    let extractedParams = {};
    if (params !== "") {
        rawParams.forEach((item) => {
            item = item.split("=");
            extractedParams[item[0]] = item[1];
        });
    } else {
        extractedParams = null;
    }

    return extractedParams;
}



export function copiarAlPortapapeles(idTag) {
    var codigoACopiar = document.getElementById(idTag);    //Elemento a copiar
    //Debe estar seleccionado en la página para que surta efecto, así que...
    var seleccion = document.createRange(); //Creo una nueva selección vacía
    seleccion.selectNodeContents(codigoACopiar);    //incluyo el nodo en la selección
    //Antes de añadir el intervalo de selección a la selección actual, elimino otros que pudieran existir (sino no funciona en Edge)
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(seleccion);  //Y la añado a lo seleccionado actualmente
    try {
        var res = document.execCommand('copy'); //Intento el copiado
        if (res) {
            MSGOK("¡¡Texto copiado al portapapeles!!", "bottom-right")
        } else {
            MSGERROR("¡¡Ha fallado el copiado al portapapeles!!", "bottom-right")

        }
    }
    catch (ex) {
        alertify.set('notifier', 'position', 'top-left');
        alertify.error("Se ha producido un error al copiar al portapaples");
    }
    window.getSelection().removeRange(seleccion);
}


export function getTwoDigits(value) {
    return value < 10 ? `0${value}` : value;
}


export function obtenerFechaLocal(esMesAnterior) {
    let date = new Date();

    let day = getTwoDigits(date.getDate());
    let month = getTwoDigits(esMesAnterior ? date.getMonth() : date.getMonth() + 1);
    let year = date.getFullYear();

    return `${year}-${month}-${day}`;
}


export function obtenerFechaLocalObjeto(esMesAnterior) {
    let resultado = {};
    let date = new Date();

    let day = getTwoDigits(date.getDate());
    let month = getTwoDigits(esMesAnterior ? date.getMonth() : date.getMonth() + 1);
    let year = date.getFullYear();
    resultado = { "DIA": day, "MES": month, "ANIO": year }

    return resultado;
}

export function obtenerFechaYHora(esMesAnterior) {
    let date = new Date();

    let day = getTwoDigits(date.getDate());
    let month = getTwoDigits(esMesAnterior ? date.getMonth() : date.getMonth() + 1);
    let year = date.getFullYear();
    let hour = getTwoDigits(date.getHours());
    let minute = getTwoDigits(date.getMinutes());
    let seconds = getTwoDigits(date.getSeconds());
    let formato = day + "/" + month + "/" + year + "_" + hour + ":" + minute + ":" + seconds

    return formato;
}

export function nuevoFormatoFechaCliente(fecha) {
    var pattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    var arrayDate = fecha.match(pattern);
    /*
    var dt = new Date(arrayDate[3], arrayDate[2] - 1, arrayDate[1]);

    var diaActual = new Date(fecha);
    var day = diaActual.getDate() < 10 ? "0" + diaActual.getDate() : diaActual.getDate();
    var month = (diaActual.getMonth() + 1) < 10 ? "0" + (diaActual.getMonth() + 1) : (diaActual.getMonth() + 1);
    var year = diaActual.getFullYear();

    var hora = diaActual.getHours() < 10 ? "0" + diaActual.getHours() : diaActual.getHours();
    var minutos = diaActual.getMinutes() < 10 ? "0" + diaActual.getMinutes() : diaActual.getMinutes();
*/
    return `${arrayDate[3]}-${arrayDate[2]}-${arrayDate[1]}`;
}



export function efectoLadoDeMontaje(id) {
    let estaActivo = false;
    if (document.getElementById(id) !== null) {
        if (document.getElementById(id).style.opacity !== "") {
            document.getElementById(id).style.opacity = "";
            estaActivo = true;
        } else {
            document.getElementById(id).style.opacity = "0.5";
        }
    }
    return estaActivo
}


export function filtroTree(palabra) {
    let treeOriginal = store.getState().listaEstructura;
    let nodosEncontrados = [];
    if (palabra.length >= 3) {
        treeOriginal.find(function (nodo, i) {
            if (nodo.Term.toLowerCase().includes(palabra.toLowerCase())) {
                if (nodo.NIVEL > 1) {
                    let continuarBuscando = true;
                    let nodosHijos = [];
                    let nodoParent = nodo.NODE_ID_PARENT;
                    do {
                        let existenNodos = treeOriginal.find(registro => registro.NODE_ID === nodoParent)
                        if (existenNodos !== undefined) {
                            if (existenNodos.NIVEL === 1) {
                                continuarBuscando = false;
                            } else {
                                continuarBuscando = true;
                                nodoParent = existenNodos.NODE_ID_PARENT
                            }
                            nodosHijos.push(existenNodos)
                        } else {
                            continuarBuscando = false;
                        }
                    } while (continuarBuscando);
                    nodosHijos.push(nodo)
                    nodosHijos.sort(dynamicSortAsc("NIVEL"))
                    nodosEncontrados.push(nodosHijos)
                }
            }
        })
    }

    store.dispatch({
        type: "REGISTRO_FILTRO_TREE",
        openFiltroTree: nodosEncontrados.length > 0 ? true : false,
        registrosFiltro: nodosEncontrados
    })
}

export function obtenerLineasPorTipo(registros, tipoLinea) {
    if (registros.length === 0) {
        return []
    } else {
        registros.map((linea, i) => {
            linea.ID = i + 1
        })
        return registros.filter(registro => registro.TIPO_LINEA === tipoLinea)
    }
}


export function descargarPdfPasandoBase64(base64String) {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fechaFichero = obtenerFechaYHora();
    const fileName = "Documento" + fechaFichero + ".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}


export function descargarDocPasandoBase64Generico(base64String, nombre) {
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = nombre;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
export function crearLineasParaElPresupuesto(registro, tipoDeLinea) {
    let lineas = [];
    let obtenerReferencia = registro;
    let listaStore = store.getState().PresupuestoTallerSeleccionado;

    //Verificar si no hay iva por defecto
    let datosFacturacion = store.getState().datosFacturacion;
    let ivaPorDefecto = datosFacturacion.ivaPorDefecto
    let precioManoObra = datosFacturacion.manoObraPorDecto
    let lineFacturacion = {};
    let payLoadArticulo = {};
    let payLoadManoObra = {};

    if (tipoDeLinea === "A") {
        //ARTICULO
        lineFacturacion = calculoLineaFacturacion(1, obtenerReferencia.PRECIO, 0, ivaPorDefecto)
        payLoadArticulo = {
            ORDEN_LINEA: 0,
            ID_PRESUPUESTO: null,
            TIPO_LINEA: "A",
            REFERENCIA_SOCIO: obtenerReferencia.REFERENCIA_PROVEEDOR,
            REFERENCIA_TECDOC: obtenerReferencia.REFERENCIA,
            MARCA_SOCIO: obtenerReferencia.CODIGO_MARCA_ERP,
            MARCA_SOCIO_DESCRIPCION: obtenerReferencia.NOMRE_MARCA,
            MARCA_TECDOC: obtenerReferencia.CODIGO_MARCA,
            MARCA_TECDOC_DESCRIPCION: obtenerReferencia.NOMRE_MARCA,
            REFERENCIA_DESCRIPCION: obtenerReferencia.NOMBRE_GRUPO_MONTAJE,
            MANO_OBRA: "",
            PRECIO: obtenerReferencia.PRECIO,
            IVA_VALOR: datosFacturacion.ivaPorDefecto,
            DTO_VALOR: 0,
            CANTIDAD: 1.00,
            IMPORTE_BRUTO: lineFacturacion.importeBruto,
            IMPORTE_DESCUENTOS: lineFacturacion.importeDescuento,
            IMPORTE_BASE_IMPONIBLE: lineFacturacion.baseImp,
            IMPORTE_IMPUESTOS: lineFacturacion.impuesto,
            IMPORTE_TOTAL: lineFacturacion.total,
            ID: 0
        }


        if (listaStore.presupuestoSecundario.length > 0) {
            lineas = listaStore.presupuestoSecundario;
            lineas.push(payLoadArticulo)
            store.dispatch({ type: "UPDATE_LISTA_PRESUPUESTO_SECUNDARIO", presupuestoSecundario: lineas })

        } else {
            lineas.push(payLoadArticulo)
            store.dispatch({ type: "UPDATE_LISTA_PRESUPUESTO_SECUNDARIO", presupuestoSecundario: lineas })
        }


        MSGOK("Referencia agregada correctamnete")
    } else {
        //MANO DE OBRRA
        let lineFacturacionManoObra = calculoLineaFacturacion(registro.Tiempo, precioManoObra, 0, ivaPorDefecto)
        let manoObraTotal = registro.Descripcion;

        if (registro.TAREAS_REPARACION.length > 0) {
            manoObraTotal = manoObraTotal + '<br/>' + '<ul>';
            registro.TAREAS_REPARACION.map(tarea => {
                manoObraTotal = manoObraTotal + '<li>' + tarea.Descripcion + '</li>';
            })
            manoObraTotal = manoObraTotal + '</ul>';
        }

        payLoadManoObra = {
            ORDEN_LINEA: 0,
            ID_PRESUPUESTO: null,
            TIPO_LINEA: "T",
            REFERENCIA_SOCIO: "",
            REFERENCIA_TECDOC: "",
            MARCA_SOCIO: "",
            MARCA_SOCIO_DESCRIPCION: "",
            MARCA_TECDOC: "",
            MARCA_TECDOC_DESCRIPCION: "",
            REFERENCIA_DESCRIPCION: "",
            MANO_OBRA: manoObraTotal,
            PRECIO: precioManoObra,
            IVA_VALOR: ivaPorDefecto,
            DTO_VALOR: 0,
            CANTIDAD: registro.Tiempo,
            IMPORTE_BRUTO: lineFacturacionManoObra.importeBruto,
            IMPORTE_DESCUENTOS: lineFacturacionManoObra.importeDescuento,
            IMPORTE_BASE_IMPONIBLE: lineFacturacionManoObra.baseImp,
            IMPORTE_IMPUESTOS: lineFacturacionManoObra.impuesto,
            IMPORTE_TOTAL: lineFacturacionManoObra.total,
            ID: 0
        }


        if (listaStore.presupuestoSecundario.length > 0) {
            lineas = listaStore.presupuestoSecundario;
            lineas.push(payLoadManoObra)
            store.dispatch({ type: "UPDATE_LISTA_PRESUPUESTO_SECUNDARIO", presupuestoSecundario: lineas })

        } else {
            lineas.push(payLoadManoObra)
            store.dispatch({ type: "UPDATE_LISTA_PRESUPUESTO_SECUNDARIO", presupuestoSecundario: lineas })
        }

        MSGOK("Referencia agregada correctamnete")

    }
}


export function convertirMonedaANumero(moneda) {
    let resultado = 0;
    if (moneda !== 0) {
        resultado = moneda.replace(".", "");
        resultado = resultado.replace(",", ".");
        resultado = resultado.replace("€", "");
        resultado = resultado.replace(" ", "");
    }
    return resultado;
}


export function verificarSiEsMostrador() {
    let esMostrador = false;
    esMostrador = store.getState().usuario.LoginSertec === undefined ? false : store.getState().usuario.LoginSertec.List_LicenciaSERTE !== null ? store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].MOSTRADOR : false;

    return esMostrador
}


export function obtenerNombreDeSocio(USUARIO) {
    let NOMBRE_SOCIO = ""
    if (Object.keys(USUARIO).length > 0) {
        if (USUARIO !== undefined) {
            if (USUARIO.ID_ROL === 2) {
                NOMBRE_SOCIO = USUARIO.NOMBRE_ROL;
            } else {
                if (USUARIO.SOCIOS !== null || USUARIO !== undefined ? USUARIO.SOCIOS.length > 0 : false) {
                    NOMBRE_SOCIO = USUARIO.SOCIOS[0].NOMBRE_SOCIO;
                }
            }
        }
    }

    return NOMBRE_SOCIO
}



export function recortarCadena(cadena, tamanyo, caracter) {
    if (cadena === undefined || cadena === null) {
        return cadena;
    } else {
        if (cadena.length < tamanyo) {
            return cadena;
        }

        let retorno = cadena.substring(0, tamanyo);

        if (caracter !== null || caracter !== "") {
            retorno = retorno + caracter;
        }
        return retorno;
    }
}

export function ordenarTablaLocal(tipoFiltro, registros, campoAFiltrar) {

    let lista = tipoFiltro === "ASC" ? registros.sort(dynamicSortAsc(campoAFiltrar)) : registros.sort(dynamicSortDesc(campoAFiltrar));

    return lista;
}

export function decode_utf8(s) {
    return decodeURIComponent(escape(s));
}


export const colorFondoMedia = (media) => {
    if (media < 25) { return "#f9c9c9" }
    if (media >= 25 && media < 50) { return "#f5d698" }
    if (media >= 50 && media < 75) { return "#f5f5b4" }
    if (media >= 75) { return "#e7f9c9" }

}



export function obtenerLicenciaLocal() {
    let registro = store.getState().usuario;

    if (Object.keys(registro).length > 0) {
        registro = registro.USUARIO
    } else {

        registro = {
            ID_USUARIO: "",
            USUARIO: "",
            PWD: "",
            ID_TALLER: "",
            NOMBRE_TALLER: "",
            RAZON_TALLER: "",
            ID_SOCIO: "",
            RAZON_SOCIO: "",
            ID_IDIOMA: "",
            ID_IDIOMA_TECDOC: "",
            CODIGO_IDIOMA: "",
            IDIOMA: "",
            REGIONAL_IDIOMA: "",
            BAJA: ""
        }
    }

    return registro;
}


export const verificarExtension = (EXTENSION) => {
    let resultado = {
        ID_TIPO: 7,
        TIPO: "desconocido.png",
        ES_EXTENSION_PERMITIDA: true
    }
    switch (EXTENSION.toUpperCase()) {
        case "PNG": case "JPG": case "JPEG":
            resultado.ID_TIPO = 1
            resultado.TIPO = "imagen.png"
            break;
        case "PDF":
            resultado.ID_TIPO = 2
            resultado.TIPO = "pdf.png"
            break;
        case "XLSX": case "XLSM": case "XLSB": case "XLTX": case "CSV":
            resultado.ID_TIPO = 3
            resultado.TIPO = "excel.png"
            break;
        case "DOCX":
            resultado.ID_TIPO = 4
            resultado.TIPO = "word.png"
            break;
        case "MP4": case "MOV": case "WMV": case "MKV":
            resultado.ID_TIPO = 5
            resultado.TIPO = "video.png"
            break;
        case "ZIP": case "RAR":
            resultado.ID_TIPO = 6
            resultado.TIPO = "comprimido.png"
            break;
        default:
            resultado.ID_TIPO = 7
            resultado.TIPO = "desconocido.png"
            resultado.ES_EXTENSION_PERMITIDA = false
            break;
    }

    return resultado;
}


export const generateUUID = () => {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}



export const buscarVehiculoLocal = async (codmotorizacion) => {
    let VEHICULO = {
        MATRICULA: "",
        BASTIDOR: "",
        MARCA_VEHICULO: "",
        MODELO_VEHICULO: "",
        MOTOR_VEHICULO: "",
        VEHICULO_TECDOC: {}
    }
    // const { VEHICULO_SERTEC } = store.getState().fichaBusquedaVehiculo;

    codmotorizacion = codmotorizacion.toString();
    if (codmotorizacion !== "") {
        const resultado = await obtener_vehiculoEnAPI(codmotorizacion);
        const { MARCA, MODELO, MOTORIZACION } = await resultado;
        VEHICULO.MARCA_VEHICULO = MARCA;
        VEHICULO.MODELO_VEHICULO = MODELO;
        VEHICULO.MOTOR_VEHICULO = MOTORIZACION;
        VEHICULO.VEHICULO_TECDOC = resultado;
    }

    /*
    if (Object.keys(VEHICULO_SERTEC).length > 0) {
        const { TIPO_BUSQUEDA, MATRICULA, OK, IDENTIFICADOR } = VEHICULO_SERTEC;
        if (OK) {
            VEHICULO.MATRICULA = TIPO_BUSQUEDA === "MATRICULA" ? MATRICULA.Matricula : "";
            VEHICULO.BASTIDOR = TIPO_BUSQUEDA === "MATRICULA" ? MATRICULA.Bastidor : IDENTIFICADOR;
        }
    }
    */

    return VEHICULO;
}




export const construirFecha = (codigoMes, Anio, condiciones) => {
    let fechaLocal = obtenerFechaLocalObjeto(false);
    let crearFecha = ""

    if (condiciones.DESDE === 4 && condiciones.HASTA === 5) {
        crearFecha = fechaLocal.DIA + "/" + codigoMes + "/" + Anio
    } else if (condiciones.DESDE === 0 && condiciones.HASTA === 1) {
        crearFecha = "01" + "/" + codigoMes + "/" + Anio
    } else if (condiciones.DESDE === 2 && condiciones.HASTA === 3) {
        crearFecha = "01" + "/" + "01" + "/" + Anio
    }

    return crearFecha;
}



export function obtenerIdioma(CODIGO_IDIOMA) {
    let pais = { IDIOMA: "ESP", PAIS: "ESPAÑA" }
    switch (CODIGO_IDIOMA.trim()) {
        case "E":
            pais.IDIOMA = "ESP";
            pais.PAIS = "ESPAÑA";
            break;
        case "P":
            pais.IDIOMA = "POR";
            pais.PAIS = "PORTUGAL";
            break;
        case "F":
            pais.IDIOMA = "FRA";
            pais.PAIS = "FRANCIA";
            break;
        case "A":
            pais.IDIOMA = "ALE";
            pais.PAIS = "ALEMANIA";
            break;
        case "I":
            pais.IDIOMA = "ITA";
            pais.PAIS = "ITALIA";
            break;
        case "N":
            pais.IDIOMA = "REN";
            pais.PAIS = "REINO UNIDO";
            break;
        case "S":
            pais.IDIOMA = "FRA";
            pais.PAIS = "SUIZA";
            break;

        case "Z":
            pais.IDIOMA = "ALE";
            pais.PAIS = "SUIZA";
            break;

        default:
            pais.IDIOMA = "ESP";
            pais.PAIS = "ESPAÑA";
            break;
    }
    return pais;
}

export function convertirTextoBastidor(BASTIDOR) {
    let MODIFICAR_VISTA = BASTIDOR


    if (MODIFICAR_VISTA !== undefined && MODIFICAR_VISTA !== null && MODIFICAR_VISTA !== "") {
        MODIFICAR_VISTA = MODIFICAR_VISTA.substring(0, MODIFICAR_VISTA.length - 5) + "*****"
    }


    return MODIFICAR_VISTA

}
